import { CustomerMapping, CustomerSpecificCustomerMapping, MyndSpecificMapping } from '@reshub/model';

export type CustomerMappingDto = CustomerMapping;
export type CustomerSpecificCustomerMappingDto = CustomerSpecificCustomerMapping;
export type MyndSpecificMappingDto = MyndSpecificMapping;

export class CustomerMappingSearchResponseDTO {
  docs: CustomerMappingDto[];
  nextPage: number;
}
