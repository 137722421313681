// eslint-disable-next-line @typescript-eslint/no-unused-vars

import { auth } from '@mezo/web/services';
import { Loading, Navbar } from '@mezo/web/ui';
import { RoutePaths, isTesting } from '@mezo/web/utils';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import React, { ReactElement, Suspense, lazy } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import tw from 'twin.macro';
const Login = lazy(() => import('./auth/login'));
const Logout = lazy(() => import('./auth/logout'));
const Dashboard = lazy(() => import('@mezo/web/dashboard'));
const ServiceRequest = lazy(() => import('@mezo/web/service-request'));

export function App() {
  const [user] = useAuthState(auth);
  const SentryRoutes = withSentryReactRouterV6Routing(Routes);
  const RouteWrapper = isTesting() ? Routes : SentryRoutes;
  const AppContainer = tw.div`flex flex-col h-full`;
  const nonChatQueryClient = new QueryClient();

  const RequireAuth = ({ children }: { children?: ReactElement }) => {
    const [user] = useAuthState(auth);
    const location = useLocation();

    return !user ? <Navigate to={RoutePaths.LOGIN} replace state={{ path: location.pathname }} /> : children ?? null;
  };
  const WithNavBar = (): JSX.Element => {
    return (
      <React.Fragment>
        {user && <Navbar email={user.email} />}
        <Outlet />
      </React.Fragment>
    );
  };

  return (
    <AppContainer>
      <RouteWrapper>
        <Route path={RoutePaths.HOME} element={<Navigate to={RoutePaths.DASHBOARD} />} />

        <Route element={<WithNavBar />}>
          <Route
            path={RoutePaths.DASHBOARD}
            element={
              <RequireAuth>
                <Suspense fallback={<Loading />}>
                  <QueryClientProvider client={nonChatQueryClient}>
                    <Dashboard />
                  </QueryClientProvider>
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path={`/${RoutePaths.buildServiceRequestRoute(':id')}`}
            element={
              <RequireAuth>
                <Suspense fallback={<Loading />}>
                  <QueryClientProvider client={nonChatQueryClient}>
                    <ServiceRequest isExternal={false} />
                  </QueryClientProvider>
                </Suspense>
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path={RoutePaths.LOGIN}
          element={
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          }
        />
        <Route path={'/logout'} element={<Logout />} />
        <Route path="*" element={<Navigate to={RoutePaths.DASHBOARD} />} />
      </RouteWrapper>
      <ToastContainer />
    </AppContainer>
  );
}

export default App;
