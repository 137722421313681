import { formatJSDate } from '@mezo/shared/formatters';
import { getMediaTypeFromExtension } from '@mezo/web/utils';
import { MediaFileInfoDto, MessageDto } from '@reshub/dtos';
import parse from 'html-react-parser';
import { Fragment } from 'react';
import tw, { styled } from 'twin.macro';
import { MEZO_LOGO_URL, NODE_TYPES } from '../types/constants';
import { DisplayNode, MessageAuthor } from '../types/types';
import { CustomerLogo } from './customer-logo';

export interface MessageProps {
  author: MessageAuthor;
  node: DisplayNode;
  onClick?: VoidFunction;
  parseContent?: boolean;
  onLoad?: VoidFunction;
}

interface BotMessageProps {
  nodes: DisplayNode[];
  onClick?: VoidFunction;
  customerIconUrl: string;
  onLoad?: VoidFunction;
}

const RECEIVER = 'receiver';

const MessageImage = tw.img`block w-auto h-auto rounded-lg max-h-64`;
const MessageVideo = tw.video`block w-full h-auto rounded-lg max-h-64`;

const StyledMedia = styled.div<{ author: string }>(({ author }) => [
  author === RECEIVER ? tw`self-start max-w-[80%]` : tw`self-end max-w-3/4`,
]);

const StyledMessage = styled.div<{ author: string }>(({ author }) => [
  tw`px-5 py-3`,
  author === RECEIVER
    ? tw`self-start max-w-full text-white rounded-tl-none rounded-tr-xl rounded-b-xl bg-primary`
    : tw`self-end text-black rounded-br-none rounded-bl-xl rounded-t-xl bg-light-grey max-w-3/4`,
]);

const StyledText = tw.span`block`;

const GridContainer = tw.div`flex justify-start gap-4`;
const LogoColumn = tw.div``;
const MessagesColumn = tw.div`flex flex-col gap-2 max-w-[80%]`;
const StyledCustomerLogo = tw(CustomerLogo)`max-w-12 max-h-12`;

export const mapDisplayNodes = (message: MessageDto) => {
  const displayNodes: DisplayNode[] =
    message.messages?.map((val) => ({
      type: NODE_TYPES.text,
      msg: val,
      media: null,
      timestamp: message.timestamp,
      senderId: message.senderId || '',
    })) ?? [];
  message.mediaFileInfos?.forEach((val: MediaFileInfoDto) => {
    if (val.contentType) {
      displayNodes.push({
        type: NODE_TYPES.media,
        msg: null,
        media: val || null,
        timestamp: message.timestamp,
        senderId: message.senderId || '',
      });
    }
  });
  return displayNodes;
};

const renderMedia = (
  author: MessageAuthor,
  media: MediaFileInfoDto | null,
  onClick?: VoidFunction,
  onLoad?: VoidFunction
) => {
  if (!media) {
    return undefined;
  }

  const type = getMediaTypeFromExtension(media);

  return type === 'video' ? (
    <StyledMedia author={author} onClick={onClick} data-cy="chat-node">
      <MessageVideo src={media.url} controls onClick={(e) => e.stopPropagation()} onLoad={onLoad} />
    </StyledMedia>
  ) : (
    <StyledMedia author={author} onClick={onClick} data-cy="chat-node">
      <MessageImage alt={media.filename} src={media.url} onLoad={onLoad} />
    </StyledMedia>
  );
};

export const MessageNode: React.FC<MessageProps> = ({ node, onClick, author, parseContent = false, onLoad }) => (
  <Fragment>
    {node.type === NODE_TYPES.media && renderMedia(author, node.media, onClick, onLoad)}
    {node.type === NODE_TYPES.text && node.msg != null && (
      <StyledMessage author={author} data-cy="chat-node">
        <StyledText title={formatJSDate(node.timestamp as unknown as Date)}>
          {parseContent ? parse(node.msg) : node.msg}
        </StyledText>
      </StyledMessage>
    )}
  </Fragment>
);

type BotMessageContainerProps = {
  customerIconUrl: string;
  children?: React.ReactNode;
};

export const BotMessageContainer: React.FC<BotMessageContainerProps> = ({ customerIconUrl, children }) => (
  <GridContainer>
    <LogoColumn>
      <StyledCustomerLogo src={customerIconUrl ?? MEZO_LOGO_URL} />
    </LogoColumn>
    <MessagesColumn>{children}</MessagesColumn>
  </GridContainer>
);

export const BotMessageNode: React.FC<BotMessageProps> = ({ nodes, onClick, customerIconUrl, onLoad }) => (
  <BotMessageContainer customerIconUrl={customerIconUrl}>
    {nodes.map((node, index) => {
      return (
        node && (
          <MessageNode
            key={`${node?.type}-${index}-${node?.timestamp}`}
            author={RECEIVER}
            node={node}
            onClick={onClick}
            parseContent={true}
            onLoad={onLoad}
          />
        )
      );
    })}
  </BotMessageContainer>
);
