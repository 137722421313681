import { Address } from './address.model';
import { CustomerId } from './customer.model';

export class RecentResidentProfile {
  id: string;
  residentId: string;
  unitId: string;
  customerId: CustomerId;
  sourceId: string;
  firstName: string;
  lastName: string;
  address: Address;
  timestamp: number;
}
