import { CalendarDate, today } from '@internationalized/date';
import { DATE_STRINGS, formatDateToTimezone } from '@mezo/shared/formatters';
import { Locale } from '@mezo/shared/utils';
import { UnitSchedulingConfiguration } from '@reshub/dtos';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { Calendar } from '../calendar';
import { ChatScreenFooter } from '../chat/chat-screen-footer';
import { AvailabilityInterval } from './availabilityInterval';
import { AvailabilitySegment, AvailabilitySelectionMap } from './scheduling.types';
import { determineFirstActiveDay, generateAvailabilities, shouldDisableDate } from './scheduling.utilities';

const PageContent = tw.div`relative flex flex-col w-full h-full mx-auto overflow-y-scroll lg:max-w-xl bg-background-light`;
const PageContainer = tw.div`flex flex-col justify-between w-full h-full bg-background rounded-xl`;
const InstructionBanner = tw.h2`px-4 py-2 m-0 text-base leading-6 text-center text-black border-b border-b-border-grey`;
const CalendarContainer = tw.div`flex justify-center p-4 border-b border-background`;
const SelectedDay = tw.h4`z-10 px-4 py-2 text-xs text-center border-b border-b-border-grey`;
const AvailabilityContainer = tw.div``;

const Empty = tw.div`p-6 text-center`;
const AvailabilitiesContainer = tw.div`flex flex-col justify-start grow-2`;

export const UTC = 'UTC';

type SchedulingProps = {
  scheduleConfig: UnitSchedulingConfiguration;
  onSubmit: (availabilities: AvailabilitySegment[]) => void;
  showLoader: boolean;
  today: CalendarDate;
};

export const Scheduling = ({ scheduleConfig, onSubmit, showLoader }: SchedulingProps) => {
  const {
    daysToSchedule,
    maxDaysOut,
    minBlocksSelectable,
    minDaysSelectable,
    firstAvailable,
    firstBlockStartHour,
    blockCount,
    blockLengthMinutes,
  } = scheduleConfig.configuration;
  const timeZoneId = scheduleConfig.timezone?.timeZoneId || UTC;
  const todayInUnitTimeZone = today(timeZoneId);
  const startDay = determineFirstActiveDay(firstAvailable, daysToSchedule, todayInUnitTimeZone);
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedAvailabilities, setSelectedAvailabilities] = useState<AvailabilitySelectionMap>({});
  const { t, i18n } = useTranslation();
  const [visibleAvailabilities, setVisibleAvailabilities] = useState<AvailabilitySegment[]>([]);

  const selectedDays = useMemo((): string[] => Object.keys(selectedAvailabilities), [selectedAvailabilities]);

  const totalSelectedTimes = useMemo(
    () => selectedDays.reduce((acc, item) => (acc += selectedAvailabilities[item].length), 0),
    [selectedAvailabilities, selectedDays]
  );

  const updateVisibleAvailabilities = useCallback(
    (start: CalendarDate) => {
      setVisibleAvailabilities(
        generateAvailabilities(firstBlockStartHour, blockCount, blockLengthMinutes, start, timeZoneId)
      );
    },
    [blockCount, blockLengthMinutes, firstBlockStartHour, timeZoneId]
  );

  const handleDaySelection = useCallback(
    (day: CalendarDate) => {
      const dateInTimeZone = day.toDate(timeZoneId);
      setSelectedDay(dateInTimeZone.toUTCString());
      updateVisibleAvailabilities(day);
    },
    [timeZoneId, updateVisibleAvailabilities]
  );

  const handleDisableDate = useCallback(
    (date: CalendarDate) => shouldDisableDate(date, daysToSchedule, maxDaysOut, todayInUnitTimeZone, firstAvailable),
    [daysToSchedule, firstAvailable, maxDaysOut, todayInUnitTimeZone]
  );

  const handleCheckChange = useCallback(
    (timeInterval: AvailabilitySegment, add: boolean) => {
      if (add) {
        setSelectedAvailabilities((prevState) => {
          const selectedDaysSelectedAvailabilities = prevState[selectedDay] || [];
          const updatedAvailabilities = {
            ...prevState,
            [selectedDay]: [...selectedDaysSelectedAvailabilities, timeInterval],
          };
          return updatedAvailabilities;
        });
      } else {
        setSelectedAvailabilities((prevState) => {
          const selectedDaysSelectedAvailabilities = prevState[selectedDay] || [];
          const filtered = selectedDaysSelectedAvailabilities.filter(
            (availability) => availability.id !== timeInterval.id
          );
          const updatedAvailabilities = {
            ...prevState,
            [selectedDay]: filtered,
          };
          if (filtered.length === 0) {
            delete updatedAvailabilities[selectedDay];
          }
          return updatedAvailabilities;
        });
      }
    },
    [selectedDay]
  );

  const isTimeslotIsSelected = useCallback(
    (id: string) => {
      const selectedDaysSelectedAvailabilities = selectedAvailabilities[selectedDay] || [];
      return selectedDaysSelectedAvailabilities
        .filter((availability) => !!availability)
        .some((availability) => availability.id === id);
    },
    [selectedAvailabilities, selectedDay]
  );

  const totalSelectedDays = selectedDays.length;

  const isSubmitDisabled = useMemo(
    () => totalSelectedDays < minDaysSelectable || totalSelectedTimes < minBlocksSelectable,
    [minBlocksSelectable, minDaysSelectable, totalSelectedDays, totalSelectedTimes]
  );

  const handleSubmit = useCallback(() => {
    const availabilitiesArray = Object.values(selectedAvailabilities).flat();
    onSubmit(availabilitiesArray);
  }, [onSubmit, selectedAvailabilities]);

  const selectionHelperText = useMemo(() => {
    if (totalSelectedTimes === 0) {
      return;
    }

    if (totalSelectedTimes < minBlocksSelectable || totalSelectedDays < minDaysSelectable) {
      const moreTimes = minBlocksSelectable - totalSelectedTimes;
      const moreDays = minDaysSelectable - totalSelectedDays;

      if (totalSelectedDays < minDaysSelectable) {
        return t('scheduling.helperTextMoreDays', { count: moreDays });
      } else {
        return t('scheduling.helperTextMoreTimes', { count: moreTimes });
      }
    }
    return;
  }, [t, totalSelectedDays, minBlocksSelectable, totalSelectedTimes, minDaysSelectable]);

  return (
    <PageContainer>
      <PageContent>
        <InstructionBanner>
          <Trans
            i18nKey="scheduling.instructions"
            values={{ minTimes: minBlocksSelectable, minDays: minDaysSelectable }}
          />
        </InstructionBanner>
        <CalendarContainer>
          <Calendar
            onDaySelection={handleDaySelection}
            numDisplayWeeks={2}
            timezoneId={scheduleConfig?.timezone?.timeZoneId || UTC}
            startDay={startDay}
            today={todayInUnitTimeZone}
            daySelections={selectedDays}
            shouldDisableDate={handleDisableDate}
            currentLocale={i18n.language}
          />
        </CalendarContainer>
        {selectedDay && (
          <AvailabilitiesContainer>
            <SelectedDay>{`${t('scheduling.dateIntro')} ${formatDateToTimezone(
              new Date(selectedDay),
              timeZoneId,
              i18n.language,
              i18n.language === Locale.EN_US ? DATE_STRINGS.dayWithMonth : DATE_STRINGS.dayWithMonthNoOrdinal
            )}`}</SelectedDay>
            <AvailabilityContainer>
              {visibleAvailabilities.map(({ startTimeUtc, endTimeUtc, id }) => (
                <AvailabilityInterval
                  key={`${startTimeUtc}-${endTimeUtc}`}
                  startTimeUtc={startTimeUtc}
                  endTimeUtc={endTimeUtc}
                  id={id}
                  onChange={handleCheckChange}
                  isSelected={isTimeslotIsSelected(id)}
                  timezoneId={timeZoneId}
                  currentLocale={i18n.language}
                />
              ))}
              {visibleAvailabilities.length === 0 && <Empty>{t('scheduling.empty')}</Empty>}
            </AvailabilityContainer>
          </AvailabilitiesContainer>
        )}
        <ChatScreenFooter
          isActive={!isSubmitDisabled}
          showButtonLoader={showLoader}
          label={t('scheduling.submitButton')}
          onClick={handleSubmit}
          textAbove={selectionHelperText}
        />
      </PageContent>
    </PageContainer>
  );
};
