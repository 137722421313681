import { useUserSession } from '@mezo/web/hooks';
import { UserSessionDto } from '@reshub/dtos';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface ChatContextValue {
  hasVisitedChatInSession: boolean;
  setChatVisited: (newValue: boolean) => void;

  userSession?: UserSessionDto;
  customerId?: string;
  residentId?: string;
  unitId?: string;

  setCustomerResidentUnit: (customerId: string, residentId: string, unitId: string) => void;
  updateFlow: (flow: string, node?: string) => void;
  updateError: (type: string) => void;
}

const ChatContext = createContext<ChatContextValue>({
  hasVisitedChatInSession: false,
  setChatVisited: (newValue: boolean) => {
    /** */
  },
  setCustomerResidentUnit: (customerId: string, residentId: string, unitId: string) => {
    /* */
  },
  updateFlow: (flow: string, node?: string) => {
    /* */
  },
  updateError: (type: string) => {
    /* */
  },
});

export const useChatContext = () => useContext(ChatContext);

export const ChatContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [hasVisitedChatInSession, setChatVisited] = useState(false);

  const [userSession, setUserSession] = useState<UserSessionDto | undefined>(undefined);

  const { useCreateUserSession, useUpdateUserSession } = useUserSession();

  const { mutateAsync: createUserSessionAsync } = useCreateUserSession();
  const { mutateAsync: updateUserSessionAsync } = useUpdateUserSession();

  useEffect(() => {
    async function createUserSession() {
      //TODO: FIXME
      try {
        const response = await createUserSessionAsync({});
        setUserSession(response);
      } catch (error) {
        //
      }
    }

    createUserSession();
  }, [createUserSessionAsync]);

  const setCustomerResidentUnit = useCallback(
    async (customerId: string, residentId: string, unitId: string) => {
      if (!userSession?.id) {
        return;
      }
      const result = await updateUserSessionAsync({
        sessionId: userSession.id,
        request: {
          customerId,
          residentId,
          unitId,
        },
      });

      if (result) {
        setUserSession(result);
      }
    },
    [updateUserSessionAsync, userSession?.id]
  );

  const updateFlow = useCallback(
    async (flow: string, node?: string) => {
      if (!userSession?.id) {
        return;
      }
      const result = await updateUserSessionAsync({
        sessionId: userSession.id,
        request: {
          flow,
          node,
        },
      });

      if (result) {
        setUserSession(result);
      }
    },
    [userSession?.id, updateUserSessionAsync]
  );

  const updateError = useCallback(
    async (type: string) => {
      if (!userSession?.id) {
        return;
      }
      const result = await updateUserSessionAsync({
        sessionId: userSession.id,
        request: {
          error: {
            type,
          },
        },
      });

      if (result) {
        setUserSession(result);
      }
    },
    [updateUserSessionAsync, userSession?.id]
  );

  const contextValue: ChatContextValue = {
    hasVisitedChatInSession,
    setChatVisited: useCallback(setChatVisited, [setChatVisited]),

    userSession,
    customerId: userSession?.customerId,
    residentId: userSession?.residentId,
    unitId: userSession?.unitId,

    setCustomerResidentUnit,
    updateFlow,
    updateError,
  };

  return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>;
};
