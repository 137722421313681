import { Locale } from '@mezo/shared/utils';
import type { DeliveryIntegrationTypeOptions, ServiceRequestFeedback } from '@reshub/model';
import { MediaFileInfo, MessageMedium, ServiceRequestPriority, ServiceRequestStatus } from '@reshub/model';
import { Type } from 'class-transformer';
import {
  IsArray,
  IsBoolean,
  IsEnum,
  IsNotEmpty,
  IsObject,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import { AvailabilityWindowsDto } from '../bot';

export class ServiceRequestAddDetailsRequestDto {
  @IsString()
  item: string;

  @IsString()
  component: string;

  @IsString()
  symptom: string;

  @IsString()
  location: string;

  @IsOptional()
  @IsString()
  itemdescription: string;

  @IsOptional()
  @IsString()
  issuedescription: string;

  @IsOptional()
  @IsString()
  locationdescription: string;

  @IsOptional()
  @IsString()
  problemstatement: string;

  @IsOptional()
  @IsString()
  diagnosisId: string;

  @IsOptional()
  @IsArray()
  customQuestionResponses?: string[];

  @ValidateNested()
  @Type(() => AvailabilityWindowsDto)
  availabilityWindows: AvailabilityWindowsDto;

  @IsOptional()
  entryNotes?: string;
}

export class ServiceRequestAddFromConversationRequestDto {
  @IsEnum(ServiceRequestStatus)
  status: ServiceRequestStatus;

  @IsEnum(MessageMedium)
  medium: MessageMedium;

  @IsString()
  dialogId: string;

  @IsString()
  loopId: string;

  @IsString()
  residentId: string;

  @IsEnum(Locale)
  locale: Locale;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => MediaFileInfo)
  mediaFileInfos: MediaFileInfo[];

  @ValidateNested()
  @Type(() => ServiceRequestAddDetailsRequestDto)
  details: ServiceRequestAddDetailsRequestDto;

  @IsBoolean()
  isAbandoned: boolean;

  @IsBoolean()
  permissionToEnter: boolean;

  @IsBoolean()
  askPermissionToEnter: boolean;

  @IsString()
  deliveryIntegrationTypeOverride?: DeliveryIntegrationTypeOptions;

  @IsBoolean()
  returnedNonMaintenanceIssue: boolean;

  @IsBoolean()
  hasAssetLabel: boolean;

  assetLookupIds?: string[];

  @IsOptional()
  @IsObject()
  feedback?: ServiceRequestFeedback;

  @IsOptional()
  entryNotes?: string;
}

export class ServiceRequestAddRequestDto {
  @IsNotEmpty()
  public customerId: string;

  @IsNotEmpty()
  public residentId: string;

  @IsNotEmpty()
  public unitId: string;

  @IsNotEmpty()
  public itemId: string;

  @IsNotEmpty()
  public symptomId: string;

  @IsEnum(ServiceRequestPriority)
  public priority?: ServiceRequestPriority;

  @IsString()
  public problemStatement: string;
}

export class ServiceRequestAddDialogDto {
  @IsNotEmpty()
  public dialogId: string;

  @IsNotEmpty()
  public loopId: string;
}

export class ServiceRequestAddConversationDto {
  dialogId?: string;
  loopId?: string;
  locale: Locale;
  deliveryIntegrationTypeOverride?: DeliveryIntegrationTypeOptions;
  mediaFileInfos: MediaFileInfo[];
  assetLookupIds?: string[];
  askPermissionToEnter: boolean;
  permissionToEnter?: boolean;
  returnedNonMaintenanceIssue: boolean;
  isAbandoned: boolean;
  entryNotes?: string;
}
