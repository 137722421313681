import { TaxonomyMapDto } from '@reshub/dtos';
import { TFunction } from 'i18next';

export const formatStatus = (input: string) => input.replace('_', ' ').split(' ').map(capitalize).join(' ');
const capitalize = (word: string) => word[0].toUpperCase() + word.substr(1);

export const formatServiceRequestTitle = (taxonomyMap: TaxonomyMapDto, t: TFunction) =>
  `${taxonomyMap?.mezoItem?.label || t('serviceRequestDetails.unknown')} / ${
    (taxonomyMap?.mezoComponent?.label || '') + (taxonomyMap?.mezoComponent?.label ? ' /' : '')
  } ${taxonomyMap?.mezoSymptom?.label || t('serviceRequestDetails.other')}`;

export const formatServiceRequestSubTitle = (taxonomyMap: TaxonomyMapDto) =>
  `${taxonomyMap?.mezoLocation?.label ? taxonomyMap?.mezoLocation?.label + ' / ' : ''}${
    taxonomyMap?.mezoCategory?.label
  }`;
