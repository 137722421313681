import { Message, MessageMedium } from '@reshub/model';
import { SessionContextDto } from './session-context.dto';
type Diff<T extends keyof any, U extends keyof any> = ({ [P in T]: P } & { [P in U]: never } & {
  [x: string]: never;
})[T];
type Overwrite<T, U> = Pick<T, Diff<keyof T, keyof U>> & U;

export class MessageTransactionDto {
  id: string;
  residentId: string;
  dialogId?: string;
  loopId: string;
  incoming: MessageContext;
  outgoing: MessageContext;
  medium: MessageMedium;
  phoneNumber?: string;
  createdAt: number;
  commsThreadId?: string;
}

export interface _Message {
  botContext: SessionContextDto;
}

export interface MessageContext extends Overwrite<Message, _Message> {
  botContext: SessionContextDto;
}
