import { ApiClient } from '@mezo/web/api-client';
import { AddUserSessionDto, UpdateUserSessionDto, UserSessionDto } from '@reshub/dtos';
import { useMutation } from 'react-query';

export const useUserSession = () => {
  const useCreateUserSession = () => {
    return useMutation({
      mutationFn: async (request: AddUserSessionDto) => {
        const result = await ApiClient.CHAT_API.utility.post<UserSessionDto>(`/user-session`, request);
        return result?.data;
      },
      onSuccess: (response) => {
        const sessionId = response.id;
        ApiClient.CHAT_API.setUserSession(sessionId);
      },
    });
  };

  const useUpdateUserSession = () => {
    return useMutation({
      mutationFn: async ({ sessionId, request }: { sessionId: string; request: UpdateUserSessionDto }) => {
        const response = await ApiClient.CHAT_API.utility.put<UserSessionDto>(`/user-session/${sessionId}`, request);
        return response?.data;
      },
      onSuccess: (response) => {
        const sessionId = response.id;
        ApiClient.CHAT_API.setUserSession(sessionId);
      },
    });
  };

  return {
    useCreateUserSession,
    useUpdateUserSession,
  };
};
