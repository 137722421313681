export enum FirstAvailable {
  SAME_DAY_NEXT_AVAILABLE = 'SAME_DAY_NEXT_AVAILABLE',
  NEXT_BUSINESS_DAY = 'NEXT_BUSINESS_DAY',
  TWO_BUSINESS_DAYS_OUT = 'TWO_BUSINESS_DAYS_OUT',
}
export interface SchedulingConfiguration {
  enabled: boolean;
  blockLengthMinutes: number;
  blockCount: number;

  firstBlockStartHour: number;
  firstAvailable: FirstAvailable;

  daysToSchedule: number[];

  maxDaysOut: number;

  minDaysSelectable: number;
  minBlocksSelectable: number;
}

export interface EmergencyConfiguration {
  enabled: boolean;
  emergencyExitText?: string;
  emergencyItemIds: string[];
}

export interface OperationConfig {
  askEntryNotes?: boolean;
  askPermissionToEnter: boolean;
  botExit1: string;
  botExit2: string;
  botGoodbye: string;
  botIntro: string;
  botIntro1: string;
  botIntro2: string;
  botSupportContact: string;
  botSupportContact2: string;
  botSupportContact3: string;
  cesSurvey: boolean;
  csatSurvey: boolean;
  emergencies?: EmergencyConfiguration;
  emergencyPhoneNumber: string;
  feedbackSurvey: boolean;
  hasIntelliflowOverride: boolean;
  html: boolean;
  includeTechnicianViewLink: boolean;
  intelliflowOverrideId: string;
  isGuestRegistrationEnabled: boolean;
  maxSessionHours: number;
  multipleRequests: boolean;
  permissionToEnter: boolean;
  scheduling?: SchedulingConfiguration;
  sessionTimeoutMins: number;
  showEmergencyIntro: boolean;
  hideServiceRequest: boolean;
  serviceRequestHeaderText: string;
}
