import { ApiClient } from '@mezo/web/api-client';
import { AliasDto } from '@reshub/dtos';
import { useQuery } from 'react-query';

const getAliasByVanityId = async (vanityId: string | undefined) => {
  const { data } = await ApiClient.CHAT_API.utility.get<AliasDto>(`aliases/${vanityId}`);

  return data;
};

const useAliasUrl = (vanityId: string | undefined) => {
  const alias = useQuery(
    ['alias', vanityId],
    async () => {
      return getAliasByVanityId(vanityId);
    },
    {
      enabled: !!vanityId,
    }
  );

  return alias;
};

export const useAliasRedirect = (vanityId: string | undefined) => {
  const response = useAliasUrl(vanityId);

  if (response.isSuccess && response.data) {
    const { data: alias } = response;

    return alias.target;
  }

  return '';
};
