import { IsNotEmpty } from 'class-validator';
import { WriteableMediaFileInfoDto } from './media-file-info.dto';

export class MediafileUploadRequestDto {
  @IsNotEmpty()
  residentId: string;

  @IsNotEmpty()
  files: {
    name: string;
    type: string;
  }[];
}

export class MediafileUploadResponseDto {
  residentId: string;
  files: { [name: string]: WriteableMediaFileInfoDto };
}
