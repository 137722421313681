import type { CustomerId } from '@reshub/model';
import { IsArray, IsNumber, IsOptional, IsString } from 'class-validator';
import { ChatEvent } from './chat-events.dto';
import { MediaFileInfoDto } from './media-file-info.dto';

export class MessageDto {
  @IsString()
  @IsOptional()
  id?: string;
  @IsNumber()
  readonly timestamp: number;
  @IsString()
  @IsOptional()
  readonly customerId?: CustomerId;
  @IsString()
  @IsOptional()
  readonly dialogId: string;
  @IsString()
  @IsOptional()
  readonly loopId: string;
  @IsString()
  @IsOptional()
  readonly senderId?: string;
  @IsArray()
  readonly messages: string[];
  @IsOptional()
  readonly mediaFileInfos: MediaFileInfoDto[];
  @IsString()
  readonly residentId: string;
  @IsString()
  @IsOptional()
  readonly unitId?: string;
  @IsOptional()
  readonly chatEvents?: ChatEvent[];
  @IsOptional()
  readonly isSystemPush?: boolean;
  @IsOptional()
  readonly isDone?: boolean;
}

export const getResiNotEnrolledResponse = (): MessageDto => {
  return {
    dialogId: '',
    message: {
      messages: ['Please contact support@mezo.io to enroll in our service!'],
      senderId: 'BOT',
    },
  } as unknown as MessageDto;
};

export const getErrorResponseMessage = (): MessageDto => {
  return {
    dialogId: '',
    message: {
      messages: ["Oops - we're having trouble. Please try again or contact support@mezo.io for help"],
      senderId: 'BOT',
    },
  } as unknown as MessageDto;
};
