import type { CustomerId } from '@reshub/model';
import { IsBoolean, IsOptional, IsString } from 'class-validator';

export class SessionRequestDto {
  @IsString()
  readonly residentId: string;
  @IsBoolean()
  readonly resetSession: boolean;
  @IsString()
  @IsOptional()
  readonly customerId: CustomerId;
}
