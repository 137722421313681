import { useEffect } from 'react';
import tw, { styled } from 'twin.macro';

export type NavigationStackPageProps = React.PropsWithChildren & {
  title: string;
  isVisible?: boolean;
  isActive?: boolean;
  onActive?: VoidFunction;
};

const NavigationStackPageWrapper = styled.div<{ isVisible: boolean; isActive: boolean }>(({ isVisible, isActive }) => [
  tw`absolute top-0 bottom-0 z-20 flex flex-col invisible w-full overflow-y-auto transition-all duration-500 opacity-0 left-full`,
  isVisible && tw`left-0 opacity-100`,
  isActive && tw`visible`,
]);

export const NavigationStackPage: React.FC<NavigationStackPageProps> = ({
  isVisible = false,
  isActive = false,
  children,
  onActive,
}) => {
  useEffect(() => {
    if (isVisible && isActive) {
      if (onActive) {
        onActive?.();
      }
    }
  }, [isVisible, isActive, onActive]);
  return (
    <NavigationStackPageWrapper isVisible={isVisible} isActive={isActive}>
      {children}
    </NavigationStackPageWrapper>
  );
};
