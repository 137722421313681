import { faArrowRightFromBracket, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMinScreen } from '@mezo/web/hooks';
import { FirebaseAuthService } from '@mezo/web/services';
import { RoutePaths } from '@mezo/web/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';
import Logo from './Logo.svg';
import { BreadCrumb } from './breadcrumb';

const StyledNavbar = styled.nav(() => [
  tw`relative flex items-center justify-center px-2 py-8 md:px-8 md:justify-between bg-background-light h-[125px] md:h-[70px] md:py-0`,
  css`
    box-shadow: inset 0px -1px 0px #e2e2ea;
    flex: 0 1 auto;
  `,
]);

const MenuItemContainer = tw.div`flex gap-4`;
const UserEmail = tw.span`text-sm`;
const SignOut = tw.button`flex items-center gap-2 p-0 border-none cursor-pointer bg-none text-disabled`;
const StyledLogo = tw.img`m-0`;
const LeftContainer = tw.div`flex flex-col items-center gap-4 md:gap-6 md:flex-row`;
const RightContainer = tw.div`absolute m-auto top-4 right-4 md:flex md:justify-end md:m-0 lg:flex-col md:static`;
const SignOutContainer = styled.div<{ isMobileNavOpen: boolean }>(({ isMobileNavOpen }) => [
  tw`absolute right-0 z-20 flex-col items-end hidden px-5 py-5 bg-white shadow-lg md:bg-none md:shadow-none md:p-0 top-12 rounded-2xl md:flex md:static md:rounded-none md:z-0`,
  isMobileNavOpen && tw`flex`,
]);
const SignOutMenu = tw.div`relative flex items-center justify-end px-4 py-2 cursor-pointer md:px-2 md:py-0 md:hidden left-1`;

const MobileNavContainer = styled.div<{ isMobileNavOpen: boolean }>(({ isMobileNavOpen }) => [
  tw`transition-transform`,
  isMobileNavOpen ? `` : tw`rotate-90`,
]);

const MobileNav = tw(FontAwesomeIcon)`text-text-medium-dark`;
const ExitIcon = tw(FontAwesomeIcon)`text-text-light`;

type Props = {
  email: string | null;
};

export const Navbar: React.FC<Props> = ({ email }) => {
  const isResident = useMatch('/resident/*');
  const { t } = useTranslation();
  const handleSignOut = () => {
    FirebaseAuthService.logOut();
  };
  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);
  const toggleMobileNav = () => setIsMobileNavOpen(!isMobileNavOpen);
  const { min } = useMinScreen();

  if (isResident) return null;

  return (
    <StyledNavbar>
      <LeftContainer>
        <StyledLogo src={Logo} alt="Mezo Logo" />
        <MenuItemContainer>
          <BreadCrumb
            to={RoutePaths.DASHBOARD}
            icon="dashboard"
            label={t('app.navbar.dashboard')}
            showOnPageMatch={false}
          />
          <BreadCrumb
            to={RoutePaths.SERVICE_REQUEST}
            icon="service-request"
            label={t('app.navbar.serviceRequest')}
            showOnPageMatch={true}
          />
        </MenuItemContainer>
      </LeftContainer>
      <RightContainer>
        <SignOutMenu onClick={toggleMobileNav}>
          <MobileNavContainer aria-label="mobile-nav-icon" isMobileNavOpen={isMobileNavOpen}>
            <MobileNav icon={faEllipsis} size="lg" />
          </MobileNavContainer>
        </SignOutMenu>
        {(isMobileNavOpen || min`md`) && (
          <SignOutContainer isMobileNavOpen={isMobileNavOpen}>
            <UserEmail>{email}</UserEmail>
            <SignOut onClick={handleSignOut}>
              <span>{t('app.navbar.signOut')}</span>
              <ExitIcon icon={faArrowRightFromBracket} size="lg" />
            </SignOut>
          </SignOutContainer>
        )}
      </RightContainer>
    </StyledNavbar>
  );
};

export default Navbar;
