import { IUpdatable } from './model';

export class Emergency implements IUpdatable {
  id: string;
  label: string;
  itemId: string;
  symptomId: string;
  is911: boolean;

  createdBy?: string;
  createdAt?: number;
  updatedBy?: string;
  updatedAt?: number;
}
