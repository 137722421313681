export type CustomerId = string;

export class Customer {
  id: CustomerId;
  jobs?: CustomerJobs;
}

export interface CustomerJobs {
  residentsLastUpdatedAt?: number;
  unitsLastUpdatedAt?: number;
  workOrdersLastUpdatedAt?: number;
  invoicesLastUpdatedAt?: number;
}

export const customerCacheKeyFactory = (customerId: CustomerId) => `CUSTOMER-${customerId}`;

export enum CustomerSourceSystem {
  APPFOLIO = 'Appfolio',
  ENTRATA = 'Entrata',
  MYND = 'Mynd',
  DARWIN = 'Darwin',
  REVELA = 'Revela',
  DEFAULT = 'Default',
  YARDI = 'Yardi',
}
