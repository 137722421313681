import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatEvent, ChoiceButtonsDto, MessageDto, ResidentSearchResultResponseDto } from '@reshub/dtos';

type SessionState = {
  isLoading: boolean;
  error: boolean;
  messages: MessageDto[];
  residents: ResidentSearchResultResponseDto[];
  residentId: string;
  customerId: string;
  dialogId: string;
  loopId: string;
  hasSystemPush: boolean;
  isDone: boolean;
  choiceButtons: ChoiceButtonsDto[];
  chatEvents: ChatEvent[];
};

export const chatInitialState: SessionState = {
  isLoading: false,
  error: false,
  messages: [],
  residents: [],
  residentId: '',
  customerId: '',
  dialogId: '',
  loopId: '',
  hasSystemPush: false,
  isDone: false,
  choiceButtons: [],
  chatEvents: [],
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState: chatInitialState,
  reducers: {
    chatApiCallStart: (state) => {
      state.isLoading = true;
    },
    chatApiCallSuccess: (state) => {
      state.isLoading = false;
    },
    chatApiCallError: (state, action) => {
      state.isLoading = false;
      state.error = true;
    },
    addMessages: (state, action: PayloadAction<MessageDto>) => {
      const { chatEvents = [] } = action.payload;
      state.messages.push(action.payload);
      state.chatEvents = chatEvents;
      state.isDone = action.payload.isDone ?? false;
    },
    clearMessages: (state) => {
      state.messages = [];
      state.choiceButtons = [];
      state.chatEvents = [];
    },
    updateResidents: (state, action: PayloadAction<ResidentSearchResultResponseDto[]>) => {
      state.residents = action.payload;
    },
    setDialog: (state, action: PayloadAction<{ dialogId: string; loopId: string }>) => {
      const { dialogId, loopId } = action.payload;
      state.dialogId = dialogId;
      state.loopId = loopId;
    },
    setHasSystemPush: (state, action: PayloadAction<boolean>) => {
      state.hasSystemPush = action.payload;
    },
    setResidentId: (state, action: PayloadAction<string>) => {
      state.residentId = action.payload;
    },
    setChatCustomerId: (state, action: PayloadAction<string>) => {
      state.customerId = action.payload;
    },
    setDone: (state) => {
      state.isDone = true;
    },
  },
});

export default chatSlice;
