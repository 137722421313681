import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import { ResizeableTextArea } from '../textarea';

const Content = tw.div`p-4`;
const Intro = tw.div`mb-8 text-base font-bold`;
const Subtitle = tw.label`inline-block mb-2 text-base font-normal`;
const Spacer = tw.div`h-8`;
const TextAreaWrapper = styled.div<{ isFocused: boolean }>(({ isFocused }) => [
  tw`flex px-3 border border-background`,
  css({ 'border-radius': '10px' }),
  isFocused && tw`border-disabled`,
]);

type AdditionalDetailsViewProps = {
  showPermissionToEnter?: boolean;
  showEntryNotes?: boolean;
  onPermissionToEnterChanged: (permissionToEnter: boolean) => void;
  onEntryNotesChanged: (entryNotes: string) => void;
  permissionToEnter?: boolean;
  entryNotes: string;
};

export const AdditionalDetailsView: React.FC<AdditionalDetailsViewProps> = ({
  showPermissionToEnter,
  showEntryNotes,
  onPermissionToEnterChanged,
  onEntryNotesChanged,
  permissionToEnter,
  entryNotes,
}) => {
  const { t } = useTranslation();

  const [entryNotesFocused, setEntryNotesFocused] = useState<boolean>(false);

  const handleEntryNotesChanged = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onEntryNotesChanged(e.target.value);
    },
    [onEntryNotesChanged]
  );

  const handleEntryNotesBlurred = useCallback(() => {
    setEntryNotesFocused(false);
  }, []);

  const handleEntryNotesFocused = useCallback(() => {
    setEntryNotesFocused(true);
  }, []);

  return (
    <Content>
      <Intro>{t('chat.additionalDetails.intro')}</Intro>
      {showPermissionToEnter && (
        <>
          <Subtitle>{t('chat.additionalDetails.permissionToEnter')}</Subtitle>
          <ToggleSwitch onValueChanged={onPermissionToEnterChanged} initialValue={permissionToEnter} />
        </>
      )}
      {showPermissionToEnter && showEntryNotes && <Spacer />}
      {showEntryNotes && (
        <>
          <Subtitle htmlFor="entryNotes">{t('chat.additionalDetails.entryNotes')}</Subtitle>
          <TextAreaWrapper isFocused={entryNotesFocused}>
            <ResizeableTextArea
              id="entryNotes"
              name="entryNotes"
              minRows={2}
              maxRows={2}
              value={entryNotes}
              onChange={handleEntryNotesChanged}
              onFocus={handleEntryNotesFocused}
              onBlur={handleEntryNotesBlurred}
            />
          </TextAreaWrapper>
        </>
      )}
    </Content>
  );
};

type ToggleSwitchProps = {
  initialValue?: boolean | undefined;
  onValueChanged: (value: boolean) => void;
};

const ToggleSwitchWrapper = styled.div(() => [
  tw`flex overflow-hidden border bg-background border-disabled h-14`,
  css({ 'border-radius': '10px' }),
]);
const ToggleButton = styled.button<{ isActive: boolean }>(({ isActive }) => [
  tw`flex-1 flex-shrink-0 text-center first:border-r text-disabled first:border-r-disabled`,
  isActive && tw`text-white bg-primary`,
]);

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ onValueChanged, initialValue }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<boolean | undefined>(initialValue);

  useEffect(() => {
    if (value === undefined) {
      return;
    }
    onValueChanged(value);
  }, [onValueChanged, value]);

  const handleYesClicked = useCallback(() => {
    setValue(true);
  }, []);

  const handleNoClicked = useCallback(() => {
    setValue(false);
  }, []);

  return (
    <ToggleSwitchWrapper>
      <ToggleButton isActive={value === true} onClick={handleYesClicked}>
        {t('chat.additionalDetails.permissionToEnterYes')}
      </ToggleButton>
      <ToggleButton isActive={value === false} onClick={handleNoClicked}>
        {t('chat.additionalDetails.permissionToEnterNo')}
      </ToggleButton>
    </ToggleSwitchWrapper>
  );
};
