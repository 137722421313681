import { ApiClient } from '@mezo/web/api-client';
import { useCallback, useEffect } from 'react';

export const useResidentAuth = (
  customerId: string | undefined,
  residentId: string | undefined,
  unitId: string | undefined
) => {
  const fetchChatToken = useCallback(async (customerId: string, unitId: string, residentId: string) => {
    try {
      const { data } = await ApiClient.CHAT_API.utility.post('/token/chat', { customerId, unitId, residentId });
      return data;
    } catch (err) {
      console.error(`Unable to fetch token for customer ${customerId}, resident ${residentId}, and unit ${unitId}`);
    }
    return;
  }, []);

  useEffect(() => {
    async function getIdToken() {
      if (unitId && residentId && customerId && !ApiClient.CHAT_API.hasAuthToken()) {
        const token = await fetchChatToken(customerId, unitId, residentId);
        if (token) {
          ApiClient.CHAT_API.setAuthToken(`Bearer ${token}`);
        }
      }
    }
    getIdToken();
  }, [customerId, unitId, residentId, fetchChatToken]);
};
