import { AriaButtonProps, useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { useRef } from 'react';
import tw, { styled } from 'twin.macro';

const StyledButton = styled('button')<{ isFocusVisible: boolean }>(({ isFocusVisible }) => [
  tw`flex items-center justify-center w-10 h-10 text-2xl text-black transition-all border-none rounded-full outline-none appearance-none bg-none hover:text-white hover:bg-primary active:bg-primary outline-dotted outline-1 focus:outline-dotted focus:outline-1`,
  isFocusVisible && tw`outline-dotted outline-1`,
]);

export const PaginationButton = (props: AriaButtonProps<'button'>) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  return (
    <StyledButton {...mergeProps(buttonProps, focusProps)} isFocusVisible={isFocusVisible} ref={ref}>
      {props.children}
    </StyledButton>
  );
};
