import { IsArray, IsObject, IsString } from 'class-validator';
import { TaxonomyEntityResponseDto } from './taxonomy-entity-response.dto';

class ItemSymptomDto {
  canPlug?: boolean;
  isPrime?: boolean;
  frequency: number;
}

export class ItemResponseDto extends TaxonomyEntityResponseDto {
  @IsString()
  categoryId: string;
  @IsArray()
  components: string[];
  @IsArray()
  locations: string[];
  @IsObject()
  symptoms: {
    [key: string]: ItemSymptomDto;
  };
  @IsArray()
  primeSymptoms?: {
    [key: string]: ItemSymptomDto;
  };
}
