import { CustomerId, UnitId } from '@reshub/model';
import { AddressDto } from './address.dto';

export class UnitDto {
  id: UnitId;
  sourceId: string;
  customerId: CustomerId;
  customerName: string;
  customerIconUrl: string;
  propertyId: string;
  propertyName: string;
  address: AddressDto;
  yearBuilt: string;
  type: string;
  squareFootage: number;
  bedroomsNumber: number;
  fullBathroomsNumber: number;
  halfBathroomsNumber: number;
  floor: number;
  floors: number;
  createdAt: number;
  updatedAt: number;
}
