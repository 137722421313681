import { HttpStatus } from '@nestjs/common';
import { MezoException } from './mezo.exception';

export class ResidentNotFoundException extends MezoException {
  override message: string;

  constructor(message = 'Resident not found') {
    super(message);
    this.status = HttpStatus.NOT_FOUND;
    this.message = message;
  }
}
