import { MezoStylesBase, TwThemeProvider } from '@mezo/web/contexts';
import { MinScreenProvider } from '@mezo/web/hooks';
import { store } from '@mezo/web/store';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';
import { GlobalStyles as TwGlobalStyles, theme } from 'twin.macro';
import App from './app/app';

import './wdyr';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <MinScreenProvider screens={theme`screens`}>
        <Provider store={store}>
          <TwThemeProvider initialTheme="mezo">
            <ModalProvider>
              <TwGlobalStyles />
              <MezoStylesBase />

              <App />
            </ModalProvider>
          </TwThemeProvider>
        </Provider>
      </MinScreenProvider>
    </BrowserRouter>
  </StrictMode>
);
