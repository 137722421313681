import { CustomerId, DeliveryIntegrationTypeOptions } from '@reshub/model';

//NOTE: this is assuming Alogolia Text search
export class ResidentSearchResponseDto {
  docs: ResidentSearchResultResponseDto[];
}

export class ResidentSearchResultResponseDto {
  residentId: string;
  unitId: string;
  sourceId: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  propertyId: string;
  propertyName: string;
  customerName: string;
  customerIconUrl: string;
  customerId: CustomerId;
  deliveryIntegrationTypeOverride?: DeliveryIntegrationTypeOptions;
  isGuest?: boolean;
}
