interface KeyValuePair {
  id: string;
  label: string;
}

export enum TaxonomyLookupScheme {
  SIMPLE_KEY = 'SIMPLE_KEY',
  COMPOUND_KEY = 'COMPOUND_KEY',
}

export class TaxonomyLookupPattern {
  id: number; //canonical id
  pattern: string[];
}

export type ItemKVP = KeyValuePair;
export type SymptomKVP = KeyValuePair;
export type ComponentKVP = KeyValuePair;
export type LocationKVP = KeyValuePair;
export type CategoryKVP = KeyValuePair;
export type ResolutionTypeKVP = KeyValuePair;
export type SkillLevelKVP = KeyValuePair;

export class Item {
  id: string;
  label: string;
  categoryId: string;
  components: string[];
  locations: string[];
  createdAt: number;
  entityId: string;
  symptoms: {
    [key: string]: ItemSymptom;
  };
  synonyms: string[];
  updatedBy: string;
  updatedAt: number;
  version: string;
  primeSymptoms: {
    [key: string]: ItemSymptom;
  };
}

export class ItemSymptom {
  canPlug?: boolean;
  isPrime?: boolean;
  frequency: number;
}

export class Component {
  id: string;
  label: string;
  updatedBy: string;
  updatedAt: number;
  version: string;
  synonyms: string[];
}

export class Location {
  id: string;
  label: string;
  updatedBy: string;
  updatedAt: number;
  version: string;
  synonyms: string[];
}

export class Symptom {
  id: string;
  label: string;
  updatedBy: string;
  updatedAt: number;
  version: string;
  synonyms: string[];
  relatedSymptoms?: string[];
  subSymptom?: string[];
}

export class CustomerLocationMap {
  id: string;
  label: string;
  customerId: string;
  customerLabel: string;
  createdAt: number;
  updatedAt: number;
}

export class PrimeTaxonomy {
  id: string;

  taxonomy: {
    itemId: string;
    symptomId?: string;
    componentId?: string;
  };

  skillsets?: string[];
}
