import { NavLink as Link, useMatch, useResolvedPath } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';
import { IconName } from '../icon';
import { MenuItem } from '../menu-item';

interface BreadCrumbProps {
  to: string;
  icon: IconName;
  label: string;
  showOnPageMatch: boolean;
}

const BreadCrumbContainer = styled.div(() => [
  tw`relative`,
  css`
    & + & {
      ${tw`ml-4`}
      &:before {
        content: '';
        ${tw`absolute top-0 bottom-0 block m-auto h-3/5 rotate-[30deg] w-[1px] bg-text-light -left-4`}
      }
    }
  `,
]);

export const BreadCrumb: React.FC<BreadCrumbProps> = ({ to, icon, label, showOnPageMatch }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const isMatch = to === match?.pattern.path;

  const generateMenuItem = (isMatch: boolean) =>
    isMatch ? (
      <BreadCrumbContainer>
        <MenuItem icon={icon} label={label} isActive={isMatch} />
      </BreadCrumbContainer>
    ) : (
      <BreadCrumbContainer>
        <Link to={to}>
          <MenuItem icon={icon} label={label} isActive={isMatch} />
        </Link>
      </BreadCrumbContainer>
    );

  if (showOnPageMatch) {
    return isMatch ? generateMenuItem(isMatch) : null;
  }
  return generateMenuItem(isMatch);
};
