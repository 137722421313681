import { ApiClient } from '@mezo/web/api-client';
import { KeyValuePairDto } from '@reshub/dtos';
import { useQuery } from 'react-query';

export const useSymptomsForItem = (itemId: string | undefined, isPrime = true) => {
  return useQuery(
    ['symptoms', { itemId, isPrime }],
    async () => {
      const { data } = await ApiClient.CHAT_API.utility.get<KeyValuePairDto[]>(
        `/items/${itemId}/symptoms?isPrime=${isPrime}`
      );
      return data;
    },
    { enabled: !!itemId }
  );
};
