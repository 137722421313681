export class EmailTemplateDynamicContentDto {
  serviceRequest: EmailTemplateServiceRequestDto;
  resident: EmailTemplateResidentDto;
  property: {
    name: string;
  };
  customer: {
    sourceSystem: string;
    logoUrl: string;
    primaryColor: string;
  };
  Sender_Name: string;
  Sender_Address: string;
  Sender_City: string;
  Sender_State: string;
  Sender_Zip: number;
}

class EmailTemplateServiceRequestDto {
  id: string;
  priority: string;
  isEmergency: boolean;
  description: string;
  problemStatement: string;
  chatHistoryUrl: string;
  hasAttachments: boolean;
  diagnosis: string;
  assets: string;
  entryNotes?: string;
  sourceSystemUrl: string;
}

class EmailTemplateResidentDto {
  name: string;
  address: string;
  availability: string;
  permissionToEnter: string;
  askPermissionToEnter: boolean;
}

export class EmailTemplateAttachmentDto {
  content: string;
  filename: string;
  type?: string;
  disposition?: string;
  content_id?: string;
}
