import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import tw, { styled } from 'twin.macro';

const bgStyles = `w-full p-4 text-center bg-light-grey rounded-xl`;

const BannerContainer = tw.div`${bgStyles}`;
const BannerButton = tw.button`${bgStyles}`;
const BannerText = tw.span`block text-sm text-text-medium-dark`;
const BannerTextUnderline = tw(BannerText)`underline`;
const BannerRow = styled.div<{ hasIcon: boolean }>(({ hasIcon }) => [
  tw`flex justify-center gap-2`,
  hasIcon && tw`justify-start`,
]);

const TextContainer = styled.div<{ hasIcon: boolean }>(({ hasIcon }) => [hasIcon && tw`text-left`]);

type ChatBannerProps = {
  icon?: IconDefinition;
  lineOne: string;
  lineTwo?: string;
  onBannerClick?: VoidFunction;
};

export const ChatBanner: React.FC<ChatBannerProps> = ({ icon, onBannerClick, lineOne, lineTwo }) => {
  const hasIcon = !!icon;
  const Wrapper = useMemo(() => (onBannerClick ? BannerButton : BannerContainer), [onBannerClick]);
  return (
    <Wrapper onClick={onBannerClick || undefined}>
      <BannerRow hasIcon={hasIcon}>
        {hasIcon && <FontAwesomeIcon icon={icon} />}
        <TextContainer hasIcon={hasIcon}>
          {lineOne && <BannerText>{lineOne}</BannerText>}
          {lineTwo && <BannerTextUnderline>{lineTwo}</BannerTextUnderline>}
        </TextContainer>
      </BannerRow>
    </Wrapper>
  );
};
