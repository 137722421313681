import {
  CustomerSourceSystem,
  DeliveryIntegrationTypeOptions,
  ServiceRequestPriority,
  TaxonomyLookupPattern,
  TaxonomyLookupScheme,
} from '@reshub/model';
import { ServiceRequestMediaType } from '../service-request/service-request-media-types.dto';

export enum NotificationChannel {
  EMAIL = 'EMAIL',
}

export enum NotificationActions {
  SERVICE_REQUEST_DELIVERED = 'SERVICE_REQUEST_DELIVERED',
}

interface EmailNotificationChannelDetails {
  recipients: { email: string }[];
}

type Notifications = {
  [action in NotificationActions]: {
    channels: {
      [channel in NotificationChannel]: EmailNotificationChannelDetails;
    };
  };
};

export interface YardiUserDefinedMapping {
  keyPath: string;
  maxLength?: number;
}

export interface YardiIntegrationSettings {
  attachmentTypeMapping: {
    [ServiceRequestMediaType.Pdf]?: string;
    [ServiceRequestMediaType.Image]?: string;
    [ServiceRequestMediaType.Video]?: string;
    [ServiceRequestMediaType.Text]?: string;
    [ServiceRequestMediaType.Binary]?: string;
  };
  statusReasonCodeMapping?: Record<string, string>;
  userDefinedFieldsMapping?: Record<string, YardiUserDefinedMapping>;
  originCode?: string;
}

export interface IntegrationConfig {
  autoDeliverServiceRequestMinutes?: number;
  maxDaysInPast?: number;
  baseUrl?: string;
  createDisabled?: boolean;
  createEndpoint?: string;
  deliveryIntegrationType?: DeliveryIntegrationTypeOptions;
  generateResidentConversationImage?: boolean;
  includeTechnicianViewLink: boolean;
  jobs: { enabled: boolean } | undefined;
  maintenanceEmployeeId?: number;
  managedPropertyIds?: string[];
  messageEndpoint: string;
  messagesDisabled: boolean;
  taxonomyMapKey?: string;
  taxonomyLookupPatterns?: TaxonomyLookupPattern[];
  taxonomyLookupScheme?: TaxonomyLookupScheme;
  updateDisabled?: boolean;
  updateEndpoint?: string;
  headerSecretKey: string;
  integrationSystem: CustomerSourceSystem;
  includeChatTranscript?: boolean;
  emergencyPriorityCode?: string;
  priorityCodeMapping?: {
    [ServiceRequestPriority.EMERGENCY]: string;
    [ServiceRequestPriority.NORMAL]: string;
  };
  yardi?: YardiIntegrationSettings;
  notifications?: Notifications;
}
