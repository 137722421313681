import { ApiClient } from '@mezo/web/api-client';
import { DialogDto, DialogUpdateRequestDto, ResidentFeedbackDto } from '@reshub/dtos';
import { useMutation, useQuery } from 'react-query';

export const useDialog = (dialogId: string | undefined, options?: { enabled: boolean }) => {
  return useQuery(
    ['dialogs', dialogId],
    async () => {
      const { data } = await ApiClient.CHAT_API.utility.get<DialogDto>(`dialogs/${dialogId}`);
      return data;
    },
    options
  );
};

export const useUpdateDialog = () => {
  return useMutation((update: { id: string; payload: DialogUpdateRequestDto }) => {
    return ApiClient.CHAT_API.utility.patch(`dialogs/${update.id}`, update.payload);
  });
};

export const useUpdateDialogFeedback = () => {
  return useMutation((update: { id: string; payload: ResidentFeedbackDto }) => {
    return ApiClient.CHAT_API.utility.patch(`dialogs/${update.id}/feedback`, update.payload);
  });
};
