export enum BotEvent {
  WELCOME = 'WELCOME',
  FILE_UPLOAD = 'FileUploadEvent',
  END_SESSION = 'END_SESSION',
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  AGENT_RESTART_CANCEL = 'AGENT_RESTART_CANCEL',
  BUTTON_EVENT = 'BUTTON_EVENT',
  SCHEDULE_COMPLETE = 'SCHEDULE_COMPLETE',
  PRIME_SYMPTOM_COLLECTED = 'PRIME_SYMPTOM_COLLECTED',
  PRIME_SYMPTOM_NOT_COLLECTED = 'PRIME_SYMPTOM_NOT_COLLECTED',
  SUBMIT_NEW_ISSUE = 'SUBMIT_NEW_ISSUE',
}
