//This dto defines the data return when searching for service requests

import { CustomerId, ServiceRequestStatus } from '@reshub/model';
import { AddressDto } from '../customer';
import { TaxonomyMapDto } from './service-request-response.dto';

export class ServiceRequestSearchResultDetailsResponseDto {
  isEmergency: boolean;
  description: string;
  descriptionHtml: string;
}

export class ServiceRequestSearchResultUnitResponseDto {
  id: string;
  propertyName: string;
  customerId: CustomerId;
  customerIconUrl: string;
  address: AddressDto;
}

export class ServiceRequestSearchResultReportedByResponseDto {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export class ServiceRequestSearchResultResponseDto {
  id: string;
  createdAt: number;
  status: ServiceRequestStatus;
  numMediaFiles: number;
  hasAssetFiles: boolean;
  isRead: boolean;
  unit: ServiceRequestSearchResultUnitResponseDto;
  isEmergency: boolean;
  taxonomyMap: TaxonomyMapDto;
  isNonMaintenance?: boolean;
}
