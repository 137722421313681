import { Locale } from '@mezo/shared/utils';
import { ApiClient } from '@mezo/web/api-client';
import { PayloadAction } from '@reduxjs/toolkit';
import { KeyValuePairDto, MessageDto, ServiceRequestResponseDto } from '@reshub/dtos';
import { ServiceRequestPriority } from '@reshub/model';
import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { serviceRequestSlice } from '../slices/service-request.slice';

const {
  actions: {
    serviceRequestApiCallError,
    serviceRequestApiCallStart,
    serviceRequestApiCallSuccess,
    setMessages,
    setSelectedServiceRequest,
    setAsset,
  },
} = serviceRequestSlice;

const ENDPOINTS = {
  getServiceRequest: (id: string) => `/service-requests/${id}`,
  getMessages: '/messages/search',
  createServiceRequest: (id: string) => `/service-requests/${id}/create`,
  deliverServiceRequest: (id: string) => `/service-requests/${id}/deliver`,
  archiveServiceRequest: (id: string) => `/service-requests/${id}/archive`,
};

enum SagaNames {
  getServiceRequest = 'getServiceRequest',
  getMessages = 'getMessages',
  archiveServiceRequest = 'archiveServiceRequest',
  updateServiceRequest = 'updateServiceRequest',
  createServiceRequest = 'createServiceRequest',
  deliverServiceRequest = 'deliverServiceRequest',
}

const sliceName = 'serviceRequest';

const { NON_CHAT_API: INTERNAL_API, CHAT_API } = ApiClient;

const sagaSlice = createSliceSaga({
  name: sliceName,
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    [SagaNames.getServiceRequest]: {
      *fn(action: PayloadAction<{ id: string; locale: Locale | undefined }>) {
        yield put(serviceRequestApiCallStart());
        yield put(setAsset(null));
        try {
          const result: any = yield call(() => {
            const headers: Record<string, string> = {};
            if (action.payload.locale) {
              headers['Accept-Language'] = action.payload.locale;
            }
            return INTERNAL_API.utility.get(ENDPOINTS.getServiceRequest(action.payload.id), { headers });
          });
          const data: ServiceRequestResponseDto = result.data;
          yield put(setSelectedServiceRequest(data));
          yield put(serviceRequestApiCallSuccess());
        } catch (err: any) {
          yield put(serviceRequestApiCallError(err.toString()));
          yield call(toast, 'There was an error retrieving service request data');
        }
      },
      sagaType: SagaType.TakeLatest,
    },
    [SagaNames.getMessages]: {
      *fn(action: PayloadAction<{ dialogId: string; loopId: string }>) {
        try {
          const { dialogId, loopId } = action.payload;
          const payload = { dialogId, loopId };
          const result: any = yield call(INTERNAL_API.utility.post, ENDPOINTS.getMessages, payload);
          const data: MessageDto[] = result?.data;
          yield put(setMessages(data));
        } catch (err: any) {
          yield put(serviceRequestApiCallError(err.toString()));
          yield call(toast, 'There was an error retrieving messages');
        }
      },
      sagaType: SagaType.TakeLatest,
    },

    [SagaNames.updateServiceRequest]: {
      *fn(
        action: PayloadAction<{
          id: string;
          details?: {
            item?: KeyValuePairDto;
            component?: KeyValuePairDto;
            symptom?: KeyValuePairDto;
            location?: KeyValuePairDto;
            description?: string;
            diagnosis?: KeyValuePairDto;
            priority?: ServiceRequestPriority;
          };
        }>
      ) {
        try {
          yield put(serviceRequestApiCallStart());
          const editResponse: any = yield call(
            INTERNAL_API.utility.patch,
            ENDPOINTS.getServiceRequest(action.payload.id),
            action.payload.details
          );
          const data: ServiceRequestResponseDto = editResponse.data;
          yield put(setSelectedServiceRequest(data));
          yield put(serviceRequestApiCallSuccess());
        } catch (err: any) {
          yield put(serviceRequestApiCallError(err.toString()));
          yield call(toast, 'There was an error updating this service request');
        }
      },
      sagaType: SagaType.TakeLatest,
    },

    [SagaNames.createServiceRequest]: {
      *fn(action: PayloadAction<string>) {
        try {
          yield put(serviceRequestApiCallStart());
          const createResponse: any = yield call(CHAT_API.utility.post, ENDPOINTS.createServiceRequest(action.payload));
          const data: ServiceRequestResponseDto = createResponse.data;
          yield put(setSelectedServiceRequest(data));
          yield put(serviceRequestApiCallSuccess());
        } catch (err: any) {
          yield put(serviceRequestApiCallError(err.toString()));
          yield call(toast, 'There was an error creating this service request');
        }
      },
      sagaType: SagaType.TakeLatest,
    },

    [SagaNames.deliverServiceRequest]: {
      *fn(action: PayloadAction<{ id: string; workorderNumber?: string }>) {
        try {
          yield put(serviceRequestApiCallStart());
          const payload = action.payload.workorderNumber
            ? { workorderNumber: action.payload.workorderNumber }
            : undefined;
          const deliverResponse: any = yield call(
            INTERNAL_API.utility.post,
            ENDPOINTS.deliverServiceRequest(action.payload.id),
            payload
          );
          const data: ServiceRequestResponseDto = deliverResponse.data;
          yield put(setSelectedServiceRequest(data));
          yield put(serviceRequestApiCallSuccess());
        } catch (err: any) {
          yield put(serviceRequestApiCallError(err.toString()));
          yield call(toast, 'There was an error delivering this service request');
        }
      },
      sagaType: SagaType.TakeLatest,
    },

    [SagaNames.archiveServiceRequest]: {
      *fn(action: PayloadAction<{ id: string; archiveReason: string; archiveReasonDescription?: string }>) {
        try {
          yield put(serviceRequestApiCallStart());
          const { id, archiveReason, archiveReasonDescription } = action.payload;
          const archiveResponse: any = yield call(INTERNAL_API.utility.patch, ENDPOINTS.archiveServiceRequest(id), {
            archiveReason,
            archiveReasonDescription,
          });
          yield put(setSelectedServiceRequest(archiveResponse.data));
          yield put(serviceRequestApiCallSuccess());
        } catch (err: any) {
          yield put(serviceRequestApiCallError(err.toString()));
          yield call(toast, 'There was an error archving this service request');
        }
      },
      sagaType: SagaType.TakeLatest,
    },
  },
});

export const serviceRequestSaga = sagaSlice.saga;
export const {
  getServiceRequest,
  getMessages,
  updateServiceRequest,
  archiveServiceRequest,
  createServiceRequest,
  deliverServiceRequest,
} = sagaSlice.actions;
