import { Diagnosis } from '@reshub/model';

export class DiagnosisDto implements Diagnosis {
  id: string;
  itemId: string;
  symptomId: string;
  resolutionTypeId?: string;
  skillLevelId?: string;
  diagnosis: string;
  recommendedFix?: string;
  partsToolsNeeded?: string;
  version: string;
}
