import { HttpStatus } from '@nestjs/common';
import { MezoException } from './mezo.exception';

export class ArchiveRequestMissingRequiredDescriptionException extends MezoException {
  override message: string;

  constructor(
    message = 'Service Request Archive of ServiceRequestArchiveReason.OTHER is missing required description'
  ) {
    super(message);
    this.status = HttpStatus.BAD_REQUEST;
    this.message = message;
  }
}
