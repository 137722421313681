import { ApiClient } from '@mezo/web/api-client';
import {
  RegisterGuestResidentRequest,
  ResidentSearchResultResponseDto,
  ServiceRequestResidentViewDto,
  TenantUserDto,
  UnitDto,
} from '@reshub/dtos';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

const mapTenantUserDtoToResidentSearchResultResponseDto = (
  tenantUser: TenantUserDto
): ResidentSearchResultResponseDto => ({
  sourceId: tenantUser.tenantData.sourceId,
  residentId: tenantUser.id,
  unitId: tenantUser.tenantData.primaryUnit.id,
  firstName: tenantUser.data.contact.firstName,
  lastName: tenantUser.data.contact.lastName,
  address1: tenantUser.tenantData.primaryUnit.address?.address1 ?? '',
  address2: tenantUser.tenantData.primaryUnit.address?.address2 ?? '',
  city: tenantUser.tenantData.primaryUnit.address?.city ?? '',
  state: tenantUser.tenantData.primaryUnit.address?.state ?? '',
  zip: tenantUser.tenantData.primaryUnit.address?.zip ?? '',
  country: tenantUser.tenantData.primaryUnit.address?.country ?? '',
  propertyId: tenantUser.tenantData.primaryUnit.propertyId,
  propertyName: tenantUser.tenantData.primaryUnit.propertyName,
  customerName: tenantUser.tenantData.primaryUnit.customerName,
  customerIconUrl: tenantUser.tenantData.primaryUnit.customerIconUrl,
  deliveryIntegrationTypeOverride: tenantUser.tenantData.deliveryIntegrationTypeOverride,
  customerId: tenantUser.tenantData.primaryUnit.customerId,
  isGuest: tenantUser.tenantData.isGuest,
});

export const useResident = (
  residentId: string | undefined,
  options:
    | Omit<
        UseQueryOptions<any, unknown, ResidentSearchResultResponseDto, (string | undefined)[]>,
        'queryKey' | 'queryFn'
      >
    | undefined
) =>
  useQuery(
    ['resident', residentId],
    async () => {
      const { data } = await ApiClient.CHAT_API.utility.get(`/residents/${residentId}`);
      return data;
    },
    {
      enabled: !!residentId,
      select: (resident) => mapTenantUserDtoToResidentSearchResultResponseDto(resident),
      ...options,
    }
  );

export const useResidentUnits = (residentId: string | undefined) =>
  useQuery(
    ['resident', residentId, 'units'],
    async () => {
      const { data } = await ApiClient.CHAT_API.utility.get(`residents/${residentId}/units`);
      return data as UnitDto[];
    },
    {
      enabled: !!residentId,
    }
  );

export const useGetServiceRequestsForResident = (residentId?: string) =>
  useQuery(
    ['getServiceRequestsForResident', residentId],
    async () => {
      const { data } = await ApiClient.CHAT_API.utility.get<ServiceRequestResidentViewDto[]>(
        `/residents/${residentId}/service-requests`
      );
      return data;
    },
    {
      enabled: !!residentId,
    }
  );

export const useCreateGuestResident = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ guestResident }: { guestResident: RegisterGuestResidentRequest }) => {
      guestResident.isGuest = true;
      const { data } = await ApiClient.CHAT_API.utility.post<TenantUserDto>(`/residents`, guestResident);
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueriesData(['guestResident'], data);
    },
  });
};
