import { Address } from './address.model';
import { BaseModel, BaseModelWithId } from './base.model';
import { Contact } from './contact.model';
import { CustomerId } from './customer.model';
import { DeliveryIntegrationTypeOptions } from './delivery-integration-type';
import { RecentResidentProfile } from './recent-resident-profile.model';
import { Role } from './roles.enum';
import { Unit } from './unit.model';

export type GroupData = BaseModel;

export interface MezoData extends GroupData {
  recentResidentProfiles: RecentResidentProfile[];
}

export type UserId = string;
export type UnitId = string;
export type SourceId = string;

export interface TenantData extends GroupData {
  isCopy?: boolean;
  customerId: CustomerId;
  sourceId: SourceId;
  sourceUpdatedAt?: string;
  moveInDate?: Date;
  primaryUnit: Unit;
  unitIds: UnitId[];
  updatedAt?: number;
  createdAt: number;
  deliveryIntegrationTypeOverride?: DeliveryIntegrationTypeOptions;
  isGuest?: boolean;
  guestAddress?: Address;
}

export interface TechnicianData extends GroupData {
  customerId: CustomerId;
}

export interface User extends BaseModelWithId {
  data: {
    contact: Contact;
  };
  roles?: Role[];
}

export interface MezoUser extends User {
  mezoData: MezoData;
}

export function isMezoUser(user: User | MezoUser | TenantUser): user is MezoUser {
  return user && Object.prototype.hasOwnProperty.call(user, 'mezoData');
}

export interface TenantUser extends User {
  tenantData: TenantData;
}

export interface TechnicianUser extends User {
  technicianData: TechnicianData;
}

export function isTenantUser(user: User | TenantUser): user is TenantUser {
  return user && Object.prototype.hasOwnProperty.call(user, 'tenantData');
}

export function isTechnicianUser(user: User | TechnicianUser): user is TechnicianUser {
  return user && Object.prototype.hasOwnProperty.call(user, 'technicianData');
}

export const userCacheKeyFactory = (id: string) => `USER-${id}`;

export interface TenantUserSimple extends Omit<TenantUser, 'createdAt' | 'createdBy' | 'tenantData'> {
  tenantData: Omit<TenantData, 'createdAt' | 'createdBy'>;
  jobId?: string;
}
