import { all, call } from 'redux-saga/effects';
import { chatSaga } from './chat.saga';
import { dashboardSaga } from './dashboard.saga';
import { serviceRequestSaga } from './service-request.saga';

const sagas: any[] = [dashboardSaga, chatSaga, serviceRequestSaga];

export function* rootSaga() {
  yield all(sagas.map((saga) => call(saga)));
}
