import { Customer, MessageTransaction } from '@reshub/model';
import { BrandingConfig, IntegrationConfig, OperationConfig } from '../configuration';
import { TenantUserDto } from '../user';

export class MezoRequestContextDto {
  customer?: Customer;
  resident?: TenantUserDto;
  lastMessage?: MessageTransaction;
  secondToLastMessage?: MessageTransaction;
  webhookReplayMessage?: MessageTransaction;
  webhookReplayPreviousMessage?: MessageTransaction;
  brandingConfig: BrandingConfig;
  operationalConfig: OperationConfig;
  integrationConfig: IntegrationConfig;
}
