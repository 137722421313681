import { ServiceRequestArchiveReason } from '@reshub/model';
import { IsDefined, IsString, ValidateIf } from 'class-validator';

export class ServiceRequestArchiveRequestDto {
  @IsString()
  @IsDefined()
  archiveReason: ServiceRequestArchiveReason;

  @ValidateIf((o) => o.archiveReason === ServiceRequestArchiveReason.OTHER)
  @IsString()
  @IsDefined()
  archiveReasonDescription?: string;
}
