import { ServiceRequestPriority } from '@reshub/model';
import { Type } from 'class-transformer';
import { IsOptional, IsString, ValidateNested } from 'class-validator';
import { KeyValuePairDto } from '../customer';

export class ServiceRequestEditKeyValuePair {
  @IsString()
  readonly field: string;
  @IsString()
  readonly value: string;
}

export class ServiceRequestEditRequestDto {
  @IsOptional()
  @ValidateNested()
  @Type(() => KeyValuePairDto)
  item?: KeyValuePairDto;

  @IsOptional()
  @ValidateNested()
  @Type(() => KeyValuePairDto)
  component?: KeyValuePairDto;

  @IsOptional()
  @ValidateNested()
  @Type(() => KeyValuePairDto)
  symptom?: KeyValuePairDto;

  @IsOptional()
  @ValidateNested()
  @Type(() => KeyValuePairDto)
  location?: KeyValuePairDto;

  @IsOptional()
  @IsString()
  description?: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => KeyValuePairDto)
  diagnosis?: KeyValuePairDto;

  @IsOptional()
  @IsString()
  customerMappingId?: string;

  @IsOptional()
  @IsString()
  priority?: ServiceRequestPriority;
}
