import { memo, ReactNode, useCallback } from 'react';
import { CSSProperties } from 'styled-components';
import tw, { css, styled, theme } from 'twin.macro';
import { PickListItem } from './pick-list';

type HighlightColorProps = {
  highlightColor?: string;
};

type PickListItemProps<T> = {
  item: T;
  selected: boolean;
  style?: CSSProperties | undefined;
  onClick: (item: T) => void;
  children?: ReactNode;
};

const SelectedCircleOuter = styled.div<HighlightColorProps>(({ highlightColor }) => [
  tw`absolute flex items-center justify-center border-2 left-2 top-2 w-[18px] h-[18px] rounded-[80px]`,
  css({
    'border-color': highlightColor,
  }),
]);
const SelectedCircleInner = styled.div<HighlightColorProps>(({ highlightColor }) => [
  tw`w-[10px] h-[10px] rounded-[10px]`,
  css({
    'background-color': highlightColor,
  }),
]);

const PickListItemButton = styled.button<{ selected: boolean } & HighlightColorProps>(
  ({ selected, highlightColor }) => [
    tw`relative h-24 text-sm border grow bg-background-light text-text-light px-2.5 rounded-[10px]`,
    selected &&
      css({
        color: highlightColor,
        'border-color': highlightColor,
      }),
  ]
);

export const PickListCell = <T extends PickListItem>({
  onClick,
  item,
  selected,
  highlightColor = theme`colors.primary`,
  style,
  children,
}: PickListItemProps<T> & HighlightColorProps) => {
  const handleOnClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  return (
    <PickListItemButton
      onClick={handleOnClick}
      selected={selected}
      highlightColor={highlightColor}
      style={style}
      aria-pressed={selected}
    >
      {selected && (
        <SelectedCircleOuter highlightColor={highlightColor}>
          <SelectedCircleInner highlightColor={highlightColor} />
        </SelectedCircleOuter>
      )}
      {children}
    </PickListItemButton>
  );
};

export const MemoizedPickListCell = memo(PickListCell) as typeof PickListCell;
