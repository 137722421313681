import { getChatEvent } from '@mezo/web/utils';
import { ChatEventType, MessageDto } from '@reshub/dtos';
import { Fragment } from 'react';
import tw from 'twin.macro';
import { BOT } from '../types/constants';
import { Gallery } from './gallery';
import { BotMessageNode, mapDisplayNodes, MessageNode } from './message';

interface Props {
  messages: MessageDto[];
  customerIconUrl: string;
}

const Container = tw.div`flex flex-col self-end w-full gap-6`;

export const MessageList: React.FC<Props> = ({ messages, customerIconUrl }) => {
  return (
    <Container>
      {messages
        .filter((m) => m.messages?.length)
        .map((message, index) => {
          const mappedMessage = mapDisplayNodes(message);
          const chatGalleryEvent = getChatEvent(message.chatEvents, ChatEventType.SHOW_GALLERY);
          const GalleryView = chatGalleryEvent ? (
            <Gallery
              key={`${message.id}-${message.timestamp}-${index}-gallery`}
              galleryId={chatGalleryEvent.payload?.['galleryId']}
              isStatic={true}
            />
          ) : (
            <></>
          );
          return message.senderId === BOT ? (
            <Fragment key={`${message.id}-${index}`}>
              <BotMessageNode nodes={mappedMessage} customerIconUrl={customerIconUrl} />
              {GalleryView}
            </Fragment>
          ) : (
            <Fragment key={`${message.id}-${index}`}>
              {mappedMessage.map((node, index) => (
                <MessageNode key={`${node?.type}-${index}-${node?.timestamp}`} author={'sender'} node={node} />
              ))}
            </Fragment>
          );
        })}
    </Container>
  );
};

export default MessageList;
