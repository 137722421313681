/**
 * This method works like Promise.all but with the ability to limit concurrency
 * @param arr TInput[]
 * @param f Promise returning function that operates on a TInput from arr
 * @param n Number of concurrent workers (default: Infinity)
 * @returns TOutput[]
 */
export const throttledConcurrencyPromiseAll = async <TOutput, TInput>(
  arr: Array<TInput>,
  f: (v: TInput, idx: number) => Promise<TOutput>,
  n = Infinity
): Promise<TOutput[]> => {
  const results = Array(arr.length);
  const entries = arr.entries();

  const worker = async () => {
    for (const [key, val] of entries) {
      results[key] = await f(val, key);
    }
  };

  await Promise.all(Array.from({ length: Math.min(arr.length, n) }, worker));

  return results;
};
