import { CustomerId } from '@reshub/model';

export class MyndUnitDto {
  customerId: CustomerId;
  unit_id: string;
  property_id: string;
  address: string;
  number: string;
  city: string;
  state: string;
  zip: string;
  year_built: string;
  type: string;
  square_footage: string;
  bedrooms_number: string;
  full_bathrooms_number: string;
  half_bathrooms_number: string;
  floor: string;
  floors: string;
}
