import { Locale } from '@mezo/shared/utils';
import { CustomerId } from './customer.model';
import { MediaFileInfo } from './media-fileinfo.model';
import { IUpdatable } from './model';

export interface AvailabilityWindows {
  availabilityWindows: {
    startTimeUtc: string;
    endTimeUtc: string;
  }[];
  timezoneId: string;
}

export interface DialogContextLoop {
  loopId: string;
  mediaFileInfos?: MediaFileInfo[];
}
export class DialogContextTranslation {
  id: string;
  dialogId: string;
  locale: Locale;
  [key: string]: string;
}

export interface DialogContext extends IUpdatable {
  id: string;
  customerId: CustomerId;
  entryNotes?: string | undefined;
  permissionToEnter?: boolean | undefined;
  resident: {
    id: string;
    locale: Locale;
    isGuest: boolean;
  };
  messageCount: number;
  isComplete: boolean;
  isAbandoned: boolean;
  completedAt?: number;
  botRequest: any;
  availabilityWindows?: AvailabilityWindows;
  commsThreadId?: string;
  feedback?: {
    rating?: number;
    text?: string;
  };
  loops?: DialogContextLoop[];
}

export const dialogContextCacheKeyFactory = (id: string) => `DIALOG_CONTEXT-${id}`;
