import { ServiceRequest } from './service-request.model';

export class ServiceRequestEdit {
  id: string;
  serviceRequestId: string;
  updatedBy: string;
  createdAt: number;
  to: ServiceRequestUpdatableProps;
  from: ServiceRequest;
}

export type ServiceRequestUpdatableProps = Pick<
  ServiceRequest,
  'details' | 'edited' | 'id' | 'priority' | 'taxonomyMap' | 'tripless' | 'updatedAt' | 'updatedBy'
>;
