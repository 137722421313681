import type { CustomerId, DeliveryIntegrationTypeOptions } from '@reshub/model';
import { Role, TenantUser } from '@reshub/model';
import { IsArray, IsBoolean, IsDate, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';
import { AddressDto, ContactDto, UnitDto } from '../customer';

export class DataDto {
  contact: ContactDto;
}

export class TenantDataDto {
  @IsString()
  customerId: CustomerId;

  @IsBoolean()
  isGuest: boolean;

  @IsString()
  sourceId: string;

  @IsDate()
  moveInDate?: Date;

  @IsObject()
  primaryUnit: UnitDto;

  @IsString({ each: true })
  unitIds: string[];

  @IsString()
  createdBy: string;

  @IsNumber()
  createdAt: number;

  @IsString()
  @IsOptional()
  updatedBy?: string;

  @IsNumber()
  @IsOptional()
  updatedAt?: number;

  @IsString()
  @IsOptional()
  deliveryIntegrationTypeOverride?: DeliveryIntegrationTypeOptions;
}

export class TenantUserDto {
  @IsString()
  id: string;

  @IsString()
  createdBy: string;
  @IsNumber()
  createdAt: number;

  @IsString()
  @IsOptional()
  updatedBy?: string;
  @IsNumber()
  @IsOptional()
  updatedAt?: number;

  @IsObject()
  data: DataDto;

  @IsObject()
  tenantData: TenantDataDto;

  @IsArray()
  @IsOptional()
  roles?: Role[];
}

export function mapFromTenantUser(tenantUser: TenantUser | undefined): TenantUserDto | undefined {
  if (!tenantUser) {
    return undefined;
  }

  const result: TenantUserDto = {
    id: tenantUser.id,
    createdBy: tenantUser.createdBy,
    createdAt: tenantUser.createdAt,
    data: {
      contact: {
        ...tenantUser.data.contact,
        address: tenantUser.data.contact.address ? new AddressDto(tenantUser.data.contact.address) : undefined,
      },
    },
    tenantData: {
      ...tenantUser.tenantData,
      isGuest: tenantUser.tenantData.isGuest ?? false,
      primaryUnit: {
        ...tenantUser.tenantData.primaryUnit,
        address:
          tenantUser.tenantData.isGuest && tenantUser.tenantData.guestAddress
            ? new AddressDto(tenantUser.tenantData.guestAddress)
            : new AddressDto(tenantUser.tenantData.primaryUnit.address),
      },
    },
    roles: tenantUser.roles,
  };

  return result;
}
