import { Contact } from './contact.model';
import { Unit } from './unit.model';

export class Resident {
  id: string;
  sourceId: string;
  contact: Contact;
  leaseFlowId: string;
  moveInDate: Date;
  primaryUnit: Unit;
  unitIds: string[];
  updatedAt: number;
  createdAt: number;
}

export const residentCacheKeyFactory = (residentUid: string) => `RESIDENT-${residentUid}`;
