import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import Button from './button';

export interface ThumbnailProps {
  handleRemoveThumbnail: (name: string) => void;
  type: string;
  url: string;
  name: string;
}

const StyledThumbnail = styled.div<{ imgSrc: string }>(({ imgSrc }) => [
  `background-image: url(${imgSrc});`,
  tw`flex-shrink-0 w-16 h-16 bg-center bg-no-repeat bg-cover rounded-lg`,
]);

const StyledVideo = tw.video`flex-shrink-0 w-16 h-16 rounded-lg bg-text`;

const FlexContainer = tw.div`flex items-center justify-between w-full gap-2`;
const FlexContainerRight = tw(FlexContainer)`justify-start`;
const IconContainer = tw.div``;
const CloseIcon = tw(FontAwesomeIcon)`text-black`;
const Name = tw.span`text-sm`;

export const ThumbnailRow = ({ name, url, handleRemoveThumbnail, type }: ThumbnailProps) => {
  const { t } = useTranslation();
  const renderThumbnail = () => {
    if (type.split('/')[0] === 'video') {
      return <StyledVideo src={url} />;
    } else {
      return <StyledThumbnail imgSrc={url} />;
    }
  };

  return (
    <FlexContainer>
      <FlexContainerRight>
        {renderThumbnail()}
        <Name>{name}</Name>
      </FlexContainerRight>
      <IconContainer>
        <Button onClick={() => handleRemoveThumbnail(name)} aria-label={t('media.deleteMedia', { filename: name })}>
          <CloseIcon icon={faTimesCircle} size="lg" />
        </Button>
      </IconContainer>
    </FlexContainer>
  );
};
