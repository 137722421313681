import { isProduction } from '@mezo/web/utils';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './sagas';
import { chatSlice, customerSlice, dashboardSlice, serviceRequestSlice, sessionSlice } from './slices';

const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = createReduxEnhancer({
  // Optionally pass options listed below
});
export const store = configureStore({
  reducer: {
    customer: customerSlice.reducer,
    session: sessionSlice.reducer,
    dashboard: dashboardSlice.reducer,
    chat: chatSlice.reducer,
    serviceRequest: serviceRequestSlice.reducer,
  },
  middleware: [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: { ignoredActions: ['chat/sendMessage'] } }),
    sagaMiddleware,
  ],
  enhancers: isProduction() ? [sentryReduxEnhancer] : [],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);
