import { HttpStatus } from '@nestjs/common';
import { MezoException } from './mezo.exception';

export class ServiceRequestAlreadyDeliveredException extends MezoException {
  override message: string;

  constructor(message = 'Service Request Already Delivered') {
    super(message);
    this.status = HttpStatus.BAD_REQUEST;
    this.message = message;
  }
}
