import { IsArray, IsString } from 'class-validator';

export class DialogMediaUpdateDto {
  @IsString()
  loopId: string;

  @IsArray()
  mediaFileInfos: {
    url: string;
    filename: string;
    contentType: string;
  }[];
}
