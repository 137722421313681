import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

export interface ApiConfig {
  timeout?: number;
  headers?: ApiHeaders;
  validateStatus?: (status: number) => boolean;
  baseURL?: string;
}

export interface Interceptors {
  request: AxiosInterceptorConfig[];
  response: AxiosInterceptorConfig[];
}

export interface ApiHeaders extends AxiosRequestHeaders {
  'Content-Type': string;
  'Access-Control-Allow-Origin': string;
  Authorization: string;
  'x-mezo-medium': string;
  'Accept-Language': string;
}

export interface AxiosInterceptorConfig {
  onFulfilled?: (value: any) => any;
  onRejected?: (error: any) => any;
}

export class Api {
  baseConfig: ApiConfig;
  baseHeaders: ApiHeaders;
  baseUrl: string;
  utility: AxiosInstance;
  requestIntercepterIds = [] as number[];
  responseIntercepterIds = [] as number[];

  constructor(config?: ApiConfig, interceptors?: Interceptors) {
    this.baseHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      'x-mezo-medium': 'web',
      'Accept-Language': window.navigator.language,
    } as ApiHeaders;
    this.setBaseUrl(config?.baseURL ?? 'http://localhost:8080/v1');

    const defaultConfig: ApiConfig = {
      baseURL: this.baseUrl,
      timeout: 60000,
      headers: this.baseHeaders,
      validateStatus: (status: number) => {
        return status >= 200 && status < 300;
      },
    };

    this.baseConfig = {
      ...defaultConfig,
      ...config,
    };

    this.utility = axios.create(this.baseConfig);

    if (interceptors?.request) {
      this.requestIntercepterIds = interceptors?.request.map((config: AxiosInterceptorConfig) =>
        this.utility.interceptors.request.use(config.onFulfilled, config.onRejected)
      );
    }

    if (interceptors?.response) {
      this.responseIntercepterIds = interceptors?.response.map((config: AxiosInterceptorConfig) =>
        this.utility.interceptors.response.use(config.onFulfilled, config.onRejected)
      );
    }
  }

  clearResponseInterceptors() {
    this.responseIntercepterIds.forEach((responseIntercepterId) => {
      this.utility.interceptors.response.eject(responseIntercepterId);
    });
  }

  setAuthToken(authToken: string) {
    this.utility.defaults.headers.common['Authorization'] = authToken;
  }

  clearAuthToken() {
    delete this.utility.defaults.headers.common['Authorization'];
  }

  hasAuthToken() {
    return this.utility.defaults.headers.common['Authorization'] != null;
  }

  setUserSession(userSessionId: string) {
    this.utility.defaults.headers.common['X-mezo-userSession'] = userSessionId;
  }

  clearUserSession() {
    delete this.utility.defaults.headers.common['X-mezo-userSession'];
  }

  hasUserSession() {
    return this.utility.defaults.headers.common['X-mezo-userSession'] != null;
  }

  setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }
  getBaseUrl() {
    return this.baseUrl;
  }
}
