import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import tw from 'twin.macro';
import { ChatStackHeader } from '../chat/chat-stack-header';
import { NavigationContextProvider } from './navigation-stack-context';
import { NavigationStackPageProps } from './navigation-stack-page';

type NavigationStackProps = {
  showRootBackButton?: boolean;
  onRootBackClicked?: VoidFunction;
  hasSubmittedRequestsStyling?: boolean;
  children:
    | ReactElement<NavigationStackPageProps>
    | (ReactElement<NavigationStackPageProps> | undefined)[]
    | undefined[];
};

const NavigationStackWrapper = tw.div`flex flex-col flex-1 h-full overflow-hidden`;
const NavigationPages = tw.div`relative flex-1`;

export const NavigationStack: React.FC<NavigationStackProps> = ({
  showRootBackButton,
  onRootBackClicked,
  children,
  hasSubmittedRequestsStyling,
}) => {
  const pages = useMemo(
    () => (Array.isArray(children) ? children.filter((child) => !!child) : [children].filter((child) => !!child)),
    [children]
  );

  const [pageStack, setPageStack] = useState<number[]>([0]);
  const currentPageIndex = useMemo(() => pageStack[pageStack.length - 1], [pageStack]);

  const handlePageChange = useCallback(
    (pageName: string) => {
      const pageIndex = pages.findIndex((page) => page?.props.title === pageName);
      if (pageIndex > -1) {
        setPageStack((oldStack) => [...oldStack, pageIndex]);
      } else {
        console.error(`No page exists for name: ${pageName}`);
      }
    },
    [pages]
  );

  const handleNavigateToNextPage = useCallback(() => {
    const nextIndex = pages.findIndex((_page, index) => index === currentPageIndex + 1);
    if (nextIndex > -1) {
      setPageStack((oldStack) => [...oldStack, nextIndex]);
    } else {
      console.error(`No page exists`);
    }
  }, [currentPageIndex, pages]);

  const handleBackClicked = useCallback(() => {
    if (currentPageIndex === 0 || currentPageIndex === undefined) {
      onRootBackClicked?.();
    } else {
      setPageStack((oldStack) => {
        return oldStack.slice(0, -1);
      });
    }
  }, [currentPageIndex, onRootBackClicked]);

  const resetNavigationStack = useCallback(() => {
    setPageStack([0]);
  }, []);

  return (
    <NavigationStackWrapper>
      <NavigationContextProvider
        onPageChange={handlePageChange}
        onNavigateToNextPage={handleNavigateToNextPage}
        resetNavigationStack={resetNavigationStack}
      >
        <ChatStackHeader
          currentPageName={pages[currentPageIndex]?.props.title}
          showBackButton={showRootBackButton || currentPageIndex > 0}
          onBackClicked={handleBackClicked}
          hasSubmittedRequestsStyling={hasSubmittedRequestsStyling}
        />
        <NavigationPages>
          {pages.map((page, index) => {
            if (page && currentPageIndex === index) {
              return React.cloneElement(page, { isActive: true, isVisible: true });
            } else if (page && pageStack.includes(index)) {
              return React.cloneElement(page, { isVisible: true });
            } else {
              return page;
            }
          })}
        </NavigationPages>
      </NavigationContextProvider>
    </NavigationStackWrapper>
  );
};
