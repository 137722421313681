import { BotContext } from './bot-context.model';
import { MediaFileInfo } from './media-fileinfo.model';

export class MessageTransactionTranslation {
  messageId: string;
  locale: string;
  'incoming.messages'?: string[];
  'outgoing.messages'?: string[];
}

export class MessageTransaction {
  id: string;
  residentId: string;
  dialogId: string;
  loopId: string;
  incoming: Message;
  outgoing: Message;
  medium: MessageMedium;
  phoneNumber?: string;
  createdAt: number;
  commsThreadId: string;
  // can we delete this?
  residentIdOld?: string; // if session was created using a generated resident that residentid is saved here
  config?: {
    integration?: Record<string, unknown>;
    branding?: Record<string, unknown>;
    operation?: Record<string, unknown>;
  };
}

export class Message {
  createdAt: number;
  messages?: string[];
  /**
   * @deprecated The property should not be used
   */
  message?: string;
  botContext: BotContext;
  mediaFileInfos?: MediaFileInfo[];
  endSession?: boolean;
  startSession?: boolean;
  isSystemPush?: boolean;
  isDone?: boolean;
  chatEvents?: {
    type: string;
    payload?: Record<string, any>;
  }[];
}

export enum MessageMedium {
  WEB = 'WEB',
  SMS = 'SMS',
  IVR = 'IVR',
  API = 'API',
  SLACK = 'SLACK',
}

export const recentResidentMessageTransactionsCacheKeyFactory = (residentId: string, dialogId?: string) => {
  let key = `MESSAGE_TRANSACTION-recentResidentMessages-${residentId}`;
  if (dialogId) {
    key += `.${dialogId}`;
  }
  return key;
};
