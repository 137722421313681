import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload } from '@mezo/web/hooks';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import { Gallery } from '../gallery';
import FileUpload from './file-upload';

const Container = tw.div`flex flex-col flex-grow-2`;
const PaddingContainer = tw.div`p-4`;
const GalleryContainer = styled.div<{ isVisible: boolean }>(({ isVisible }) => [
  tw`relative overflow-hidden transition-all duration-500 max-h-0`,
  isVisible && tw`max-h-screen`,
]);
const GalleryInterior = tw.div`p-4 border-y bg-background-secondary border-background `;

const IconButton = tw.button`absolute top-0 right-0 z-10 w-12 h-12 p-0 border-0 cursor-pointer bg-none`;
const StyledIcon = tw(FontAwesomeIcon)`text-black fill-current `;

const Link = tw.a`underline cursor-pointer text-primary`;

const SkipContainer = tw(PaddingContainer)`flex flex-col items-center gap-2 text-center`;
const Disclaimer = tw.div`flex justify-center w-full text-xs text-center align-middle text-text-medium-dark`;

const Instructions = tw.p``;

type ChatMediaProps = {
  handleUploadMedia: (files: File[]) => void;
  handleDeleteMedia: (name: string) => void;
  handleNavigateNext: VoidFunction;
  handleMediaSkipped: VoidFunction;
  uploads: Upload[];
  errorMessages: string[];
};

export const ChatMedia = ({
  handleUploadMedia,
  uploads,
  handleDeleteMedia,
  errorMessages,
  handleNavigateNext,
  handleMediaSkipped,
}: ChatMediaProps) => {
  const { t } = useTranslation();
  const [displayGallery, setDisplayGallery] = useState<boolean>(true);
  const [isSkipDisclaimerVisible, setIsSkipDisclaimerVisible] = useState<boolean>(false);

  const handleSkip = useCallback(() => {
    if (isSkipDisclaimerVisible) {
      handleMediaSkipped();
      handleNavigateNext();
    } else {
      setIsSkipDisclaimerVisible(true);
    }
  }, [isSkipDisclaimerVisible, handleNavigateNext, handleMediaSkipped]);

  const toggleExampleDisplay = useCallback(() => {
    setDisplayGallery(!displayGallery);
  }, [displayGallery]);

  return (
    <Container>
      <PaddingContainer>
        <Instructions>
          {t('media.instructions')}
          {!displayGallery && (
            <>
              {' '}
              <Link onClick={toggleExampleDisplay}>{t('media.showExamples')}</Link>
            </>
          )}
        </Instructions>
      </PaddingContainer>
      <GalleryContainer isVisible={displayGallery}>
        <IconButton onClick={toggleExampleDisplay} aria-label={t('media.hideExamples')}>
          <StyledIcon icon={faXmark} size="xl" />
        </IconButton>
        <GalleryInterior>
          <Gallery galleryId={'exampleAssets'} isStatic={false} shouldDisplayTitle={true} />
        </GalleryInterior>
      </GalleryContainer>
      <PaddingContainer>
        <FileUpload
          files={uploads}
          onMediaSelection={handleUploadMedia}
          onMediaDeletion={handleDeleteMedia}
          errors={errorMessages}
        />
      </PaddingContainer>
      {uploads.length === 0 && (
        <SkipContainer>
          <Link onClick={handleSkip}>{t('media.skip')}</Link>
          {isSkipDisclaimerVisible && <Disclaimer>{t('media.youSure')}</Disclaimer>}
        </SkipContainer>
      )}
    </Container>
  );
};

export default ChatMedia;
