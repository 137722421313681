import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload } from '@mezo/web/hooks';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import tw, { css, styled, theme } from 'twin.macro';

import { AndroidUpload } from '../chat/file-input';
import ProgressBar from '../progress-bar';
import { ThumbnailRow } from '../thumbnail-row';

const UploadButton = styled.button<{ hasSelectedFiles: boolean }>(({ hasSelectedFiles }) => [
  tw`flex justify-center w-full gap-2 p-4 align-middle border rounded-lg`,
  !hasSelectedFiles ? tw`text-white border-white bg-primary ` : tw`bg-white text-primary border-primary`,
]);

const FileIcon = tw(FontAwesomeIcon)`w-4 h-4 mx-2 my-auto`;
const ButtonText = tw.div``;
const Input = tw.input`hidden`;
const Disclaimer = tw.div`flex justify-center w-full text-xs text-center align-middle text-text-medium-dark`;

const Container = tw.div`flex flex-col gap-4`;
const ThumbnailContainer = tw(Container)`justify-between`;
const UploadContainer = tw(Container)`gap-2`;
const UploadButtonContainer = tw.div`relative`;
const AndroidUploadContainer = styled('div')(() => [
  tw`absolute right-0 w-1/2 shadow-lg top-1/2`,
  css`
    transform: translateY(-50%);
  `,
]);

const ErrorText = tw.p`text-sm text-error`;

type FileUploadProps = {
  files: Upload[];
  onMediaSelection: (files: File[]) => void;
  onMediaDeletion: (name: string) => void;
  errors: string[];
};

export const FileUpload = ({ onMediaSelection, files, onMediaDeletion, errors }: FileUploadProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const [isUploadMenuVisible, setUploadMenuVisible] = useState<boolean>(false);

  const handleOnClick = useCallback(() => {
    if (isAndroid) {
      setUploadMenuVisible(!isUploadMenuVisible);
    } else {
      ref.current?.click();
    }
  }, [isUploadMenuVisible]);

  const handleOnFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        onMediaSelection(Array.from(files));
      }
    },
    [onMediaSelection]
  );

  const handleAndroidFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        onMediaSelection(Array.from(files));
      }
      setUploadMenuVisible(false);
    },
    [onMediaSelection]
  );

  const hasSelectedFiles = files.length > 0;

  return (
    <Container>
      <ThumbnailContainer>
        {files.map(({ filename, url, contentType, uploadProgress }) => {
          return uploadProgress >= 100 ? (
            <ThumbnailRow
              key={filename}
              url={url}
              name={filename}
              handleRemoveThumbnail={onMediaDeletion}
              type={contentType}
            />
          ) : (
            <ProgressBar key={filename} percentage={uploadProgress} color={theme`colors.primary`} />
          );
        })}
      </ThumbnailContainer>
      {errors.length > 0 && (
        <Container>
          {errors.map((error) => (
            <ErrorText>{error}</ErrorText>
          ))}
        </Container>
      )}
      <UploadContainer>
        <Input
          ref={ref}
          type="file"
          name="files"
          aria-label={!hasSelectedFiles ? t('media.buttonLabel') : t('media.moreButtonLabel')}
          id="files"
          accept=".jpg, .jpeg, .png, .mp4, .mov"
          onChange={handleOnFileChange}
          multiple={true}
        />
        <UploadButtonContainer>
          <UploadButton hasSelectedFiles={hasSelectedFiles} onClick={handleOnClick}>
            <FileIcon icon={faUpload} size={'1x'} />
            <ButtonText>{!hasSelectedFiles ? t('media.buttonLabel') : t('media.moreButtonLabel')}</ButtonText>
          </UploadButton>
          {isUploadMenuVisible && isAndroid && (
            <AndroidUploadContainer>
              <AndroidUpload handleOnChange={handleAndroidFileChange} />
            </AndroidUploadContainer>
          )}
        </UploadButtonContainer>
        <Disclaimer>{t('media.disclaimer')}</Disclaimer>
      </UploadContainer>
    </Container>
  );
};

export default FileUpload;
