import { IsBoolean, IsString } from 'class-validator';

export class ServiceRequestsUsersDto {
  @IsString()
  readonly id: string;
  @IsString()
  readonly userId: string;
  @IsBoolean()
  readonly isRead: boolean;
}
