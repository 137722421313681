import { ReactNode, useCallback } from 'react';
import tw, { styled } from 'twin.macro';
import Loading from '../loading';
import { MemoizedPickListCell } from './pick-list-cell';

export type PickListItem = {
  id: string | number;
  label: string;
};

export type PickListProps<T> = {
  items: T[];
  selectedItem?: T | undefined;
  highlightColor?: string;
  loading?: boolean;
  renderItem?: (item: T) => ReactNode;
  onItemSelected?: (item: T | undefined) => void;
};

const PickListContainer = styled.div<{ fullWidthColumns: boolean }>(({ fullWidthColumns }) => [
  tw`grid w-full grid-flow-row grid-cols-1 gap-4`,
  !fullWidthColumns && tw`grid-cols-2`,
]);

export const PickList = <T extends PickListItem>({
  items,
  selectedItem,
  loading,
  onItemSelected,
  highlightColor,
  renderItem,
}: PickListProps<T>) => {
  const handleItemPicked = useCallback(
    (item: T) => {
      const newSelectedItem = item.id === selectedItem?.id ? undefined : item;
      onItemSelected?.(newSelectedItem);
    },
    [onItemSelected, selectedItem?.id]
  );

  const numColumns = items.length <= 4 ? 1 : 2;
  const style = numColumns === 1 ? tw`py-7` : tw`py-[30px]`;
  const defaultRenderItem = useCallback(
    (item: T) => (
      <MemoizedPickListCell
        key={item.id}
        item={item}
        onClick={handleItemPicked}
        selected={item.id === selectedItem?.id}
        highlightColor={highlightColor}
        style={style}
      >
        {item.label}
      </MemoizedPickListCell>
    ),
    [handleItemPicked, selectedItem?.id, highlightColor, style]
  );

  const renderMethod = renderItem || defaultRenderItem;
  return loading ? (
    <Loading />
  ) : (
    <PickListContainer fullWidthColumns={numColumns === 1}>{items.map((item) => renderMethod(item))}</PickListContainer>
  );
};
