import { IUpdatable } from './model';

export class Translation implements IUpdatable {
  id: string;
  reason?: string;
  sourceText: string;

  languages: {
    [key: string]: string;
  };

  createdBy?: string;
  createdAt?: number;
  updatedBy?: string;
  updatedAt?: number;
}
