import { Api, isProduction } from '@mezo/web/utils';
import { captureException } from '@sentry/react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { noop } from 'lodash';
import { toast } from 'react-toastify';

const WEB_API_VERSION = 'v1';

// These must be explicitly mentioned in the code, or they will not be picked up and replaced by the dotenv plugin
// eslint-disable-next-line @typescript-eslint/no-var-requires
const envJson: AppConfig = {
  WEB_FIREBASE_API_KEY: process.env['NX_WEB_FIREBASE_API_KEY'] as string,
  WEB_FIREBASE_AUTH_DOMAIN: process.env['NX_WEB_FIREBASE_AUTH_DOMAIN'] as string,
  WEB_FIREBASE_PROJECT_ID: process.env['NX_WEB_FIREBASE_PROJECT_ID'] as string,
  WEB_FIREBASE_STORAGE_BUCKET: process.env['NX_WEB_FIREBASE_STORAGE_BUCKET'] as string,
  WEB_FIREBASE_MESSAGING_SENDER_ID: process.env['NX_WEB_FIREBASE_MESSAGING_SENDER_ID'] as string,
  WEB_FIREBASE_APP_ID: process.env['NX_WEB_FIREBASE_APP_ID'] as string,
  WEB_FIREBASE_MEASUREMENT_ID: process.env['NX_WEB_FIREBASE_MEASUREMENT_ID'] as string,
  WEB_API_BASE_URL: process.env['NX_WEB_API_BASE_URL'] as string,
  WEB_BASE_URL: process.env['NX_WEB_BASE_URL'] as string,
};

export interface AppConfig {
  WEB_FIREBASE_API_KEY: string;
  WEB_FIREBASE_AUTH_DOMAIN: string;
  WEB_FIREBASE_PROJECT_ID: string;
  WEB_FIREBASE_STORAGE_BUCKET: string;
  WEB_FIREBASE_MESSAGING_SENDER_ID: string;
  WEB_FIREBASE_APP_ID: string;
  WEB_FIREBASE_MEASUREMENT_ID: string;
  WEB_API_BASE_URL: string;
  WEB_BASE_URL: string;
}

const nonChatInterceptors = {
  request: [
    {
      onFulfilled: (_config: AxiosRequestConfig) => {
        return _config;
      },
      onRejected: (err: any) => {
        noop();
      },
    },
  ],
  response: [
    {
      onFulFilled: (response: AxiosResponse) => {
        return response;
      },
      onRejected: (err: any) => {
        toast(err.message);
        if (isProduction()) {
          captureException(err);
        }
      },
    },
  ],
};

const chatInterceptors = {
  request: [
    {
      onFulfilled: (_config: AxiosRequestConfig) => {
        return _config;
      },
      onRejected: (err: any) => {
        noop();
      },
    },
  ],
  response: [
    {
      onFulFilled: (response: AxiosResponse) => {
        return response;
      },
      onRejected: (err: any) => {
        if (isProduction()) {
          captureException(err);
        }
      },
    },
  ],
};

export class ApiClient {
  static APP_CONFIG: AppConfig = envJson;

  static NON_CHAT_API: Api = new Api(
    {
      baseURL: `${ApiClient.APP_CONFIG.WEB_API_BASE_URL}/${WEB_API_VERSION}`,
    },
    nonChatInterceptors
  );

  static CHAT_API: Api = new Api(
    {
      baseURL: `${ApiClient.APP_CONFIG.WEB_API_BASE_URL}/${WEB_API_VERSION}`,
    },
    chatInterceptors
  );
}
