import { BrandingConfiguration } from '@mezo/web/hooks';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { MEZO_LOGO_URL } from '../../types/constants';
import { CustomerLogo } from '../customer-logo';

const FlexContainer = tw.div`relative flex items-center justify-start gap-4 px-4 py-2 shadow-lg`;
const StyledLogo = tw(CustomerLogo)`w-10 h-10 border-2`;
const HeaderText = tw.h2`m-0 text-sm tracking-wide`;

interface Props {
  config: Pick<BrandingConfiguration, 'logo' | 'name' | 'hideChatHeader'>;
}

export const ChatHeader: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation();
  const { logo, name, hideChatHeader = false } = config;
  return !hideChatHeader ? (
    <FlexContainer>
      <StyledLogo src={logo ?? MEZO_LOGO_URL} alt={`${name || 'Mezo'} Logo`} />
      <HeaderText>{t('chat.residentHeader', { customerName: name || 'Mezo' })}</HeaderText>
    </FlexContainer>
  ) : null;
};

export default ChatHeader;
