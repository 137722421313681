import { TaxonomyMap } from '@reshub/model';

export const formatTaxonomyForServiceRequestTitle = (taxonomyMap: TaxonomyMap | undefined) => {
  if (!taxonomyMap) {
    return '';
  }
  return `${taxonomyMap?.mezoItem?.label || 'Unknown'} / ${
    (taxonomyMap?.mezoComponent?.label || '') + (taxonomyMap?.mezoComponent?.label ? ' /' : '')
  } ${taxonomyMap?.mezoSymptom?.label || 'Other'}`;
};
