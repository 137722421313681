import { CustomerSourceSystem } from '@reshub/model';

export class ServiceRequestResidentViewDto {
  createdAt: number;
  item: string;
  sourceId: string;
  sourceStatus: string;
  sourceSystem: CustomerSourceSystem;
  sourceLastUpdated: number;
}

export class ServiceRequestResidentView extends ServiceRequestResidentViewDto {
  showWorkOrderNumber: boolean;
}
