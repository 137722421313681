//This dto defines the data return when fetching a service request
import { Locale } from '@mezo/shared/utils';
import {
  CategoryKVP,
  ComponentKVP,
  CustomerId,
  DeliveryIntegrationTypeOptions,
  Diagnosis,
  ItemKVP,
  LocationKVP,
  MediaFileInfo,
  MessageMedium,
  ServiceRequestArchiveReason,
  ServiceRequestPriority,
  ServiceRequestStatus,
  Skillset,
  SymptomKVP,
} from '@reshub/model';
import { AddressDto } from '../customer';

export class AvailabilityWindow {
  startTimeUtc: string;
  endTimeUtc: string;
}
export class ServiceRequestDetailsResponseDto {
  itemdescription: string;
  issuedescription: string;
  locationdescription: string;
  problemstatement: string;
  description: string;
  descriptionHtml: string;
  diagnosis?: Diagnosis;
  availabilityWindows: AvailabilityWindow[];
  skillsets?: Skillset[];
}

export class ServiceRequestUnitResponseDto {
  id: string;
  propertyId: string;
  propertyName: string;
  address: AddressDto;
  customerId: CustomerId;
  customerIconUrl: string;
  timezone?: {
    dstOffset: number;
    rawOffset: number;
    status: string;
    timeZoneId: string;
    timeZoneName: string;
  };
}

export class ServiceRequestReportedByDto {
  id: string;
  sourceId: string;
  isGuest: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string | undefined;
  locale: Locale;
  guestAddress?: AddressDto;
}

export class ServiceRequestResponseDto {
  archiveReason?: ServiceRequestArchiveReason;
  archiveReasonDescription?: string;
  askPermissionToEnter: boolean;
  assetLookupId?: string;
  createdAt: number;
  deliveredAt: number;
  deliveredBy: string;
  deliveryAttemptCount?: number;
  deliveryIntegrationType: DeliveryIntegrationTypeOptions;
  details: ServiceRequestDetailsResponseDto;
  dialogId: string;
  edited: boolean;
  entryNotes?: string;
  feedback?: {
    rating?: number;
    text?: string;
  };
  id: string;
  isRead: boolean;
  loopId: string;
  medium: MessageMedium;
  mediaFileInfos: MediaFileInfo[];
  originalTaxonomyMap: TaxonomyMapDto;
  permissionToEnter?: boolean;
  reportedBy: ServiceRequestReportedByDto;
  externalLink: string;
  isNonMaintenance: boolean;
  priority: ServiceRequestPriority;
  skillsets?: Skillset[];
  source: {
    id: string;
    status?: string;
    url?: string;
  };
  status: ServiceRequestStatus;
  taxonomyMap: TaxonomyMapDto;
  tripless: boolean;
  unit: ServiceRequestUnitResponseDto;
  updatedAt: number;
  updatedBy: string;
}

export class TaxonomyMapDto {
  mezoItem?: ItemKVP;
  mezoComponent?: ComponentKVP;
  mezoSymptom?: SymptomKVP;
  mezoLocation?: LocationKVP;
  mezoCategory?: CategoryKVP;

  customerItem?: ItemKVP; //For customers like Mynd this maps to their subCategory
  customerComponent?: ComponentKVP; //For customers like Mynd this is N/A
  customerSymptom?: SymptomKVP; //For customers like Mynd this maps to their Issue
  customerLocation?: LocationKVP;
  customerCategory?: CategoryKVP;
}
