import { IsDate, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';
import { Contact } from './contact.model';
import type { CustomerId } from './customer.model';
import { Unit } from './unit.model';

export class UserData {
  contact: Contact;
}

export class TenantDataModel {
  @IsString()
  customerId: CustomerId;

  @IsString()
  sourceId: string;

  @IsDate()
  moveInDate: Date;

  @IsObject()
  primaryUnit: Unit;

  @IsString({ each: true })
  unitIds: string[];

  @IsString()
  createdBy: string;

  @IsNumber()
  createdAt: number;

  @IsString()
  @IsOptional()
  updatedBy?: string;

  @IsNumber()
  @IsOptional()
  updatedAt?: number;
}

export class TenantUserModel {
  @IsString()
  id: string;

  @IsString()
  createdBy: string;
  @IsNumber()
  createdAt: number;

  @IsString()
  @IsOptional()
  updatedBy?: string;
  @IsNumber()
  @IsOptional()
  updatedAt?: number;

  @IsObject()
  data: UserData;

  @IsObject()
  tenantData: TenantDataModel;
}
