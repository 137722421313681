import { IsArray, IsNotEmpty } from 'class-validator';

export class AvailabilityWindowsDto {
  @IsArray()
  availabilityWindows: {
    startTimeUtc: string;
    endTimeUtc: string;
  }[];

  @IsNotEmpty()
  timezoneId: string;
}
