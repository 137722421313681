import { useMinScreen } from '@mezo/web/hooks';
import { css } from 'twin.macro';

export const DebugBreakpoint = () => {
  const { min } = useMinScreen();

  return (
    <span
      css={css`
        position: absolute;
        bottom: 4px;
        left: 4px;
        z-index: 10000;
        font-family: monospace;
        color: red;
        font-size: 16px;
      `}
    >
      {!min`sm` && <div>&lt; small</div>}
      {min`sm` && !min`md` && <div>small - md</div>}
      {min`md` && !min`lg` && <div>md - lg</div>}
      {min`lg` && !min`xl` && <div>lg - xl</div>}
      {min`xl` && !min`2xl` && <div>xl - 2xl</div>}
      {min`2xl` && <div>&gt; 2xl</div>}
    </span>
  );
};

export default DebugBreakpoint;
