import { CustomerId } from '@reshub/model';

export class DataIntegrationDto {
  customerId?: CustomerId;
  filename: string;
  dataType: string;
  numberOfRecords: string;
  numberOfSkippedRecords: string;
  secondsToCreateMessages: string;
}
