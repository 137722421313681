import { ApiClient } from '@mezo/web/api-client';
import {
  RecentResidentProfileDto,
  ResidentSearchResponseDto,
  ResidentSearchResultResponseDto,
  SearchTextRequestDto,
} from '@reshub/dtos';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useGetRecentResidents = (uid: string) =>
  useQuery(
    ['recentResidents', uid],
    async () => {
      const { data } = await ApiClient.NON_CHAT_API.utility.get<ResidentSearchResultResponseDto[]>(
        `/users/${uid}/recent-resident-profiles`
      );
      return data;
    },
    {
      enabled: !!uid,
    }
  );

export const useSearchResidents = (searchText: string) =>
  useQuery(
    ['searchResidents', searchText],
    async () => {
      const payload: SearchTextRequestDto = {
        searchText: searchText,
        filter: '',
        resultsPerPage: 8,
        pageNumber: 0,
      };
      const { data } = await ApiClient.NON_CHAT_API.utility.post<ResidentSearchResponseDto>(
        `/residents/search`,
        payload
      );
      return data.docs;
    },
    {
      enabled: !!searchText.length,
    }
  );

export const useAddRecentResident = (uid: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ resident }: { resident: RecentResidentProfileDto }) => {
      const { data } = await ApiClient.NON_CHAT_API.utility.post(`/users/${uid}/recent-resident-profiles`, resident);
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueriesData(['recentResidents', uid], data);
    },
  });
};
