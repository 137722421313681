import { GeocodeResponseData, GeocodeResult } from '@googlemaps/google-maps-services-js';
import { Location } from './address.model';

export interface Geocode extends GeocodeResponseData {
  id: string;

  address?: string;
  placeId?: string;

  createdBy: string;
  createdAt: number;
  updatedAt?: number;
}

export function geocodeToLocation(geocode: GeocodeResult | undefined): Location | undefined {
  if (!geocode?.address_components || !geocode?.geometry) {
    return;
  }
  return {
    latitude: geocode.geometry.location.lat,
    longitude: geocode.geometry.location.lng,
    google: {
      placeId: geocode.place_id,
      types: geocode.types,
      geometry: geocode.geometry,
      addressComponents: geocode.address_components.map((ac) => ({
        shortName: ac.short_name,
        longName: ac.long_name,
        types: ac.types,
      })),
    },
  };
}
