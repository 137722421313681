import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CalendarDate, createCalendar, isSameDay } from '@internationalized/date';
import { useCalendar, useCalendarGrid } from '@react-aria/calendar';
import { useLocale } from '@react-aria/i18n';
import { useCalendarState } from '@react-stately/calendar';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import tw, { styled } from 'twin.macro';

import { getLocale } from '@mezo/shared/formatters';
import CalendarCell from './calendar-cell';
import { PaginationButton } from './pagination-button';

const StyledCalendarContainer = tw.div`flex flex-col items-stretch w-full max-w-md gap-4`;
const Title = styled.h3``;
const CalendarHeader = tw.div`flex items-center justify-between `;
const ButtonContainer = tw.div`flex gap-4`;
const FlexTable = tw.div`flex flex-col w-full gap-4`;
const WeekContainer = tw.div`flex justify-between`;
const HeaderCell = tw.div`w-8 text-xs text-center`;

type WeekViewProps = {
  timezoneId: string;
  onDaySelection: (date: CalendarDate) => void;
  numDisplayWeeks: number;
  shouldDisableDate: (date: CalendarDate) => boolean;
  daySelections: string[];
  startDay: CalendarDate;
  today: CalendarDate;
  currentLocale?: string;
};

export const Calendar = ({
  timezoneId,
  onDaySelection,
  numDisplayWeeks = 2,
  shouldDisableDate,
  daySelections,
  startDay,
  today,
  currentLocale,
}: WeekViewProps) => {
  const { locale } = useLocale();
  const userLocale = currentLocale || locale;
  const defaultValue = startDay;
  const state = useCalendarState({
    defaultValue,
    visibleDuration: { weeks: numDisplayWeeks },
    locale: userLocale,
    createCalendar,
  });

  const { calendarProps, prevButtonProps, nextButtonProps } = useCalendar({ defaultValue }, state);
  const { gridProps, weekDays, headerProps } = useCalendarGrid({}, state);

  const { selectDate } = state;

  // select the default day when the component is mounted. runs once on mount
  useEffect(() => {
    onDaySelection(defaultValue);
  }, []);

  const handleDateSelection = useCallback(
    (date: CalendarDate) => {
      onDaySelection(date);
      selectDate(date);
    },
    [onDaySelection, selectDate]
  );

  const disableCell = useCallback(
    (date: CalendarDate) => {
      return shouldDisableDate(date);
    },
    [shouldDisableDate]
  );

  const Weeks = useMemo(() => {
    const rows = [];
    for (let i = 0; i < numDisplayWeeks; i++) {
      rows.push(
        <WeekContainer key={`week-${i}`}>
          {state
            .getDatesInWeek(i)
            .map((date, j) =>
              date ? (
                <CalendarCell
                  isDisabled={disableCell(date)}
                  key={`week-${i}-day-${j}`}
                  state={state}
                  date={date}
                  isCurrentDay={isSameDay(date, today)}
                  onSelect={handleDateSelection}
                  timezoneId={timezoneId}
                  isSelected={state.isSelected(date)}
                  hasEvent={daySelections.includes(date.toDate(timezoneId).toUTCString())}
                />
              ) : (
                <div key={`week-${i}-day-${j}`} />
              )
            )}
        </WeekContainer>
      );
    }
    return rows;
  }, [numDisplayWeeks, state, disableCell, timezoneId, today, handleDateSelection, daySelections]);

  const generateMonthsText = (locale: string) => {
    const { end, start } = state.visibleRange;
    if (end.month === start.month) {
      return format(start.toDate(state.timeZone), 'MMM yyyy', { locale: getLocale(locale) });
    } else {
      const firstMonth = format(start.toDate(state.timeZone), 'MMM', { locale: getLocale(locale) });
      const followingMonth = format(end.toDate(state.timeZone), 'MMM yyyy', { locale: getLocale(locale) });
      return `${firstMonth} - ${followingMonth}`;
    }
  };

  return (
    <StyledCalendarContainer {...calendarProps}>
      <CalendarHeader>
        <Title>{generateMonthsText(userLocale)}</Title>
        <ButtonContainer>
          <PaginationButton {...prevButtonProps}>
            <FontAwesomeIcon icon={faAngleLeft} size="1x" />
          </PaginationButton>
          <PaginationButton {...nextButtonProps}>
            <FontAwesomeIcon icon={faAngleRight} size="1x" />
          </PaginationButton>
        </ButtonContainer>
      </CalendarHeader>
      <FlexTable {...gridProps}>
        <WeekContainer key="header" {...headerProps}>
          {weekDays.map((weekDay, i) => (
            <HeaderCell key={`header-${weekDay}-${i}`}>{weekDay}</HeaderCell>
          ))}
        </WeekContainer>
        {Weeks}
      </FlexTable>
    </StyledCalendarContainer>
  );
};
