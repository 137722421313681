import { ApiClient } from '@mezo/web/api-client';
import { historyContext as history } from '@mezo/web/contexts';
import { sessionSlice, store } from '@mezo/web/store';

import {
  EmailAuthProvider,
  getAuth,
  getIdToken,
  GoogleAuthProvider,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { app } from './firebase.app.service';

const AUTH_ERROR_CODES = {
  USER_NOT_FOUND: 'auth/user-not-found',
  WRONG_PASSWORD: 'auth/wrong-password',
};

const FIVE_MINUTES_AS_MS = 300000;

const deleteAndSignout = async (user: any) => {
  await user.delete();
  await auth.signOut();
};

export const auth = getAuth(app);
export const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: GoogleAuthProvider.PROVIDER_ID,
      disableSignUp: {
        status: true,
      },
      requireDisplayName: false,
      customParameters: {
        // Forces account selection even when one account
        // is available.
        hd: 'mezo.io',
      },
    },
    {
      provider: EmailAuthProvider.PROVIDER_ID,
      disableSignUp: {
        status: true,
      },
      requireDisplayName: false,
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult: (authResult: any, redirectUrl: string) => {
      if (!authResult.user.email.includes('mezo.io')) {
        deleteAndSignout(authResult.user);
        return false;
      }
      return true;
    },
  },
};

onIdTokenChanged(auth, (user) => {
  if (user) {
    // Set bearer token on auth utils header
    const parsedUser: any = user.toJSON();
    const { stsTokenManager } = parsedUser;
    const { accessToken, expirationTime } = stsTokenManager;

    const msUntilRenew = expirationTime - FIVE_MINUTES_AS_MS - Date.now();

    ApiClient.NON_CHAT_API.setAuthToken(`Bearer ${accessToken}`);
    ApiClient.CHAT_API.setAuthToken(`Bearer ${accessToken}`);

    setTimeout(() => {
      getIdToken(user, true);
    }, msUntilRenew);
  }
});

export class FirebaseAuthService {
  static signInWithEmail(email: string, password: string): void {
    const { authenticateUserFailure } = sessionSlice.actions;

    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        history.push('/dashboard');
      })
      .catch((error) => {
        if (error.code === AUTH_ERROR_CODES.USER_NOT_FOUND) {
          store.dispatch(authenticateUserFailure('Invalid email and password. Please try again'));
        } else if (error.code === AUTH_ERROR_CODES.WRONG_PASSWORD) {
          store.dispatch(authenticateUserFailure('Invalid password. Please try again'));
        }
      });
  }
  static async logOut() {
    try {
      const { logOutUserSuccess } = sessionSlice.actions;

      await signOut(auth);

      // Sign-out successful.
      store.dispatch(logOutUserSuccess());
      history.push('/login');
      window.location.reload(); // HACK
    } catch (error) {
      //
    }
  }
}
