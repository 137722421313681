import { TenantUserDto } from '../user';
import { BotRequestDto } from './bot-request.dto';
import { MessageTransactionDto } from './message-transaction.dto';

export class BotRequestContextDto extends BotRequestDto {
  event?: string;
  lastMessage?: MessageTransactionDto;
  currentMessage: MessageTransactionDto;
  resident?: TenantUserDto;
}
