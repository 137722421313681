import { ApiClient } from '@mezo/web/api-client';
import { MediaFileInfoDto, MediafileUploadRequestDto, MediafileUploadResponseDto } from '@reshub/dtos';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';

export interface Upload extends MediaFileInfoDto {
  uploadProgress: number;
}

export const useUploadMedia = (residentId: string, dialogId: string, loopId: string) => {
  const [uploads, setUploads] = useState<{ [key: string]: Upload }>({});
  const [isUploadingMedia, setIsUploadingMedia] = useState<boolean>(false);

  const setProgressCompleted = useCallback((filename: string, progressValue: number) => {
    setUploads((previousUploads) => {
      const selectedUpload = previousUploads[filename];
      if (!selectedUpload) {
        return previousUploads;
      }
      const updatedUpload = {
        ...selectedUpload,
        uploadProgress: progressValue,
      };
      return {
        ...previousUploads,
        [filename]: updatedUpload,
      };
    });
  }, []);

  const deleteMedia = useCallback((filename: string) => {
    setUploads((previousUploads) => {
      delete previousUploads[filename];
      return previousUploads;
    });
  }, []);

  const clearMedia = useCallback(() => setUploads({}), []);

  const useSubmitMedia = () => {
    const mediaFileInfos = Object.values(uploads).map(({ filename, contentType, url }) => ({
      filename,
      contentType,
      url,
    }));
    const dialogPayload = {
      loopId,
      mediaFileInfos,
    };
    return useMutation(() => ApiClient.CHAT_API.utility.patch(`dialogs/${dialogId}/media`, dialogPayload));
  };

  const useUploadMediaMutation = () =>
    useMutation({
      mutationFn: async ({ files }: { files: File[] }) => {
        setIsUploadingMedia(true);
        const filesToSend = ensureFilesHaveUniqueNames(files);
        const payload: MediafileUploadRequestDto = {
          residentId,
          files: filesToSend.map((f) => ({
            name: f.name,
            type: f.type,
          })),
        };
        const { data } = await ApiClient.CHAT_API.utility.post<MediafileUploadResponseDto>('/bot/media', payload);

        const uploadsBeginning: Record<string, Upload> = {};
        const uploadsEnd: Record<string, Upload> = {};
        const uploadPromises = [];

        for (const file of files) {
          const writableFile = data.files[file.name];
          const config = {
            onUploadProgress: (progressEvent: ProgressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgressCompleted(writableFile.filename, percentCompleted);
            },
          };
          const uploadStart: Upload = {
            uploadProgress: 0,
            url: writableFile.url,
            filename: writableFile.filename,
            contentType: writableFile.contentType,
          };
          const uploadEnd: Upload = {
            uploadProgress: 100,
            url: writableFile.url,
            filename: writableFile.filename,
            contentType: writableFile.contentType,
          };

          uploadsBeginning[writableFile.filename] = uploadStart;
          uploadsEnd[writableFile.filename] = uploadEnd;
          uploadPromises.push(axios.put(writableFile.writeUrl, file, config));
        }
        setUploads((previousUploads) => {
          return {
            ...previousUploads,
            ...uploadsBeginning,
          };
        });
        await Promise.all(uploadPromises);
        setUploads((previousUploads) => {
          return {
            ...previousUploads,
            ...uploadsEnd,
          };
        });
        setIsUploadingMedia(false);
      },
    });
  return {
    useUploadMediaMutation,
    uploads: Object.values(uploads) as Upload[],
    isUploadingMedia,
    deleteMedia,
    useSubmitMedia,
    clearMedia,
  };
};

const ensureFilesHaveUniqueNames = (files: File[]): File[] => {
  const result: File[] = [];

  const found = new Map<string, number>();
  for (const file of files) {
    if (!found.has(file.name)) {
      found.set(file.name, 0);
      result.push(file);
    } else {
      const previousCount = found.get(file.name) ?? 0;

      result.push(new File([file], `${previousCount}-${file.name}`, { type: file.type }));

      found.set(file.name, previousCount + 1);
    }
  }

  return result;
};
