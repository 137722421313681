import { ApiClient } from '@mezo/web/api-client';
import { ServiceRequestAddDialogDto, ServiceRequestAddRequestDto } from '@reshub/dtos';
import { useMutation } from 'react-query';

export const useCreateServiceRequest = () => {
  return useMutation((serviceRequest: ServiceRequestAddRequestDto) => {
    return ApiClient.CHAT_API.utility.post('/service-requests', serviceRequest);
  });
};

export const useCreateServiceRequestFromDialog = () => {
  return useMutation((serviceRequestDialogAndLoop: ServiceRequestAddDialogDto) => {
    return ApiClient.CHAT_API.utility.post('/service-requests/dialog', serviceRequestDialogAndLoop);
  });
};
