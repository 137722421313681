import { CustomerId } from './customer.model';

export type UnitGroupId = string;

export class UnitGroup {
  id: UnitGroupId;
  customerId: CustomerId;
  name: string;
  description?: string;
  createdBy: string;
  createdAt: number;
  updatedAt?: number;
}
