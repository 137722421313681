import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getFirestore,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  where,
  WhereFilterOp,
} from 'firebase/firestore';
import { app } from './firebase.app.service';

export const firestore = getFirestore(app);
export class FirebaseFirestoreService {
  static buildQuery(whereClauses: [string, WhereFilterOp, string | number][]): QueryConstraint[] {
    const clauses = whereClauses.map(([field, operator, value]) => where(field, operator, value));
    return clauses;
  }

  static watchCollectionForChanges(
    collectionName: string,
    whereClauses: QueryConstraint[],
    callback: (doc: QuerySnapshot<DocumentData>) => void
  ) {
    const q = query(collection(firestore, collectionName), ...whereClauses);
    const unsubscribe = onSnapshot(q, (doc) => {
      callback(doc);
    });
    return unsubscribe;
  }

  static watchCollectionItemForChanges(
    collectionName: string,
    id: string,
    callback: (doc: DocumentSnapshot<DocumentData>) => void
  ) {
    const unsubscribe = onSnapshot(doc(collection(firestore, collectionName), id), (doc) => {
      callback(doc);
    });
    return unsubscribe;
  }
}
