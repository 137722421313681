import { FindUnitsAddressQueryDto } from '@reshub/dtos';

export const BRANDING_ID_KEY = 'brandingId';
export const CUSTOMER_ID_KEY = 'customerId';
export const DISABLE_RESIDENT_STORAGE_KEY = 'disableResidentStorage';

export const DASHBOARD = '/dashboard';
export const ADDRESSES = '/addresses';
export const LOGIN = '/login';
export const HOME = '/';
export const CUSTOMER = '/customer';
export const CAPABILITY = '/cap';
export const RESIDENT = '/resident';

export const SERVICE_REQUEST = '/service-request';
export const buildServiceRequestRoute = (serviceRequestId: string): string => {
  return `${SERVICE_REQUEST}/${serviceRequestId}`;
};

export const CHAT = '/chat';
export const CUSTOMERS = '/customers';

export const AVAILABILITY = 'availability';

const appendQueryParameters = (url: string, parameters: Record<string, string | undefined>): string => {
  const query = new URLSearchParams();
  for (const key in parameters) {
    const value = parameters[key];
    if (value) {
      query.append(key, value);
    }
  }

  const queryString = query.toString();
  if (queryString.length > 0) {
    url += `?${queryString}`;
  }
  return url;
};

const appendBrandingId = (url: string, brandingId: string | undefined): string => {
  return appendQueryParameters(url, { [BRANDING_ID_KEY]: brandingId });
};

export const buildChatRoute = (
  customerId: string,
  residentId: string,
  unitId: string,
  brandingId: string | undefined,
  disableResidentStorage = false
): string => {
  const chatUrl = `${CHAT}${buildCustomerResidentUnitRoute(customerId, residentId, unitId)}`;
  return appendQueryParameters(chatUrl, {
    [BRANDING_ID_KEY]: brandingId,
    [DISABLE_RESIDENT_STORAGE_KEY]: disableResidentStorage ? 'true' : 'false',
  });
};

export const buildCustomerResidentUnitRoute = (customerId: string, residentId: string, unitId: string): string => {
  return `${CUSTOMERS}/${customerId}/residents/${residentId}/units/${unitId}`;
};

export const buildAddressesRoute = (brandingId: string | undefined, customerId?: string): string => {
  const url = `${CHAT}${ADDRESSES}`;
  if (customerId) {
    const searchQuery = new URLSearchParams({ [CUSTOMER_ID_KEY]: customerId });
    return `${appendBrandingId(url, brandingId)}&${searchQuery.toString()}`;
  }
  return appendBrandingId(url, brandingId);
};

export const buildUnitIdentificationSubRoute = (
  customerId: string,
  residentId: string,
  brandingId: string | undefined
): string => {
  const url = `${CUSTOMERS}/${customerId}/residents/${residentId}/units`;
  return appendBrandingId(url, brandingId);
};

export const buildUnitIdentificationRoute = (
  customerId: string,
  residentId: string,
  brandingId: string | undefined,
  disableResidentStorage = false
): string => {
  const unitIdRoute = `${CHAT}${buildUnitIdentificationSubRoute(customerId, residentId, brandingId)}`;
  return appendQueryParameters(unitIdRoute, {
    [DISABLE_RESIDENT_STORAGE_KEY]: disableResidentStorage ? 'true' : 'false',
  });
};

export const RESIDENT_IDENTIFICATION = '/resident-identification';
export const buildResidentIdentificationRoute = (
  unitAddressQuery: FindUnitsAddressQueryDto,
  brandingId: string | undefined,
  customerId?: string
): string => {
  const searchQuery = new URLSearchParams(unitAddressQuery);
  if (brandingId) {
    searchQuery.append(BRANDING_ID_KEY, brandingId);
  }
  if (customerId) {
    searchQuery.append(CUSTOMER_ID_KEY, customerId);
  }
  return `${CHAT}${RESIDENT_IDENTIFICATION}?${searchQuery.toString()}`;
};

export const buildChatLandingRoute = (
  customerId: string,
  residentId: string,
  unitId: string,
  brandingId?: string
): string => {
  const baseRoute = buildCustomerResidentUnitRoute(customerId, residentId, unitId);
  const intro = `${baseRoute}/intro`;
  if (brandingId) {
    return appendBrandingId(intro, brandingId);
  }
  return intro;
};

export const buildEmergencyListRoute = (customerId: string, residentId: string, unitId: string): string => {
  const baseRoute = buildCustomerResidentUnitRoute(customerId, residentId, unitId);
  return `${baseRoute}/emergencies`;
};

export const buildEmergencyDetailsRoute = (
  customerId: string,
  residentId: string,
  unitId: string,
  emergencyId: string
): string => {
  const baseRoute = buildCustomerResidentUnitRoute(customerId, residentId, unitId);
  return `${baseRoute}/emergencies/${emergencyId}`;
};

export const buildEmergencySubmittedRoute = (
  customerId: string,
  residentId: string,
  unitId: string,
  emergencyId: string
): string => {
  const baseRoute = buildCustomerResidentUnitRoute(customerId, residentId, unitId);
  return `${baseRoute}/emergencies/${emergencyId}/submitted`;
};

export const buildResidentServiceRequestListRoute = (customerId: string, residentId: string, unitId: string): string =>
  `${CHAT}${CUSTOMERS}/${customerId}/residents/${residentId}/units/${unitId}/service-requests`;

export const buildGuestResidentRegistrationRoute = (customerId: string): string =>
  `${CUSTOMERS}/${customerId}/residents/register`;
