import { useContext } from 'react';
import { TwThemeContext } from './tw-theme';

const Sun = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93v-.001zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121v-.001zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
  </svg>
);

const Moon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M10 7a7 7 0 0 0 12 4.9v.1c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2h.1A6.977 6.977 0 0 0 10 7zm-6 5a8 8 0 0 0 15.062 3.762A9 9 0 0 1 8.238 4.938 7.999 7.999 0 0 0 4 12z" />
  </svg>
);

export const TwThemeToggle = () => {
  const { theme, setTheme } = useContext(TwThemeContext);

  function isMezo() {
    return theme === 'mezo';
  }

  return (
    <div tw="flex flex-col items-center p-10 space-y-10 rounded-lg bg-secondary">
      <h1 tw="text-lg font-bold uppercase text-text">{isMezo() ? 'Dark' : 'Light'} Mode</h1>
      <button
        tw="w-8 h-8 fill-current focus:outline-none text-primary hover:text-secondary"
        onClick={() => setTheme(isMezo() ? 'mezo' : 'override')}
      >
        {isMezo() ? <Moon /> : <Sun />}
      </button>
    </div>
  );
};
