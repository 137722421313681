export enum NODE_TYPES {
  text,
  media,
}

export const MEZO_LOGO_URL = 'https://m.mezo.io/mezo.png';

export const BOT = 'BOT';

export enum KEYCODES {
  enter = 13,
}
