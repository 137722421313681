import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectChatDomain = (state: RootState) => state.chat;
export const selectResidents = createSelector(selectChatDomain, (state) => state.residents);
export const selectMessages = createSelector(selectChatDomain, (state) => state.messages);
export const selectChatIsLoading = createSelector(selectChatDomain, (state) => state.isLoading);
export const selectChatError = createSelector(selectChatDomain, (state) => state.error);
export const selectResidentId = createSelector(selectChatDomain, (state) => state.residentId);
export const selectDialogId = createSelector(selectChatDomain, (state) => state.dialogId);
export const selectLoopId = createSelector(selectChatDomain, (state) => state.loopId);
export const selectHasSystemPush = createSelector(selectChatDomain, (state) => state.hasSystemPush);
export const selectIsDone = createSelector(selectChatDomain, (state) => state.isDone);
export const selectChatEvents = createSelector(selectChatDomain, (state) => state.chatEvents);
export const selectChatCustomerId = createSelector(selectChatDomain, (state) => state.customerId);
