import { UserSessionStatusReason, UserSessionStatusType } from '@reshub/model';
import { IsOptional } from 'class-validator';

export class UpdateUserSessionDto {
  @IsOptional()
  customerId?: string;
  @IsOptional()
  residentId?: string;
  @IsOptional()
  unitId?: string;

  @IsOptional()
  status?: {
    type: UserSessionStatusType;
    reason?: UserSessionStatusReason;
  };

  @IsOptional()
  dialogId?: string;
  @IsOptional()
  loopId?: string;

  @IsOptional()
  flow?: string;
  @IsOptional()
  node?: string;

  @IsOptional()
  serviceRequests?: {
    loopId: string;
    id: string;
  }[];

  @IsOptional()
  taxonomies?: {
    loopId: string;
    itemId?: string;
    symptomId?: string;
    componentId?: string;
    locationId?: string;
  }[];

  @IsOptional()
  isEmergency?: boolean;

  @IsOptional()
  error?: {
    type?: string;
  };
}
