import { createGlobalStyle } from 'styled-components';

const brandColors = {
  black: '#000000',
  white: '#ffffff',
  brand_primary: '#F15E5A',
  brand_secondary: '#50BDA2',
  brand_pending: '#F7934e',
  grey: {
    one: '#231F20',
    two: '#404040',
    three: '#808180',
    four: '#B2B2B2',
    five: '#CCCCCC',
    six: '#E5E5E5',
    seven: '#F2F2F2',
    eight: '#F7F7F7',
    nine: '#EEEEEE',
  },
  brand_primary_shade: {
    one: '#080202',
    two: '#290A0A',
    three: '#4A1111',
    four: '#6C1918',
    five: '#8D2322',
    six: '#AE312E',
    seven: '#D04440',
    eight: '#F15E5A',
    nine: '#FE8785',
    ten: '#FEA09E',
    eleven: '#FEB9B8',
    twelve: '#FFD3D3',
    thirteen: '#FFEDED',
  },
  brand_secondary_shade: {
    one: '#04251F',
    two: '#09382E',
    three: '#115143',
    four: '#1F715E',
    five: '#34977F',
    six: '#50BDA2',
    seven: '#5CDABA',
    eight: '#6FEFCF',
    nine: '#8FFDE1',
    ten: '#A1FFE7',
    eleven: '#B4FFEE',
    twelve: '#CDFFF3',
    thirteen: '#E6FFF9',
  },
  brand_pending_shade: {
    one: '#F49254',
    two: '#F8AC7C',
    three: '#FDBD94',
    four: '#FFD3B7',
    five: '#FFEADD',
  },
  errors: {
    main: '#DC4B4A',
  },
};

export const MezoStylesBase = createGlobalStyle`
  .mezo{
    --light-grey: ${brandColors.grey.seven};
    --primary: ${brandColors.brand_primary};
    --primary-light: ${brandColors.brand_primary_shade.four};
    --secondary: ${brandColors.brand_secondary};
    --background: ${brandColors.grey.six};
    --background-light: ${brandColors.white};
    --background-secondary: ${brandColors.grey.seven};
    --background-dark: ${brandColors.grey.one};
    --background-header: ${brandColors.black};
    --alert: ${brandColors.brand_primary_shade.eight};
    --alert-light: ${brandColors.brand_primary_shade.thirteen};
    --pending: ${brandColors.brand_pending_shade.one};
    --pending-light: ${brandColors.brand_pending_shade.five};
    --error: ${brandColors.errors.main};
    --success-dark: ${brandColors.brand_secondary_shade.three};
    --success: ${brandColors.brand_secondary_shade.six};
    --success-light: ${brandColors.brand_secondary_shade.thirteen};
    --disabled: ${brandColors.grey.three};
    --processing: ${brandColors.grey.four};
    --processing-light: ${brandColors.white};
    --delivered: ${brandColors.brand_secondary};
    --text: ${brandColors.black};
    --text-inverted: ${brandColors.white};
    --text-light: ${brandColors.grey.three};
    --text-medium-dark: ${brandColors.grey.two};
    --text-grey: ${brandColors.grey.five};
    --border-grey: ${brandColors.grey.nine};
  }
`;
