import { Address } from './address.model';
import { CustomerId } from './customer.model';
import { Diagnosis } from './diagnosis.model';
import { MediaFileInfo } from './media-fileinfo.model';
import { MessageMedium } from './message-transaction.model';
import type { CategoryKVP, ComponentKVP, ItemKVP, LocationKVP, SymptomKVP } from './taxonomy.model';

export interface CustomerServiceRequestV1 {
  residentId: string;
  location: string;
  categoryId: string;
  subCategoryId: string;
  issueId: string;
  description: string;
  notes: string;
  status: string;
}

export enum ServiceRequestStatusV1 {
  PENDING = 'pending',
  DELIVERED = 'delivered',
  ARCHIVED = 'archived',
}

export enum ServiceRequestArchiveReasonV1 {
  DUPLICATE = 'Duplicate service request',
  FAILED = 'Failed resident validation',
  MISSING = 'No problem statement',
  TEST = 'Test service request',
  OTHER = '',
}

export class ServiceRequestReportedByV1 {
  id: string;
  sourceId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export class ServiceRequestUnitV1 {
  id: string;
  propertyId: string;
  propertyName: string;
  address: Address;
  customerId: CustomerId;
  customerName: string;
  customerIconUrl: string;
  timezone?: {
    dstOffset: number;
    rawOffset: number;
    status: string;
    timeZoneId: string;
    timeZoneName: string;
  };
}

//NOTE: DialogFlow lowercases the desciptions
export interface ServiceRequestDetailsV1 {
  item: ItemKVP;
  component: ComponentKVP;
  symptom: SymptomKVP;
  location: LocationKVP;
  category: CategoryKVP;
  itemdescription: string;
  issuedescription: string;
  locationdescription: string;
  problemstatement: string;
  description: string;
  descriptionHtml: string;
  diagnosis?: Diagnosis;
}

export interface ServiceRequestCustomerDataV1 {
  mezoItem: string;
  mezoSymptom: string;
  mezoComponent: string;
  mezoLocation: string;
  mezoCategory: string;
  myndCategory: string;
  myndSubCategory: string;
  myndIssue: string;
  myndCategoryId: string;
  myndSubCategoryId: string;
  myndIssueId: string;
  myndLocation: string;
  mezoItemId: string;
  mezoSymptomId: string;
  mezoComponentId: string;
  mezoLocationId: string;
  mezoCategoryId: string;
  id: string;
}

export class ServiceRequestV1 {
  id: string;
  createdAt: number;
  updatedAt: number;
  updatedBy: string;
  deliveredAt: number;
  deliveredBy: string;
  isAbandoned?: boolean;
  edited: boolean;
  status: ServiceRequestStatusV1;
  tripless: boolean;
  medium: MessageMedium;
  reportedBy: ServiceRequestReportedByV1;
  loopId: string;
  dialogId: string;
  assetLookupIds?: string[];
  unit: ServiceRequestUnitV1;
  mediaFileInfos: MediaFileInfo[];
  details: ServiceRequestDetailsV1;
  customerData: ServiceRequestCustomerDataV1;
  sourceId: string;
  customerServiceRequest: CustomerServiceRequestV1;
  originalCustomerServiceRequest: CustomerServiceRequestV1;
  permissionToEnter?: boolean;
  askPermissionToEnter: boolean;
}
