import { ApiClient } from '@mezo/web/api-client';
import { AvailabilityWindow } from '@reshub/dtos';
import { useMutation } from 'react-query';

export const useSetAvailabilitiesForDialog = (dialogId: string) =>
  useMutation({
    mutationFn: async ({
      availabilityWindows,
      timezoneId,
    }: {
      availabilityWindows: AvailabilityWindow[];
      timezoneId: string;
    }) => {
      const { data } = await ApiClient.CHAT_API.utility.post(`/dialogs/${dialogId}/scheduling`, {
        availabilityWindows,
        timezoneId,
      });
      return data;
    },
  });
