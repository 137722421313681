import { CustomerId } from './customer.model';

export class ApiKey {
  id: string;
  createdAt: number;
  createdBy: string;
  customerId: CustomerId;
  description: string;
  updatedAt?: number;
  updatedBy?: string;
}
