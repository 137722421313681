import { Locale } from '@mezo/shared/utils';
import type { CustomerId } from '@reshub/model';
import { MessageMedium } from '@reshub/model';
import { ChatEvent } from './chat-events.dto';
import { MediaFileInfoDto } from './media-file-info.dto';

export enum ResidentUidPath {
  PHONE = 'data.contact.phoneNumber',
  EMAIL = 'data.contact.emailAddress',
  ID = 'id',
}
export class BotRequestDto {
  constructor(
    public residentUid: string,
    public residentUidPath: ResidentUidPath,
    public medium: MessageMedium,
    public isChoiceButton: boolean,
    public locale: Locale
  ) {
    this.language = new Intl.Locale(locale).language;
  }
  message?: string;
  timestamp?: number;
  params?: any;
  mediaFileInfos?: MediaFileInfoDto[];
  resetCount = 0;
  replay: boolean;
  sessionTimeout: boolean;
  customerId?: CustomerId;
  webhookReplay: boolean;
  chatEvents?: ChatEvent[];
  language: string;
}
