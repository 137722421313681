import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetInfoDto, MessageDto, ServiceRequestResponseDto } from '@reshub/dtos';

type SliceState = {
  asset: AssetInfoDto | null;
  error: boolean;
  isLoading: boolean;
  messages: MessageDto[];
  selectedServiceRequest: ServiceRequestResponseDto | null;
};

const initialState: SliceState = {
  asset: null,
  error: false,
  isLoading: false,
  messages: [],
  selectedServiceRequest: null,
};

export const serviceRequestSlice = createSlice({
  name: 'serviceRequest',
  initialState: initialState,
  reducers: {
    serviceRequestApiCallStart: (state) => {
      state.isLoading = true;
    },
    serviceRequestApiCallSuccess: (state) => {
      state.isLoading = false;
    },
    serviceRequestApiCallError: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.error = true;
    },
    setSelectedServiceRequest: (state, action: PayloadAction<ServiceRequestResponseDto>) => {
      state.selectedServiceRequest = action.payload;
    },
    clearSelectedServiceRequest: (state) => {
      state.selectedServiceRequest = null;
      state.messages = [];
      state.asset = null;
    },
    setMessages: (state, action: PayloadAction<MessageDto[]>) => {
      state.messages = action.payload;
    },
    setAsset: (state, action: PayloadAction<AssetInfoDto | null>) => {
      state.asset = action.payload;
    },
  },
});

export default serviceRequestSlice;
