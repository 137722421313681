import tw from 'twin.macro';

const Container = tw.span``;
const Text = tw.span`text-sm`;
const Link = tw.a`underline cursor-pointer text-primary`;

type TextWithLinkAtEndProps = {
  plainText: string;
  linkText: string;
  onClick: VoidFunction;
};

export const TextWithLinkAtEnd = ({ plainText, linkText, onClick }: TextWithLinkAtEndProps) => (
  <Container>
    <Text>
      {plainText} <Link onClick={onClick}>{linkText}</Link>
    </Text>
  </Container>
);

export default TextWithLinkAtEnd;
