import { Type } from 'class-transformer';
import { IsArray, IsNumber, IsOptional, ValidateNested } from 'class-validator';
import { FieldDto } from '../search';

export class CustomerMappingSearchRequestDto {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => FieldDto)
  readonly fields: FieldDto[];
  @IsOptional()
  @IsNumber()
  readonly resultsPerPage: number;
  @IsNumber()
  @IsOptional()
  readonly nextPage: number;
}
