import { useCallback } from 'react';
import { GoogleAnalyticsUtils, useGoogleAnalytics } from './google-analytics';

export enum MetricsResult {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export type MetricUtils = {
  initialize: (trackingIds: { google?: string }) => void;
  trackEvent: (eventName: string, payload?: any) => void;
  trackUser: (userId?: string) => void;
};

export function useMetrics(): MetricUtils {
  const {
    initialize: initializeGoogleAnalytics,
    setUser: setGoogleUser,
    sendEvent: sendGoogleEvent,
  }: GoogleAnalyticsUtils = useGoogleAnalytics();

  const initialize = useCallback(
    (ids: { google?: string }) => {
      if (ids.google) {
        initializeGoogleAnalytics(ids.google);
      }
    },
    [initializeGoogleAnalytics]
  );

  const trackUser = useCallback(
    (userId?: string) => {
      setGoogleUser(userId);
    },
    [setGoogleUser]
  );

  const trackEvent = useCallback(
    (eventName: string, payload?: any) => {
      sendGoogleEvent(eventName, payload);
    },
    [sendGoogleEvent]
  );

  return {
    initialize,
    trackEvent,
    trackUser,
  };
}
