import { CustomerId } from '@reshub/model';

export class MyndResidentDto {
  customerId: CustomerId;
  unit_id: string;
  lease_flow_id: string;
  resident_id: string;
  first_name: string;
  last_name: string;
  move_in_date: Date;
  updated_at: string;
  email_address?: string;
  phone_number?: string;
}
