import { KeyValuePairDto } from '../customer/key-value-pair.dto';

export class TaxonomyLookupDto {
  taxonomyMapKey?: string;
  itemKVP?: KeyValuePairDto;
  componentKVP?: KeyValuePairDto;
  symptomKVP?: KeyValuePairDto;
  locationKVP?: KeyValuePairDto;
}

export const EMPTY_TAXONOMY_KVP = {
  label: '',
  id: '',
};

export const OTHER_TAXONOMY_KVP = {
  label: 'other',
  id: 'otherID',
};

export const SOMETHING_ELSE_ID = 'somethingElseID';
