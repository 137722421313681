import tw, { css, styled, theme } from 'twin.macro';
import ButtonV2 from '../button.v2';

type ChatScreenFooter = {
  hasError?: boolean;
  isActive: boolean;
  showButtonLoader?: boolean;
  label: string;
  highlightColor?: string;
  onClick: () => void;
  textAbove?: string;
};

type StyleProps = {
  isActive: boolean;
  highlightColor?: string;
};

const FooterWrapper = tw.div`flex flex-col items-center justify-center w-full pb-2 mx-auto bg-white rounded-t-xl`;
const ButtonWrapper = tw.div`w-full px-2`;

const Highlight = styled.div<StyleProps>(({ isActive, highlightColor }) => [
  tw`flex w-full pb-2 mx-auto border-t-2 opacity-50 rounded-t-xl`,
  isActive ? css({ 'border-color': highlightColor }) : tw`border-text-light`,
]);

const TextAboveWrapper = styled.div<{ hasError: boolean }>(({ hasError }) => [
  tw`pb-2 mx-auto text-xs text-center`,
  hasError && tw`text-error`,
]);

export const ChatScreenFooter: React.FC<ChatScreenFooter> = ({
  hasError = false,
  isActive,
  label,
  showButtonLoader = false,
  onClick,
  highlightColor = theme`colors.primary`,
  textAbove,
}) => {
  return (
    <FooterWrapper>
      <Highlight isActive={isActive} highlightColor={highlightColor} />
      {textAbove && <TextAboveWrapper hasError={hasError}>{textAbove}</TextAboveWrapper>}
      <ButtonWrapper>
        <ButtonV2
          primary={true}
          disabled={!isActive || showButtonLoader}
          onClick={onClick}
          highlightColor={highlightColor}
          isLoading={showButtonLoader}
        >
          {label}
        </ButtonV2>
      </ButtonWrapper>
    </FooterWrapper>
  );
};

export default ChatScreenFooter;
