import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectServiceRequestDomain = (state: RootState) => state.serviceRequest;

export const selectSelectedServiceRequest = createSelector(
  selectServiceRequestDomain,
  (state) => state.selectedServiceRequest
);
export const selectServiceRequestMessages = createSelector(selectServiceRequestDomain, (state) => state.messages);
export const selectServiceRequestAsset = createSelector(selectServiceRequestDomain, (state) => state.asset);
export const selectServiceRequestIsLoading = createSelector(selectServiceRequestDomain, (state) => state.isLoading);
