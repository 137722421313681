import { MediaFileInfoDto } from '@reshub/dtos';
type MediaType = 'image' | 'video' | 'unknown';

export const getMediaTypeFromExtension = (mediaFileInfo: MediaFileInfoDto): MediaType => {
  if (mediaFileInfo.contentType) return mediaFileInfo.contentType.split('/')[0] as MediaType;
  const ext = mediaFileInfo?.filename?.split('.').pop();
  if (!ext) {
    return 'unknown';
  }

  const imageExtensions = ['png', 'jpg', 'gif', 'jpeg', 'svg'];
  const videoExtensions = ['mp4', 'mov'];

  // Extract jpg from .jpg
  const strippedExt = ext.slice(1, ext.length);

  if (imageExtensions.includes(strippedExt)) {
    return 'image';
  } else if (videoExtensions.includes(strippedExt)) {
    return 'video';
  } else {
    return 'unknown';
  }
};
