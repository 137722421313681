import Select from 'react-select';
import tw from 'twin.macro';

const FormGroup = tw.div`relative mb-5`;

const Label = tw.label`relative px-2 py-1 text-xs leading-relaxed bg-none left-1 text-text-light`;

export type OptionType = {
  label: string;
  value: string;
};

export type StyledSelectProps = {
  id: string;
  labelString: string;
  options: OptionType[];
  handler: (option: any) => void;
  value: OptionType;
  emptyMessage: string;
  isLoading: boolean;
};

export const StyledSelect: React.FC<StyledSelectProps> = (props) => {
  return (
    <FormGroup key={props.id}>
      <Label id={`${props.id}-label`} htmlFor={props.id}>
        {props.labelString}
      </Label>
      <Select
        aria-labelledby={`${props.id}-label`}
        inputId={props.id}
        options={props.options}
        onChange={props.handler}
        value={props.value}
        noOptionsMessage={() => props.emptyMessage}
        isLoading={props.isLoading && !props.options.length}
        // isClearable={true}
      />
    </FormGroup>
  );
};
