import { ApiClient } from '@mezo/web/api-client';
import { UnitChatConfigurationResponse } from '@reshub/dtos';
import { useQuery } from 'react-query';

export const chatConfigurationQueryKey = (unitId: string | undefined) => ['units', unitId, 'chatConfiguration'];

export const fetchChatConfiguration = async (unitId: string | undefined): Promise<UnitChatConfigurationResponse> => {
  const { data } = await ApiClient.CHAT_API.utility.get<UnitChatConfigurationResponse>(
    `unit/${unitId}/chatConfiguration`
  );
  return data;
};

export const useChatConfiguration = (unitId: string | undefined) => {
  return useQuery(chatConfigurationQueryKey(unitId), async () => fetchChatConfiguration(unitId), {
    enabled: !!unitId,
  });
};
