import tw, { css, styled, theme } from 'twin.macro';
import Spinner from './spinner';

type ButtonProps = {
  isLoading?: boolean;
  highlightColor?: string;
  primary?: boolean;
  disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = styled.button<ButtonProps>(
  ({ highlightColor = theme`colors.primary`, primary = false, disabled = false }) => [
    tw`w-full px-4 text-center rounded-[10px] min-h-[56px] border-[1px]`,
    primary ? css({ 'background-color': highlightColor }) : css({ 'border-color': highlightColor }),
    primary ? tw`text-white` : css({ color: highlightColor }),
    primary && disabled && tw`bg-disabled`,
    !primary && disabled && tw`border-disabled text-disabled`,
    !disabled && tw`hover:opacity-[.85]`,
  ]
);

export const ButtonWithSpinner: React.FC<ButtonProps> = ({ isLoading = false, children, ...rest }) => {
  let loaderColor: string;
  if (rest.primary) {
    loaderColor = theme`colors.white`;
  } else {
    loaderColor = rest.highlightColor || theme`colors.primary`;
  }

  return <Button {...rest}>{isLoading ? <Spinner highlightColor={loaderColor} /> : children}</Button>;
};

export default ButtonWithSpinner;
