import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEventHandler,
  Ref,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import tw from 'twin.macro';

export interface ResizeableTextAreaProps {
  id?: string;
  name: string;
  placeholder?: string;
  minRows: number;
  maxRows: number;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
}

const CustomTextArea = tw.textarea`flex-1 py-3 leading-6 bg-transparent border-none resize-none px-1.5 focus:outline-none active:outline-none focus:ring-[0px] placeholder-text-light`;

export const ResizeableTextArea = forwardRef(
  (
    { id, name, placeholder, minRows, maxRows, value, onChange, onKeyDown, onFocus, onBlur }: ResizeableTextAreaProps,
    ref: Ref<{
      focus: VoidFunction;
      blur: VoidFunction;
    }>
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => ({
      focus() {
        textAreaRef.current?.focus();
      },
      blur() {
        textAreaRef.current?.blur();
      },
    }));

    const [rows, setRows] = useState<number>(minRows);
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const textareaLineHeight = 24;
      const textareaPadding = 24;

      const previousRows = event.target.rows;
      event.target.rows = minRows;

      const currentRows = ~~((event.target.scrollHeight - textareaPadding) / textareaLineHeight);

      if (currentRows === previousRows) {
        event.target.rows = currentRows;
      }

      if (currentRows >= maxRows) {
        event.target.rows = maxRows;
        event.target.scrollTop = event.target.scrollHeight;
      }
      setRows(currentRows < maxRows ? currentRows : maxRows);
      onChange(event);
    };

    return (
      <CustomTextArea
        id={id}
        data-hj-allow
        ref={textAreaRef}
        aria-label={name}
        rows={rows}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }
);

export default ResizeableTextArea;
