import { Address } from '@reshub/model';
import { IsOptional, IsString } from 'class-validator';

export class AddressDto {
  constructor(params: Address) {
    this.address1 = params.address1;
    this.address2 = params.address2;
    this.city = params.city;
    this.state = params.state;
    this.zip = params.zip;
    this.country = params.country;
  }

  @IsString()
  address1: string;

  @IsOptional()
  @IsString()
  address2?: string;

  @IsString()
  city: string;

  @IsString()
  state: string;

  @IsString()
  zip: string;

  @IsString()
  country?: string;

  toModel(): Address {
    return new Address({
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip,
      country: this.country,
    });
  }
}
