export interface CustomerMapping {
  id: string;
  mezoItem: string;
  mezoSymptom: string;
  mezoComponent: string;
  mezoLocation: string;
  mezoCategory: string;
  mezoItemId: string;
  mezoSymptomId: string;
  mezoComponentId: string;
  mezoLocationId: string;
  mezoCategoryId: string;
}

export interface CustomerSpecificCustomerMapping extends CustomerMapping {
  customerCategory: string;
  customerCategoryId: string;
  customerSubCategory: string;
  customerSubCategoryId: string;
  customerIssue: string;
  customerIssueId: string;
  customerLocation: string;
}

export interface MyndSpecificMapping extends CustomerMapping {
  myndCategory: string;
  myndCategoryId: string;
  myndSubCategory: string;
  myndSubCategoryId: string;
  myndIssue: string;
  myndIssueId: string;
  myndLocation: string;
}

export function isSpecificCustomerMapping(
  customerMapping: CustomerMapping | CustomerSpecificCustomerMapping
): customerMapping is CustomerSpecificCustomerMapping {
  return Object.getOwnPropertyNames(customerMapping).some(
    (p: any) => p?.toString().toLowerCase().indexOf('customer') === 0
  );
}
