import { CHAT_INPUT_TYPES, DeliveryIntegrationTypeOptions } from '@reshub/model';

// Session params purposefully using null instead of undefined due to logic currently embedded in Dialogflow
// DO NOT CHANGE TO undefined
export interface SessionParams {
  agentRestartCancel: 'agent' | 'restart' | 'cancel' | null;
  askPermissionToEnter: boolean | null;
  assetCopy: string | null;
  botIntro: string | null;
  botVersion: string | null;
  cesRating: string | null;
  choiceButtonText1: string | null;
  choiceButtonText2: string | null;
  choiceButtonText3: string | null;
  choiceButtonText4: string | null;
  choiceButtonText5: string | null;
  choiceButtonText6: string | null;
  choiceButtonValue1: string | number | null;
  choiceButtonValue2: string | number | null;
  choiceButtonValue3: string | number | null;
  choiceButtonValue4: string | number | null;
  choiceButtonValue5: string | number | null;
  choiceButtonValue6: string | number | null;
  component: string | null;
  createServiceRequest: boolean | null;
  csatRating: string | null;
  customQuestionResponses?: string[] | null;
  customerId: string | null;
  customerName: string | null;
  deliveryIntegrationTypeOverride: DeliveryIntegrationTypeOptions | null;
  diagnosisId: string | null;
  dialogId: string | null;
  displayGalleryView: string | null;
  diySuccess: string | null;
  endDialog: boolean;
  expectingMedia: boolean | null;
  firstName: string | null;
  inputParameter: keyof SessionContextDto['sessionParams'] | null;
  inputType: CHAT_INPUT_TYPES | null;
  isAsset: boolean;
  isGuest: boolean | null;
  issuedescription: string | null;
  item: string | null;
  itemdescription: string | null;
  lastName: string | null;
  location: string | null;
  locale: string | null;
  language: string | null;
  locationdescription: string | null;
  loopId: string | null;
  mediaUpload: boolean;
  medium: string | null;
  otherfeedback: string | null;
  permissionToEnter: boolean | null;
  'platform.collectPrimeSymptom': boolean | null;
  'platform.intakeComplete': boolean | null;
  problemstatement: string | null;
  promptMediaUpload?: boolean;
  propertyId: string | null;
  propertyName: string | null;
  residentId: string | null;
  returnedNonMaintenanceIssue?: boolean;
  skipMediaCollection: boolean | null;
  symptom: string | null;
  unit: {
    numToilets: number | null;
  };
  unitId: string | null;
  unitNumber: string | null;
  webhookReplayRequested: boolean;
  //TODO: remove these. The following are DF internal params that we are using in the platform to determine DIY successes for the JSON diys. Remove this when those flows are converted from the JSON intelliflow structure to DF/other
  acCheckFanAutoDiy: string | null;
  acPowerSwitchDiy: string | null;
  acVentOpenDiy: string | null;
  batteryChangeDiy: string | null;
  breakerResetDiy: string | null;
  cleanFilterDiy: string | null;
  pluggedInAndOnDiy: string | null;
  plungeToiletDiy: string | null;
  toiletHoseTighteningDiy: string | null;
  toiletPlungeDiy: string | null;
  toiletPullChainAdjustDiy: string | null;
  toiletTightenSeatBoltsDiy: string | null;
  toiletTurnOnWaterDiy: string | null;
  toiletValveAdjustDiy: string | null;
}

export interface SessionContextDto {
  sessionParams: SessionParams;
  dialogFlowState: {
    currentPageName: string | null;
    currentFlowName: string | null;
    currentPageDisplayName: string | null;
    languageCode: string | null;
  } | null;
}

export function createNewSessionContextDto(
  residentId: string,
  deliveryIntegrationTypeOverride: DeliveryIntegrationTypeOptions | null,
  dialogId: string,
  customerName: string,
  propertyName: string,
  medium: string,
  isGuest: boolean
) {
  // Session params purposefully using null instead of undefined due to logic currently embedded in Dialogflow
  // DO NOT CHANGE TO undefined
  const sessionContext: SessionContextDto = {
    sessionParams: {
      agentRestartCancel: null,
      askPermissionToEnter: null,
      assetCopy: null,
      botIntro: null,
      botVersion: null,
      cesRating: null,
      choiceButtonText1: null,
      choiceButtonText2: null,
      choiceButtonText3: null,
      choiceButtonText4: null,
      choiceButtonText5: null,
      choiceButtonText6: null,
      choiceButtonValue1: null,
      choiceButtonValue2: null,
      choiceButtonValue3: null,
      choiceButtonValue4: null,
      choiceButtonValue5: null,
      choiceButtonValue6: null,
      component: null,
      createServiceRequest: null,
      csatRating: null,
      customerId: null,
      customerName,
      deliveryIntegrationTypeOverride,
      diagnosisId: null,
      dialogId: dialogId,
      displayGalleryView: null,
      diySuccess: null,
      endDialog: false,
      expectingMedia: null,
      firstName: null,
      inputParameter: null,
      inputType: null,
      isAsset: false,
      isGuest,
      issuedescription: null,
      item: null,
      itemdescription: null,
      lastName: null,
      locale: null,
      language: null,
      location: null,
      locationdescription: null,
      loopId: '0',
      mediaUpload: true,
      medium: medium,
      otherfeedback: null,
      permissionToEnter: null,
      'platform.collectPrimeSymptom': false,
      'platform.intakeComplete': false,
      promptMediaUpload: false,
      problemstatement: null,
      propertyId: null,
      propertyName,
      residentId: residentId,
      skipMediaCollection: null,
      symptom: null,
      unit: {
        numToilets: null,
      },
      unitId: null,
      unitNumber: null,
      webhookReplayRequested: false,
      acCheckFanAutoDiy: null,
      acPowerSwitchDiy: null,
      acVentOpenDiy: null,
      batteryChangeDiy: null,
      breakerResetDiy: null,
      cleanFilterDiy: null,
      pluggedInAndOnDiy: null,
      plungeToiletDiy: null,
      toiletHoseTighteningDiy: null,
      toiletPlungeDiy: null,
      toiletPullChainAdjustDiy: null,
      toiletTightenSeatBoltsDiy: null,
      toiletTurnOnWaterDiy: null,
      toiletValveAdjustDiy: null,
    },
    dialogFlowState: {
      currentFlowName: null,
      currentPageName: '',
      currentPageDisplayName: 'NEW',
      languageCode: null,
    },
  };

  return sessionContext;
}
