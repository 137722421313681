import { IsNumber, IsOptional, IsString } from 'class-validator';

export class ServiceRequestSearchRequestDto {
  @IsString()
  @IsOptional()
  readonly field: string;
  @IsString()
  @IsOptional()
  readonly value: string;
  @IsOptional()
  @IsNumber()
  readonly resultsPerPage: number;
  @IsNumber()
  @IsOptional()
  readonly nextPage: number;
}
