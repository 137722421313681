import { createSelector } from '@reduxjs/toolkit';
import { selectChatCustomerId, selectDialogId, selectLoopId, selectResidentId } from '.';
import { selectSelectedServiceRequest } from './service-request.selectors';

export type MetricsDimensions = {
  customerId?: string;
  residentId?: string;
  dialogId?: string;
  loopId?: string;

  propertyId?: string;
  unitId?: string;
};

export const selectMetricsDimensions = createSelector(
  [selectSelectedServiceRequest, selectResidentId, selectDialogId, selectLoopId, selectChatCustomerId],
  (serviceRequest, chatResidentId, chatDialogId, chatLoopId, chatCustomerId) => {
    let dialogId = '';
    let loopId = '';
    let residentId = '';
    let customerId;

    // TODO: pull these values from the user session
    if (serviceRequest?.dialogId) {
      dialogId = serviceRequest?.dialogId ?? '';
      loopId = serviceRequest?.loopId ?? '0';
      residentId = serviceRequest?.reportedBy?.id ?? '';
      customerId = serviceRequest?.unit?.customerId ?? '';
    } else if (chatDialogId.length > 0 || chatCustomerId) {
      dialogId = chatDialogId;
      loopId = chatLoopId;
      residentId = chatResidentId;
      customerId = chatCustomerId;
    }

    return {
      customerId,
      residentId,
      dialogId,
      loopId,

      unitId: serviceRequest?.unit?.id,
      propertyId: serviceRequest?.unit?.propertyId,
    };
  }
);
