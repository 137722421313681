import { ApiClient } from '@mezo/web/api-client';
import { EmergencyConfiguration, EmergencyItem } from '@reshub/dtos';
import { useQuery } from 'react-query';

export const useEmergencyList = (unitId: string | undefined) => {
  return useQuery(
    ['emergencies', { unitId }],
    async () => {
      const { data } = await ApiClient.CHAT_API.utility.get<EmergencyItem[]>(`/emergencies?unitId=${unitId}`);
      return data;
    },
    { enabled: !!unitId }
  );
};

export const useEmergency = (emergencyId: string | undefined, unitId: string | undefined) => {
  return useQuery(
    ['emergencies', emergencyId, { unitId }],
    async () => {
      const { data } = await ApiClient.CHAT_API.utility.get<EmergencyItem[]>(`/emergencies?unitId=${unitId}`);
      return data.find(({ id }) => id === emergencyId);
    },
    { enabled: !!unitId && !!emergencyId }
  );
};

export const useEmergencyConfig = (unitId: string | undefined) => {
  return useQuery(
    ['emergencies', unitId, 'config'],
    async () => {
      const { data } = await ApiClient.CHAT_API.utility.get<EmergencyConfiguration>(
        `/emergencies/config?unitId=${unitId}`
      );
      return data;
    },
    { enabled: !!unitId, refetchOnWindowFocus: false }
  );
};
