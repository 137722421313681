import { Type } from 'class-transformer';
import { ValidateNested } from 'class-validator';
import { KeyValuePairDto } from '../customer';

export class DiagnosisDependenciesDto {
  @ValidateNested()
  @Type(() => KeyValuePairDto)
  skillLevels: KeyValuePairDto[];
  @ValidateNested()
  @Type(() => KeyValuePairDto)
  resolutionTypes: KeyValuePairDto[];
}
