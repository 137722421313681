import { ApiClient } from '@mezo/web/api-client';
import { FindUnitsAddressQueryDto, FindUnitsAddressQueryResponse, UnitResidentSearchResponse } from '@reshub/dtos';
import { useQuery } from 'react-query';

const sortUnitAddresses = (
  { entry: leftEntry }: FindUnitsAddressQueryResponse,
  { entry: rightEntry }: FindUnitsAddressQueryResponse
) => {
  if (!leftEntry) {
    return -1;
  }

  if (!rightEntry) {
    return 1;
  }

  if (leftEntry < rightEntry) {
    return -1;
  }

  if (leftEntry > rightEntry) {
    return 1;
  }

  return 0;
};

export const useUnitAddresses = (findAddressQuery: FindUnitsAddressQueryDto | undefined) => {
  const query = new URLSearchParams(findAddressQuery);
  return useQuery(
    ['unitAddresses', query.toString()],
    async () => {
      const { data } = await ApiClient.CHAT_API.utility.get(`unit/search?${query.toString()}`);
      return data as FindUnitsAddressQueryResponse[];
    },
    {
      enabled: !!findAddressQuery,
      select: (data) => [...data.sort(sortUnitAddresses)],
    }
  );
};

export const useUnitResidentSearch = (unitId: string | undefined, residentLastName: string | undefined) => {
  return useQuery(
    ['validateUnitResidents', unitId, residentLastName],
    async () => {
      const { data } = await ApiClient.CHAT_API.utility.get(`unit/${unitId}/residents?lastName=${residentLastName}`);
      return data as UnitResidentSearchResponse[];
    },
    {
      enabled: !!unitId && !!residentLastName,
    }
  );
};
