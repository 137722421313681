import { IAddress } from '@reshub/model';
import { IsDefined, IsOptional, IsString } from 'class-validator';

export class UpdateUnitAddressRequest implements IAddress {
  @IsString()
  @IsDefined()
  address1: string;

  @IsString()
  @IsOptional()
  address2?: string;

  @IsString()
  @IsDefined()
  city: string;

  @IsString()
  @IsDefined()
  state: string;

  @IsString()
  @IsDefined()
  zip: string;

  @IsString()
  @IsDefined()
  country: string;
}
