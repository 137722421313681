import { CustomerId } from './customer.model';
import { UnitGroupId } from './unit-group.model';

export enum PlatformId {
  MEZO = 'MEZO',
}

export class Configuration {
  id: string;
  scope: {
    platform: PlatformId;
    customerId: CustomerId | null;
    unitGroupId: UnitGroupId | null;
  };
  integration?: Record<string, unknown>;
  branding?: Record<string, unknown>;
  operation?: Record<string, unknown>;
}
