import { IsBoolean, IsOptional, IsString } from 'class-validator';

export enum DialogUpdateStatus {
  COMPLETE = 'complete',
}

export class DialogUpdateRequestDto {
  @IsString()
  @IsOptional()
  entryNotes?: string;

  @IsBoolean()
  @IsOptional()
  permissionToEnter?: boolean;

  @IsString()
  @IsOptional()
  status?: DialogUpdateStatus;
}

export class DialogUpdateResponseDto {
  @IsString()
  id: string;

  @IsString()
  status?: DialogUpdateStatus;

  @IsString()
  entryNotes?: string;

  @IsBoolean()
  permissionToEnter?: boolean;
}
