import { KeyValuePairDto } from '@reshub/dtos';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw, { theme } from 'twin.macro';
import { ChatScreenFooter, ChatScreenHeader, MemoizedPickListCell, PickList } from '..';
import { ChatContainer, HeaderShadow } from '../chat/styled-components';

const Content = tw.div`relative flex flex-col h-full bg-white`;
const PickListWrapper = tw.div`flex-1 px-4 pb-1 overflow-y-auto mt-7`;
const FooterWrapper = tw.div`w-full `;

interface SymptomProps {
  itemName: string;
  handleClose: VoidFunction;
  onSubmit: (symptom: KeyValuePairDto) => void;
  symptoms: KeyValuePairDto[];
  isLoading: boolean;
}

export const SymptomPickList: React.FC<SymptomProps> = ({ itemName, handleClose, onSubmit, symptoms, isLoading }) => {
  const { t } = useTranslation();
  const [selectedSymptom, setSelectedSymptom] = useState<KeyValuePairDto | undefined>();

  const highlightColor = theme`colors.primary`;

  const handleSymptomSelected = useCallback((symptom: KeyValuePairDto | undefined) => {
    setSelectedSymptom((previousSelectedSymptom) => {
      if (previousSelectedSymptom?.id === symptom?.id) {
        return undefined;
      }
      return symptom;
    });
  }, []);

  const handleSubmit = useCallback(() => {
    if (selectedSymptom) {
      onSubmit(selectedSymptom);
      return;
    }
  }, [onSubmit, selectedSymptom]);

  const renderSymptomItem = useCallback(
    (symptom: KeyValuePairDto) => {
      const selected = symptom.id === selectedSymptom?.id;
      return (
        <MemoizedPickListCell
          key={symptom.id}
          item={symptom}
          onClick={handleSymptomSelected}
          selected={selected}
          highlightColor={highlightColor}
        >
          {symptom.label}
        </MemoizedPickListCell>
      );
    },
    [handleSymptomSelected, highlightColor, selectedSymptom?.id]
  );

  return (
    <ChatContainer>
      <Content>
        <HeaderShadow>
          <ChatScreenHeader onClose={handleClose}>
            <span>
              <strong>{t('symptom.headerText1')}</strong>&nbsp;
              {t('symptom.headerText2', { itemName: itemName })}
            </span>
          </ChatScreenHeader>
        </HeaderShadow>
        <PickListWrapper>
          <PickList
            items={symptoms ?? []}
            highlightColor={highlightColor}
            renderItem={renderSymptomItem}
            loading={isLoading}
          />
        </PickListWrapper>
        <FooterWrapper>
          <ChatScreenFooter
            highlightColor={highlightColor}
            isActive={!!selectedSymptom}
            onClick={handleSubmit}
            label={t('symptom.submitButton')}
          />
        </FooterWrapper>
      </Content>
    </ChatContainer>
  );
};
