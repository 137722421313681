import { UserId } from '@reshub/model';
import { ContactDto } from './contact.dto';
import { UnitDto } from './unit.dto';

export class ResidentDto {
  id: UserId;
  sourceId: string;
  contact: ContactDto;
  leaseFlowId: string;
  moveInDate: Date;
  primaryUnit: UnitDto;
  unitIds: string[];
  updatedAt: number;
  createdAt: number;
}
