import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import Button from './button';

type ThumbnailType = 'file' | 'url';

export interface ThumbnailProps {
  file?: File;
  imgSrc?: string;
  handleRemoveThumbnail: (file: File) => void;
  thumbnailIndex: number;
  type: ThumbnailType;
}

const StyledThumbnail = styled.div<{ imgSrc: string }>(({ imgSrc }) => [
  `background-image: url(${imgSrc});`,
  tw`w-16 h-16 bg-center bg-no-repeat bg-cover rounded-lg`,
]);

const StyledVideo = tw.video`w-16 h-16 rounded-lg bg-text`;

const Container = tw.div`relative`;
const IconContainer = tw.div`absolute -top-2 -right-2 `;
const CloseIcon = tw(FontAwesomeIcon)`text-text-light`;

export const Thumbnail = ({ file, handleRemoveThumbnail, imgSrc, type }: ThumbnailProps) => {
  const [url, setUrl] = useState<string>(imgSrc || '');

  useEffect(() => {
    let objectUrl: string | undefined;

    if (type === 'file' && file) {
      objectUrl = URL.createObjectURL(file);
      setUrl(objectUrl);
    }

    return () => {
      if (type === 'file' && objectUrl) {
        // On unmount, revoke url to avoid memory leaks
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [file, type]);

  const renderThumbnail = () => {
    if (file) {
      if (file.type.split('/')[0] === 'video') {
        return <StyledVideo src={url} />;
      } else {
        return <StyledThumbnail imgSrc={url} />;
      }
    } else {
      return <StyledThumbnail imgSrc={url} />;
    }
  };

  return (
    <Container>
      {renderThumbnail()}
      {type === 'file' && file && (
        <IconContainer>
          <Button onClick={() => handleRemoveThumbnail(file)}>
            <CloseIcon icon={faTimesCircle} size="lg" />
          </Button>
        </IconContainer>
      )}
    </Container>
  );
};

export default Thumbnail;
