import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw, { css, styled } from 'twin.macro';

type ChatStackHeaderProps = {
  showBackButton: boolean;
  currentPageName?: string;
  onBackClicked: VoidFunction;
  hasSubmittedRequestsStyling?: boolean;
};

const BackButton = styled.button<{ isVisible: boolean }>(({ isVisible }) => [
  tw`flex-grow-0 flex-shrink-0 transition-opacity duration-300 bg-transparent border-none opacity-0 fill-current text-primary `,
  isVisible && tw`opacity-100`,
]);

const ChatStackHeaderWrapper = styled.div<{ hasSubmittedRequestsStyling?: boolean }>(
  ({ hasSubmittedRequestsStyling }) => [
    tw`flex items-center justify-center flex-shrink-0 px-4 text-center border-b border-b-border-grey h-14`,
    hasSubmittedRequestsStyling &&
      css`
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
      `,
  ]
);

const PageTitle = tw.div`self-center flex-1 text-sm`;
const RightPadding = tw.div`w-6`;
export const ChatStackHeader: React.FC<ChatStackHeaderProps> = ({
  showBackButton,
  onBackClicked,
  currentPageName,
  hasSubmittedRequestsStyling,
}) => {
  return (
    <ChatStackHeaderWrapper hasSubmittedRequestsStyling={hasSubmittedRequestsStyling}>
      <BackButton disabled={!showBackButton} isVisible={showBackButton} onClick={onBackClicked} aria-label={'Back'}>
        <FontAwesomeIcon icon={faArrowLeft} size="xl" />
      </BackButton>
      <PageTitle>{currentPageName}</PageTitle>
      <RightPadding />
    </ChatStackHeaderWrapper>
  );
};
