import { hexToRgb } from '@mezo/web/utils';
import tw, { css, styled } from 'twin.macro';

const Container = tw.div`w-full h-3 rounded-full bg-text-grey`;

const Filler = styled.div<{ percentage: number; color: string; lightenedColor: string }>(
  ({ percentage, color, lightenedColor }) => [
    tw`mr-auto`,
    css`
      height: 100%;
      width: ${percentage}%;
      background: linear-gradient(90deg, ${lightenedColor} 0%, ${color} 100%);
      border-radius: inherit;
      transition: width 1s ease-in-out;
    `,
  ]
);

export interface ProgressBarProps {
  color: string;
  percentage: number;
}
export const ProgressBar: React.FC<ProgressBarProps> = ({ color, percentage }) => {
  const rgb = hexToRgb(color);

  const lightenedColor = rgb ? `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, 0.25)` : color;

  return (
    <Container>
      <Filler percentage={percentage} color={color} lightenedColor={lightenedColor} />
    </Container>
  );
};

export default ProgressBar;
