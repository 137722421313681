export enum CHAT_INPUT_TYPES {
  multipleChoice,
}
export interface BotContext {
  sessionParams?: {
    residentId?: string;
    dialogId?: string;
    loopId?: string;
    firstName?: string;
    lastName?: string;
    unitId?: string;
    propertyId?: string;
    item?: string;
    location?: string;
    category?: string;
    webhookReplayRequested?: boolean;
    expectingMedia?: boolean;
    otherfeedback?: string;
    isAsset?: boolean;
    inputType?: CHAT_INPUT_TYPES;
    inputParameter?: string;
    'platform.collectPrimeSymptom'?: boolean;
  };
  dialogFlowState?: {
    currentPageName?: string;
    currentPageDisplayName?: string;
    currentFlowName?: string;
  };
}
