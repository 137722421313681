import { IsNumber, IsString } from 'class-validator';

export class RecentResidentProfileDto {
  @IsString()
  readonly residentId: string;
  @IsString()
  readonly unitId: string;
  @IsString()
  readonly sourceId: string;
  @IsString()
  readonly customerId: string;
  @IsString()
  readonly firstName: string;
  @IsString()
  readonly lastName: string;
  @IsString()
  readonly address1: string;
  @IsString()
  readonly address2: string;
  @IsString()
  readonly city: string;
  @IsString()
  readonly state: string;
  @IsString()
  readonly zip: string;
  @IsString()
  readonly country: string;
  @IsNumber()
  readonly timestamp: number;
}
