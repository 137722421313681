import { ApiClient } from '@mezo/web/api-client';
import { i18n } from '@mezo/web/i18n';
import { PayloadAction } from '@reduxjs/toolkit';
import { ChatEvent, MessageDto } from '@reshub/dtos';
import { CustomerId } from '@reshub/model';
import { toast } from 'react-toastify';
import { all, call, put, select } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { selectChatCustomerId, selectDialogId, selectLoopId, selectResidentId } from '../selectors/chat.selectors';
import { chatSlice } from '../slices';
const {
  actions: {
    chatApiCallStart,
    chatApiCallSuccess,
    addMessages,
    setDone,
    clearMessages,
    chatApiCallError,
    setChatCustomerId,
    setDialog,
    setResidentId,
    setHasSystemPush,
  },
} = chatSlice;

const ENDPOINTS = {
  customer: (customerId: string) => `/customers/${customerId}`,
  media: '/bot/media',
  message: '/bot/message',
  session: '/bot/session',
  systemMessage: '/messages/system',
};

function ensureFilesHaveUniqueNames(files: File[]): File[] {
  const result: File[] = [];

  const found = new Map<string, number>();
  for (const file of files) {
    if (!found.has(file.name)) {
      found.set(file.name, 0);
      result.push(file);
    } else {
      const previousCount = found.get(file.name) ?? 0;

      result.push(new File([file], `${previousCount}-${file.name}`, { type: file.type }));

      found.set(file.name, previousCount + 1);
    }
  }

  return result;
}

const sagaSlice = createSliceSaga({
  name: 'chat',
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    startSession: {
      *fn(action: PayloadAction<{ residentId: string; resetSession: boolean; customerId: CustomerId }>) {
        yield put(chatApiCallStart());
        yield put(clearMessages());
        yield put(setResidentId(''));
        yield put(setDialog({ dialogId: '', loopId: '' }));
        yield put(setHasSystemPush(false));
        try {
          const params = {
            residentId: action.payload.residentId,
            resetSession: action.payload.resetSession,
            customerId: action.payload.customerId,
          };
          const response: any = yield call(ApiClient.CHAT_API.utility.post, ENDPOINTS.session, params);
          const reply: MessageDto = response.data;
          const dialogId: string = yield select(selectDialogId);
          const loopId: string = yield select(selectLoopId);
          const residentId: string = yield select(selectResidentId);
          const customerId: string = yield select(selectChatCustomerId);
          if (dialogId !== reply.dialogId || loopId !== reply.loopId) {
            yield put(setDialog({ dialogId: reply.dialogId, loopId: reply.loopId }));
          }
          if (residentId !== reply.residentId) {
            yield put(setResidentId(reply.residentId ?? ''));
          }
          if (customerId !== reply.customerId) {
            yield put(setChatCustomerId(reply.customerId ?? ''));
          }
          yield put(addMessages(reply));
          yield put(chatApiCallSuccess());
        } catch (err) {
          yield put(chatApiCallError((err as Error).toString()));
          yield call(toast as any, i18n.t('error.chat'));
        }
      },
      sagaType: SagaType.TakeLatest,
    },
    sendMessage: {
      *fn(
        action: PayloadAction<{
          residentId: string;
          message: string;
          files?: File[];
          customerId: CustomerId;
          event?: ChatEvent;
        }>
      ) {
        yield put(chatApiCallStart());
        try {
          const currentLoopId: string = yield select(selectLoopId);
          const currentDialogId: string = yield select(selectDialogId);
          const { residentId, message, files, customerId, event } = action.payload;
          const timestamp = Date.now();
          let messageDto: MessageDto = {
            timestamp,
            dialogId: currentDialogId,
            loopId: currentLoopId,
            messages: message ? [message] : [],
            mediaFileInfos: [],
            residentId,
            customerId,
            senderId: 'sender',
            chatEvents: event ? [event] : undefined,
          };
          let mediaResponse = null;
          if (files && files.length > 0) {
            const filesToSend = ensureFilesHaveUniqueNames(files);
            mediaResponse = yield call(ApiClient.CHAT_API.utility.post, ENDPOINTS.media, {
              residentId,
              files: filesToSend.map((f) => ({
                name: f.name,
                type: f.type,
              })),
            });
          }
          if (mediaResponse?.data) {
            const mappedMediaFiles = (mediaResponse as any).data;
            const mediaFileInfos = [];
            const calls = [];

            for (const file of files ?? []) {
              const mediaFile = mappedMediaFiles.files[file.name];
              calls.push(call(fetch, mediaFile.writeUrl, { method: 'PUT', body: file }));
              mediaFileInfos.push(mediaFile);
            }
            try {
              yield all(calls);
            } catch (error) {
              if (!(window as any).Cypress) {
                throw error;
              }
            }
            messageDto = {
              ...messageDto,
              mediaFileInfos,
            };
          }
          yield put(addMessages(messageDto));
          const response: any = yield call(ApiClient.CHAT_API.utility.post, ENDPOINTS.message, messageDto);
          const reply: MessageDto = response.data;
          const dialogId: string = yield select(selectDialogId);
          const loopId: string = yield select(selectLoopId);

          if (dialogId !== reply.dialogId || loopId !== reply.loopId) {
            yield put(setDialog({ dialogId: reply.dialogId, loopId: reply.loopId }));
          }
          if (residentId !== reply.residentId) {
            yield put(setResidentId(reply.residentId ?? ''));
          }
          yield put(addMessages(reply));
          yield put(chatApiCallSuccess());
        } catch (err) {
          yield put(chatApiCallError((err as Error).toString()));
          yield call(toast as any, i18n.t('error.chat'));
        }
      },
      sagaType: SagaType.TakeEvery,
    },
    fetchSystemMessage: {
      *fn(action: PayloadAction<string>) {
        // removed the chatApiCalls because they show the loading indicator and we're running this behind the scenes
        try {
          const params = { dialogId: action.payload };
          const response: any = yield call(ApiClient.CHAT_API.utility.post, ENDPOINTS.systemMessage, params);
          const reply: MessageDto = response.data;
          if ((reply.messages ?? []).length > 0) {
            yield put(addMessages(reply));
            yield put(setHasSystemPush(true));
          }
        } catch (err) {
          yield put(chatApiCallError((err as Error).toString()));
          yield put(setDone());
        }
      },
      sagaType: SagaType.TakeLatest,
    },
  },
});

export const chatSaga = sagaSlice.saga;
export const { startSession, sendMessage, fetchSystemMessage } = sagaSlice.actions;
