import { AddressType, GeocodingAddressComponentType } from '@googlemaps/google-maps-services-js';

interface LatLngLiteral {
  lat: number;
  lng: number;
}

interface AddressComponent {
  types: Array<AddressType | GeocodingAddressComponentType>;
  shortName: string;
  longName: string;
}

export interface Location {
  latitude: number;
  longitude: number;

  google: {
    placeId: string;
    types: string[];
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
      location_type?: 'ROOFTOP' | 'RANGE_INTERPOLATED' | 'GEOMETRIC_CENTER' | 'APPROXIMATE';

      viewport: {
        northeast: LatLngLiteral;
        southwest: LatLngLiteral;
      };

      bounds?: {
        northeast: LatLngLiteral;
        southwest: LatLngLiteral;
      };
    };

    addressComponents: AddressComponent[];
  };
}

const AddressProps: (keyof Address)[] = ['address1', 'address2', 'city', 'state', 'zip', 'country'];

export interface IAddress {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country?: string;
}

export class Address implements IAddress {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country?: string;

  geocodeAddressString?: string;
  geocodingFailed?: boolean;
  isReset?: boolean;

  manualOverride?: {
    createdBy: string;
    createdAt: number;
  };

  location?: Location;

  constructor(params: {
    address1: string;
    address2?: string | undefined;
    city: string;
    state: string;
    zip: string;
    country?: string | undefined;
    location?: Location | undefined;
  }) {
    this.address1 = params.address1;
    this.address2 = params.address2 === null ? undefined : params.address2;
    this.city = params.city;
    this.state = params.state;
    this.zip = params.zip;
    this.country = params.country;

    this.location = params.location;
  }

  public isEqual(other: Address | undefined) {
    if (other == null) {
      return false;
    }

    return AddressProps.every((key) => this[key] !== other[key]);
  }
}

export function addressFromStreetNumberAndRoute(
  streetNumber: string | undefined,
  route: string | undefined
): string | undefined {
  if (!streetNumber || !route) {
    return;
  }
  return `${streetNumber} ${route}`;
}

export function formatAddressAsString(address: IAddress, includeAddress2: boolean) {
  const address2 = includeAddress2 && address.address2 ? `, ${address.address2},` : ',';
  return `${address.address1}${address2} ${address.city}, ${address.state} ${address.zip}`;
}
