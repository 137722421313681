import { ServiceRequestSearchResultUnitResponseDto } from '@reshub/dtos';
import tw, { css, styled } from 'twin.macro';

const AddressStyles = tw.address`flex flex-wrap gap-1`;
const AddressText = styled.span(() => [
  tw`flex gap-1 text-sm not-italic font-normal text-text`,
  css`
    & + & {
      &:before {
        content: '·';
      }
    }
  `,
]);

export const Address: React.FC<AddressProps> = ({ unit }) => (
  <AddressStyles>
    {unit.propertyName && <AddressText>{unit.propertyName}</AddressText>}
    {unit.address.address1 && <AddressText>{unit.address?.address1}</AddressText>}
    {unit.address.address2 && <AddressText>{unit.address?.address2}</AddressText>}
  </AddressStyles>
);

interface AddressProps {
  unit: ServiceRequestSearchResultUnitResponseDto;
}
