import { ComputedConfiguration } from '@mezo/server/configuration';
import {
  getBrandingConfigForCustomer,
  getBrandingConfigForCustomerForChat,
  getBrandingConfigForUnit,
  getBrandingConfigForUnitForChat,
  getBrandingConfiguration,
  getBrandingConfigurationForChat,
} from '@mezo/web/queries';
import { minutesToMilliseconds } from 'date-fns';
import { useQuery, UseQueryResult } from 'react-query';

export interface UseBrandingConfigurationProps {
  unitId?: string | undefined;
  customerId?: string | undefined;
  configurationId?: string | undefined;
}

export interface BrandingConfiguration {
  primaryColor?: string;
  name?: string;
  logo?: string;
  hideChatHeader?: boolean;
}

export const useBrandingConfigurationForChat = ({
  unitId,
  customerId,
  configurationId,
}: UseBrandingConfigurationProps): UseQueryResult<BrandingConfiguration | undefined> => {
  let queryKey: string[] = [];
  let queryFunc: () => Promise<ComputedConfiguration | undefined> = async () => undefined;

  if (configurationId) {
    queryKey = ['configurationBrandingForChat', configurationId];
    queryFunc = () => getBrandingConfigurationForChat(configurationId);
  } else if (unitId) {
    queryKey = ['unitBrandingConfigForChat', unitId];
    queryFunc = () => getBrandingConfigForUnitForChat(unitId);
  } else if (customerId) {
    queryKey = ['customerBrandingConfigForChat', customerId];
    queryFunc = () => getBrandingConfigForCustomerForChat(customerId);
  }

  return useQuery(queryKey, queryFunc, {
    enabled: !!configurationId || !!unitId || !!customerId,
    staleTime: minutesToMilliseconds(10),
  });
};

export const useBrandingConfiguration = ({
  unitId,
  customerId,
  configurationId,
}: UseBrandingConfigurationProps): UseQueryResult<BrandingConfiguration | undefined> => {
  let queryKey: string[] = [];
  let queryFunc: () => Promise<ComputedConfiguration | undefined> = async () => undefined;

  if (configurationId) {
    queryKey = ['configurationBranding', configurationId];
    queryFunc = () => getBrandingConfiguration(configurationId);
  } else if (unitId) {
    queryKey = ['unitBrandingConfig', unitId];
    queryFunc = () => getBrandingConfigForUnit(unitId);
  } else if (customerId) {
    queryKey = ['customerBrandingConfig', customerId];
    queryFunc = () => getBrandingConfigForCustomer(customerId);
  }

  return useQuery(queryKey, queryFunc, {
    enabled: !!configurationId || !!unitId || !!customerId,
    staleTime: minutesToMilliseconds(10),
  });
};
