import { Locale } from '@mezo/shared/utils';
import { Address } from './address.model';
import { CustomerId, CustomerSourceSystem } from './customer.model';
import { DeliveryIntegrationTypeOptions } from './delivery-integration-type';
import { Diagnosis } from './diagnosis.model';
import { MediaFileInfo } from './media-fileinfo.model';
import { MessageMedium } from './message-transaction.model';
import { ServiceRequestCustomerDataV1, ServiceRequestStatusV1, ServiceRequestV1 } from './service-request.v1.model';
import type { CategoryKVP, ComponentKVP, ItemKVP, LocationKVP, SymptomKVP } from './taxonomy.model';

export const DEFAULT_SERVICE_REQUEST_SOURCE_STATUS = 'SUBMITTED';

interface KeyValuePair {
  id: string;
  label: string;
}

export class CustomerItemCategoryMap {
  id: string;
  label: string;
  customerId: string;
  customerLabel: string;
  categoryId: string;
  categoryLabel: string;
  customerCategoryId: string;
  customerCategoryLabel: string;
  validLocations: KeyValuePair[];
  createdAt: number;
  updatedAt: number;
}

// this function is exported and used in the `mezo-functions` project. can't move this over to the ServiceRequestUtils since its used in that other project
export function isNewServiceRequest(
  serviceRequest: ServiceRequest | ServiceRequestV1
): serviceRequest is ServiceRequest {
  return serviceRequest && Object.prototype.hasOwnProperty.call(serviceRequest, 'taxonomyMap');
}

export enum ServiceRequestStatus {
  PROCESSING = 'processing',
  UNDELIVERABLE = 'undeliverable',
  PENDING = 'pending',
  DELIVERED = 'delivered',
  DELIVERING = 'delivering',
  ARCHIVED = 'archived',
}

export enum ServiceRequestArchiveReason {
  DUPLICATE = 'Duplicate service request',
  FAILED = 'Failed resident validation',
  MISSING = 'No problem statement',
  TEST = 'Test service request',
  OTHER = 'Other',
}

export class ServiceRequestReportedBy {
  id: string;
  sourceId: string;
  isGuest: boolean;
  firstName: string;
  lastName: string;
  phoneNumber: string | undefined;
  locale: Locale;
  guestAddress?: Address;
  email?: string;
}

export class ServiceRequestUnit {
  id: string;
  sourceId: string;
  sourceAddress?: string;
  propertyId: string;
  propertyName: string;
  address: Address;
  customerId: CustomerId;
  customerName: string;
  customerIconUrl: string;
  timezone?: {
    dstOffset: number;
    rawOffset: number;
    status: string;
    timeZoneId: string;
    timeZoneName: string;
  };
}

export interface Skillset {
  id: string;
  label: string;
}

//NOTE: DialogFlow lowercases the desciptions
export interface ServiceRequestDetails {
  itemdescription: string;
  issuedescription: string;
  locationdescription: string;
  problemstatement: string | null;
  description: string;
  descriptionHtml: string;
  diagnosis?: Diagnosis;
  skillsets?: Skillset[];
  availabilityWindows?: { startTimeUtc: string; endTimeUtc: string }[];
}

export class TaxonomyMap {
  mezoItem?: ItemKVP;
  mezoComponent?: ComponentKVP;
  mezoSymptom?: SymptomKVP;
  mezoLocation?: LocationKVP;
  mezoCategory?: CategoryKVP;

  customerItem?: ItemKVP; //For customers like Mynd this maps to their subCategory
  customerComponent?: ComponentKVP; //For customers like Mynd this is N/A
  customerSymptom?: SymptomKVP; //For customers like Mynd this maps to their Issue
  customerLocation?: LocationKVP;
  customerCategory?: CategoryKVP;
}

export type TaxonomyMapKey = keyof TaxonomyMap;

export enum ServiceRequestPriority {
  NORMAL = 'NORMAL',
  EMERGENCY = 'EMERGENCY',
}

export class ServiceRequestFeedback {
  rating?: number;
  text?: string;
}

export class ServiceRequest {
  id: string;
  createdAt: number;
  updatedAt: number;
  updatedBy: string;
  deliveredAt?: number;
  deliveredBy: string;
  deliveryAttemptCount?: number;
  isAbandoned?: boolean;
  edited: boolean;
  status: ServiceRequestStatus;
  priority: ServiceRequestPriority;
  tripless: boolean;
  medium: MessageMedium;
  reportedBy: ServiceRequestReportedBy;
  dialogId?: string;
  loopId?: string;
  assetLookupIds?: string[];
  unit: ServiceRequestUnit;
  mediaFileInfos: MediaFileInfo[];
  details: ServiceRequestDetails;
  taxonomyMap?: TaxonomyMap;
  originalTaxonomyMap?: TaxonomyMap;
  sourceId?: string;
  source: {
    id: string;
    url?: string;
    status: string;
    lastUpdated: number;
    customerId: CustomerId;
    propertyId: string;
    sourceSystem: CustomerSourceSystem;
  };
  permissionToEnter?: boolean;
  askPermissionToEnter: boolean;
  deliveryIntegrationTypeOverride?: DeliveryIntegrationTypeOptions;
  capabilities?: {
    view: string;
  };
  archiveReason?: ServiceRequestArchiveReason;
  archiveReasonDescription?: string;
  isNonMaintenance: boolean;
  config?: {
    integration: Record<string, any>;
    branding: Record<string, any>;
    operation: Record<string, any>;
  };
  feedback?: ServiceRequestFeedback;
  entryNotes?: string;
}

export class ServiceRequestTranslation {
  id: string;
  locale: string;
  [key: string]: string;
}

export interface CompoundMap {
  id: string;
  item: string;
  itemId: string;
  symptom: string;
  symptomId: string;
  component: string;
  componentId: string;
  location: string;
  locationId: string;
  category: string;
  categoryLabel: string;
  categoryId: string;
  customerItem: string;
  customerItemId: string;
  customerSymptom: string;
  customerSymptomId: string;
  customerComponent: string;
  customerComponentId: string;
  customerLocation: string;
  customerLocationId: string;
  customerCategory: string;
  customerCategoryId: string;
  validLocations?: KeyValuePair[];
}

export function transformToNewServiceRequest(serviceRequest: ServiceRequest | ServiceRequestV1): ServiceRequest {
  if (isNewServiceRequest(serviceRequest)) {
    return serviceRequest;
  }

  const details: ServiceRequestDetails = {
    itemdescription: serviceRequest.details.itemdescription,
    issuedescription: serviceRequest.details.issuedescription,
    locationdescription: serviceRequest.details.locationdescription,
    problemstatement: serviceRequest.details.problemstatement,
    description: serviceRequest.details.description,
    descriptionHtml: serviceRequest.details.descriptionHtml,
    diagnosis: serviceRequest.details.diagnosis,
    availabilityWindows: [],
  };

  const customerData = serviceRequest.customerData;

  const taxonomyMap = transformCustomerDataToTaxonomyMap(customerData);
  const originalTaxonomyMap = taxonomyMap;

  let srStatus = ServiceRequestStatus.PENDING;

  if (serviceRequest.status === ServiceRequestStatusV1.DELIVERED) {
    srStatus = ServiceRequestStatus.DELIVERED;
  }

  const unit = {
    sourceId: '',
    ...serviceRequest.unit,
    sourceAddress: undefined,
    timezone: undefined,
  };

  const newCustomerServiceRequest: ServiceRequest = {
    id: serviceRequest.id,
    createdAt: serviceRequest.createdAt,
    updatedAt: serviceRequest.updatedAt,
    updatedBy: serviceRequest.updatedBy,
    deliveredAt: serviceRequest.deliveredAt,
    deliveredBy: serviceRequest.deliveredBy,
    isAbandoned: serviceRequest.isAbandoned,
    edited: serviceRequest.edited,
    status: srStatus,
    priority: ServiceRequestPriority.NORMAL,
    tripless: serviceRequest.tripless,
    medium: serviceRequest.medium,
    reportedBy: { ...serviceRequest.reportedBy, locale: Locale.EN_US, isGuest: false },
    loopId: serviceRequest.loopId,
    dialogId: serviceRequest.dialogId,
    assetLookupIds: serviceRequest.assetLookupIds,
    unit: unit,
    mediaFileInfos: serviceRequest.mediaFileInfos || [],
    details: details,
    taxonomyMap: taxonomyMap,
    originalTaxonomyMap: originalTaxonomyMap,
    sourceId: serviceRequest.sourceId,
    source: {
      id: serviceRequest.sourceId,
      status: DEFAULT_SERVICE_REQUEST_SOURCE_STATUS,
      lastUpdated: Date.now(),
      customerId: serviceRequest.unit.customerId,
      propertyId: serviceRequest.unit.propertyId,
      sourceSystem: CustomerSourceSystem.DEFAULT,
    },
    permissionToEnter: serviceRequest.permissionToEnter,
    askPermissionToEnter: serviceRequest.askPermissionToEnter,
    isNonMaintenance: false,
    entryNotes: undefined,
  };

  return newCustomerServiceRequest;
}

function transformCustomerDataToTaxonomyMap(customerData: ServiceRequestCustomerDataV1): TaxonomyMap | undefined {
  if (!customerData) return;

  const mezoItem = {
    id: customerData.mezoItemId,
    label: customerData.mezoItem,
  };
  const mezoComponent = {
    id: customerData.mezoComponentId,
    label: customerData.mezoComponent,
  };
  const mezoSymptom = {
    id: customerData.mezoSymptomId,
    label: customerData.mezoSymptom,
  };
  const mezoLocation = {
    id: customerData.mezoLocationId,
    label: customerData.mezoLocation,
  };
  const mezoCategory = {
    id: customerData.mezoCategoryId,
    label: customerData.mezoCategory,
  };
  const customerItem = {
    id: customerData.myndSubCategoryId,
    label: customerData.myndSubCategory,
  };
  const customerComponent = {
    id: '',
    label: '',
  };
  const customerSymptom = {
    id: customerData.myndIssueId,
    label: customerData.myndIssue,
  };
  const customerLocation = {
    id: customerData.myndLocation,
    label: customerData.myndLocation,
  };
  const customerCategory = {
    id: customerData.myndCategoryId,
    label: customerData.myndCategory,
  };

  const taxonomyMap = {
    mezoItem: mezoItem,
    mezoComponent: mezoComponent,
    mezoSymptom: mezoSymptom,
    mezoLocation: mezoLocation,
    mezoCategory: mezoCategory,
    customerItem: customerItem,
    customerComponent: customerComponent,
    customerSymptom: customerSymptom,
    customerLocation: customerLocation,
    customerCategory: customerCategory,
  };

  return taxonomyMap;
}
