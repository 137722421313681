import { ApiClient } from '@mezo/web/api-client';
import { DiagnosisDto, ItemResponseDto, KeyValuePairDto } from '@reshub/dtos';
import { useQuery } from 'react-query';

export const useGetDiagnoses = () => {
  return useQuery(['diagnoses'], async () => {
    const { data } = await ApiClient.NON_CHAT_API.utility.get<DiagnosisDto[]>('/taxonomies/diagnoses');
    return data;
  });
};

type DiagnosisDependenciesPayload = {
  resolutionTypes: KeyValuePairDto[];
  skillLevels: KeyValuePairDto[];
};

export const useGetDiagnosisDependencies = () => {
  return useQuery(['diagnosisDependencies'], async () => {
    const payload = await ApiClient.NON_CHAT_API.utility.get<DiagnosisDependenciesPayload>(
      '/taxonomies/diagnosis-dependencies'
    );
    return payload.data;
  });
};

type GetTaxonomiesPayload = {
  components: KeyValuePairDto[];
  items: ItemResponseDto[];
  locations: KeyValuePairDto[];
  symptoms: KeyValuePairDto[];
};

export const useGetTaxonomies = () => {
  return useQuery(['taxonomies'], async () => {
    const { data } = await ApiClient.NON_CHAT_API.utility.get<GetTaxonomiesPayload>('/taxonomies');
    return data;
  });
};
