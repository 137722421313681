import { Locale } from '@mezo/shared/utils';

export enum UserSessionStatusType {
  OPEN = 'open',
  COMPLETED = 'completed',
  ABANDONED = 'abandoned',
}

export enum UserSessionStatusReason {
  TIMEOUT = 'timeout',
  USER_EXIT = 'user exit',
  ERROR = 'error',
}

export interface UserSession {
  id: string;

  createdAt: number;
  updatedAt?: number;

  customerId?: string;
  residentId?: string;
  unitId?: string;

  user: {
    locale: Locale;
    userAgent: string;
    isGuest: boolean;
  };

  status?: {
    type: UserSessionStatusType;
    reason?: UserSessionStatusReason;
  };

  flow?: string;
  node?: string;

  dialogId?: string;
  loopId?: string;

  serviceRequests?: {
    loopId: string;
    id: string;
  }[];

  taxonomies?: {
    loopId: string;
    itemId?: string;
    symptomId?: string;
    componentId?: string;
    locationId?: string;
  }[];

  isEmergency?: boolean;

  error?: {
    type?: string;
  };
}
