import { Address } from './address.model';
import { CustomerId } from './customer.model';
import { ServiceRequest } from './service-request.model';
import { UnitGroupId } from './unit-group.model';

export class Unit {
  id: string;
  sourceId: string;
  customerId: CustomerId;
  customerName: string;
  customerIconUrl: string;
  propertyId: string;
  propertyName: string;
  address: Address;

  // TODO Make me readonly; this is a serialized version of the source system's address
  sourceAddress: string;

  isInvalid?: boolean;
  isGuest?: boolean;
  serviceRequests?: ServiceRequest[];
  yearBuilt: string;
  type: string;
  squareFootage: number;
  bedroomsNumber: number;
  fullBathroomsNumber: number;
  halfBathroomsNumber: number;
  unitGroupIds?: UnitGroupId[];
  floor: number;
  floors: number;
  createdAt: number;
  updatedAt: number;
  jobId?: string;
  timezone?: {
    /**
     * the offset for daylight-savings time in seconds.
     * This will be zero if the time zone is not in Daylight Savings Time during the specified `timestamp`.
     */
    dstOffset: number;
    /** the offset from UTC (in seconds) for the given location. This does not take into effect daylight savings. */
    rawOffset: number;
    status: string;
    /**
     * a string containing the ID of the time zone, such as "America/Los_Angeles" or "Australia/Sydney".
     * These IDs are defined by [Unicode Common Locale Data Repository (CLDR) project](http://cldr.unicode.org/),
     * and currently available in file [timezone.xml](http://unicode.org/repos/cldr/trunk/common/bcp47/timezone.xml).
     * When a timezone has several IDs, the canonical one is returned. In timezone.xml, this is the first alias of each timezone.
     * For example, "Asia/Calcutta" is returned, not "Asia/Kolkata".
     */
    timeZoneId: string;
    /**
     * a string containing the long form name of the time zone.
     * This field will be localized if the `language` parameter is set.
     * eg. "Pacific Daylight Time" or "Australian Eastern Daylight Time"
     */
    timeZoneName: string;
  };

  // ideally this would be a getter on the Unit model itself,
  // but there are firebase limitations preventing that
  static numToilets(unit: Unit | undefined) {
    if (!unit) {
      return 0;
    }

    return Math.ceil(unit?.halfBathroomsNumber) || 0 + Math.ceil(unit?.fullBathroomsNumber) || 0;
  }
}
