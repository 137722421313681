import { useCallback } from 'react';
import ReactGA from 'react-ga4';

export type GoogleAnalyticsUtils = {
  initialize: (trackingId: string) => void;
  setUser: (userId?: string) => void;
  sendEvent: (eventName: string, payload?: any) => void;
};

export function useGoogleAnalytics(): GoogleAnalyticsUtils {
  const initialize = useCallback((trackingId: string) => {
    if (trackingId) {
      ReactGA.initialize(trackingId, { testMode: process.env['NODE_ENV'] === 'test' });
    }
  }, []);

  const sendEvent = useCallback((eventName: string, payload?: any) => {
    if (!ReactGA.isInitialized || !payload) {
      return;
    }
    ReactGA.event(eventName, payload);
  }, []);

  const setUser = useCallback((userId?: string) => {
    if (!ReactGA.isInitialized || !userId) {
      return;
    }
    ReactGA.set({ user_id: userId });
  }, []);

  return {
    initialize,
    setUser,
    sendEvent,
  };
}
