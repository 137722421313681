import parse from 'html-react-parser';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import ButtonV2 from '../button.v2';
import { StarRating } from '../star-rating';
import { ResizeableTextArea } from '../textarea';

type FeedbackProps = {
  showRatingInput: boolean;
  showFeedbackInput: boolean;
  customExitText?: string;
  onSubmitNewIssue: VoidFunction;
  onSubmitRating: (stars: number) => void;
  onSubmitFeedback: (text: string) => void;
  onNavigateToSubmittedRequests: VoidFunction;
  showResidentSubmittedRequestsLink: boolean;
  isRequestClosed: boolean;
  isGuestResident: boolean;
};

const Container = tw.div`flex flex-col w-full h-full overflow-y-auto bg-background-light`;
const Header = tw.h1`mb-4 text-2xl font-medium`;
const LinkButton = tw.button`underline text-primary`;
const Text = tw.div`pb-2 mb-5`;

const TopContainer = tw.div`p-4`;

const LinkButtonContainer = tw.div`flex flex-col justify-between gap-6 p-6`;

const FeedbackButtonWrapper = tw.div`mb-4`;
const FeedbackInputs = tw.div`p-4 bg-light-grey`;
const FeedbackLabel = tw.label`block text-base font-bold`;
const FeedbackTextareaWrapper = tw.div`flex px-3 my-4 bg-white rounded-lg border-light-grey`;
const StarRatingWrapper = tw.div`my-4`;
const ExitText = tw.div`my-4`;

export const ChatFeedback = ({
  showFeedbackInput,
  showRatingInput,
  customExitText,
  onSubmitFeedback,
  onSubmitRating,
  onSubmitNewIssue,
  onNavigateToSubmittedRequests,
  showResidentSubmittedRequestsLink,
  isRequestClosed,
  isGuestResident,
}: FeedbackProps) => {
  const { t } = useTranslation();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [textFeedback, setTextFeedback] = useState<string>('');
  const [submittedRating, setSubmittedRating] = useState<number | undefined>();

  const handleTextChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextFeedback(event.currentTarget.value);
  }, []);

  const handleSubmitForm = useCallback(() => {
    onSubmitFeedback(textFeedback);
    setIsFormSubmitted(true);
  }, [textFeedback, onSubmitFeedback]);

  const handleRatingClicked = useCallback(
    (position: number) => {
      setSubmittedRating(position);
      onSubmitRating(position);
    },
    [onSubmitRating]
  );

  const headerText = useMemo(() => {
    let text = t('chat.feedback.header');
    if (isGuestResident) {
      text = t('chat.feedback.headerGuest');
    }
    if (isRequestClosed) {
      text = t('chat.feedback.headerClosed');
    }
    return text;
  }, [isGuestResident, isRequestClosed, t]);

  return (
    <Container>
      <TopContainer>
        <Header>{headerText}</Header>
        {isGuestResident && <Text>{t('chat.feedback.guestMessage')}</Text>}
        {!isRequestClosed && customExitText && <Text>{parse(customExitText)}</Text>}
      </TopContainer>
      {(showFeedbackInput || showRatingInput) && (
        <FeedbackInputs>
          {isFormSubmitted ? (
            <ExitText>{t('chat.feedback.thanksForFeedback')}</ExitText>
          ) : (
            <>
              {showRatingInput && (
                <>
                  <FeedbackLabel>
                    {isRequestClosed ? t('chat.feedback.howEasy') : t('chat.feedback.howEasyRequest')}
                  </FeedbackLabel>
                  <StarRatingWrapper>
                    <StarRating
                      isEditable={true}
                      rating={submittedRating}
                      onRatingClicked={handleRatingClicked}
                      showRatingLabelCheck={!!submittedRating}
                    />
                  </StarRatingWrapper>
                </>
              )}
              {showFeedbackInput && (
                <>
                  <FeedbackLabel htmlFor="feedbackMessage">{t('chat.feedback.whatElse')}</FeedbackLabel>
                  <FeedbackTextareaWrapper>
                    <ResizeableTextArea
                      id="feedbackMessage"
                      name="feedbackMessage"
                      placeholder=""
                      minRows={1}
                      maxRows={3}
                      onChange={handleTextChange}
                      value={textFeedback}
                    />
                  </FeedbackTextareaWrapper>
                  <FeedbackButtonWrapper>
                    <ButtonV2 primary={true} disabled={!textFeedback} onClick={handleSubmitForm}>
                      {t('chat.feedback.submitFeedback')}
                    </ButtonV2>
                  </FeedbackButtonWrapper>
                </>
              )}
            </>
          )}
        </FeedbackInputs>
      )}
      <LinkButtonContainer>
        {showResidentSubmittedRequestsLink && (
          <LinkButton onClick={onNavigateToSubmittedRequests}>{t('chat.feedback.viewSubmittedRequests')}</LinkButton>
        )}
        <LinkButton onClick={onSubmitNewIssue}>{t('chat.feedback.submitNewIssue')}</LinkButton>
      </LinkButtonContainer>
    </Container>
  );
};
