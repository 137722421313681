export class ImageDecode {
  /** Image description as submitted */
  description: string;
  /** Size of the image in bytes */
  size: number;
  /** URI of the image to be used in future calls */
  uri: string;
  /** Brand ID of the product */
  brand?: number;
  /** Brand name of the product */
  brandName?: string;
  /** Model number if readable, otherwise undefined */
  modelNumber?: string;
  /** Serial number if readable, otherwise undefined */
  serialNumber?: string;
  /** Year of manufacture */
  yearManufactured?: number;
  /** Month of manufacture */
  monthManufactured?: number;
  /** Product ID of the product */
  productId: number;
  /** Product type name of the product */
  productName?: string;
  /** Sub-product type ID of the product */
  subProductId?: number;
  /** Sub-product type name of the product */
  subProductName?: string;
}

export enum SerialNumberValid {
  INVALID = 0,
  VALID = 1,
  UNKNOWN_FORMAT = 2,
  VALID_UNKNOWN_DATE = 3,
}

export enum RecallCode {
  NO_RECALLS = 0,
  ONE_OR_MORE_POSSIBLE = 1,
  RECALLS_APPLICABLE = 2,
}

export class RecallInformation {
  /** Recall information code:
   * (0) No recalls returned - there are no recalls for this product
   * (1) One or more recalls were returned as possible matches for the submitted product. Brand, model number, and/or serial number was not included in the request or are not defined in the CPSC recall, therefore the system could not confirm the recall as a positive match for the product. The returned recall(s) should be manually reviewed to determine if they apply to the product.
   * (2) The returned recall(s) applies to the submitted product. */
  recallCode: RecallCode;
  recalls?: {
    /** Date recall added to Source7 database */
    dateAdded: string;
    /** CPSC Recall Number */
    recallNumber: string;
    /** Hazard that caused the recall */
    hazard: string;
    /** The specified fix for the recall */
    fix: string;
    /** Short name hazard that caused the recall */
    dueTo: string;
    /** CPSC URL For the recall */
    recallUrl: string;
    /**  Date of first sale of recalled product */
    dateStartedBeingSold: string;
    /** Date of last sale of recalled product */
    dateStoppedBeingSold: string;
    /** Date product was recalled */
    dateRecalled: string;
  }[];
}

export class AgeLifeDataValidation {
  // (0) Invalid model number (1) Valid model number (2) Unknown model number format
  modelNumberValid: number;
  // (0) Invalid Serial Number (1) Valid Serial Number (2) Unknown serial number format (3) Valid Serial Number - Unable to decode date of manufacture.
  serialNumberValid: number;
  /** Expected life of the product in years. */
  expectedLife: number;
  dateManufactured?: AssetDateInfo[];
}

export class AssetDateInfo {
  /** Year Manufactured */
  year: number;
  /** Month Manufactured */
  month: number;
  /** Age of product in years */
  age: number;
  /** Estimated remaining life of the product in years */
  remainingLife?: number;
}

export class AssetLookup {
  id: string;
  applianceUid?: string;
  dialogId: string;
  loopId?: string;
  itemId: string;
  assetInfoId: string;
  unitId: string;
  propertyId: string;
  imageUrl: string;
  imageDecode?: ImageDecode;
  recallInformation?: RecallInformation;
  ageLifeDataValidation?: AgeLifeDataValidation;
}
