import { ApiClient, AppConfig } from '@mezo/web/api-client';
import { getApp, initializeApp } from 'firebase/app';

const appConfig: AppConfig = ApiClient.APP_CONFIG;

// Firebase Config
const config = {
  apiKey: appConfig.WEB_FIREBASE_API_KEY,
  authDomain: appConfig.WEB_FIREBASE_AUTH_DOMAIN,
  projectId: appConfig.WEB_FIREBASE_PROJECT_ID,
  storageBucket: appConfig.WEB_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: appConfig.WEB_FIREBASE_MESSAGING_SENDER_ID,
  appId: appConfig.WEB_FIREBASE_APP_ID,
  measurementId: appConfig.WEB_FIREBASE_MEASUREMENT_ID,
  databaseURL: '',
};

initializeApp(config);

export const app = getApp();
