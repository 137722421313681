import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw, { styled, theme } from 'twin.macro';

type ChatScreenHeaderProps = {
  onClose?: VoidFunction;
  children: React.ReactNode;
};

const ButtonWrapper = tw.button`absolute border-none h-[44px] w-[44px] bg-none top-2 right-2`;

const HeaderWrapper = styled.div<{ showClose: boolean }>(({ showClose }) => [
  tw`relative flex items-start justify-between max-w-full p-4 bg-white `,
  showClose && tw`pr-14`,
]);

export const ChatScreenHeader: React.FC<ChatScreenHeaderProps> = ({ onClose, children }) => {
  return (
    <HeaderWrapper showClose={!!onClose}>
      {children}
      {!!onClose && (
        <ButtonWrapper onClick={onClose} aria-label="close">
          <FontAwesomeIcon icon={faXmark} size="2x" color={theme`colors.primary`} />
        </ButtonWrapper>
      )}
    </HeaderWrapper>
  );
};

export default ChatScreenHeader;
