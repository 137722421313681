import React, { Fragment, ReactElement } from 'react';
import tw, { css, styled } from 'twin.macro';
const BodyCell = tw.td`px-6 py-3 break-normal md:px-8 lg:py-4`;
const HeaderCell = styled.th<{ isTitleCell: boolean }>(({ isTitleCell }) => [
  tw`px-6 py-3 font-medium text-left break-normal md:px-8 lg:py-4 bg-background-secondary`,
  isTitleCell && tw`pb-0 lg:pb-0`,
]);
const StyledTable = styled.table(() => [
  tw`w-full border-collapse`,
  css`
    tr:last-child [scope='row'] {
      border-radius: 0 0 10px 10px;
    }
    thead tr:first-child th:first-child {
      border-top-left-radius: 10px;
    }
    thead tr:first-child th:last-child {
      border-top-right-radius: 10px;
    }
    thead tr:only-child {
      border-radius: 0 0 10px 10px;
    }
    @media (min-width: 768px) {
      tbody tr + tr {
        ${tw`border-t-[1px] border-background`}
      }
    }
    @media (max-width: 767px) {
      tr [scope='row']:only-child {
        border-radius: 10px;
      }
    }
    @media (min-width: 1600px) {
      thead th:last-child {
        width: 45%;
      }
    }
  `,
]);

export const Table: React.FC<TableProps> = ({ headers, data, title }) => {
  const renderTableHeader = (headers: string[] | undefined, title?: ReactElement<any, any>) => {
    return headers || title ? (
      <thead>
        {title && headers && (
          <tr>
            <TableHeader isTitleCell={data.length ? true : false} span={headers.length} content={title} />
          </tr>
        )}
        {headers && !!data.length && (
          <tr>
            {headers.map((value, index) => (
              <TableHeader isTitleCell={false} key={`header-${index}-${value}`} content={value} />
            ))}
          </tr>
        )}
      </thead>
    ) : null;
  };

  const renderTableBody = (data: RowProps[]) => {
    return (
      <tbody>
        {data.map((row, index) => (
          <Fragment key={`row-${index}-container`}>
            <tr key={`row-${index}`}>
              {row.rowHeader && (
                <TableHeader isTitleCell={false} key={`${index}-header`} type="row" content={row.rowHeader} />
              )}
              {row.cells.map((cell, index) => (
                <BodyCell key={`${index}-${cell}`}>{cell}</BodyCell>
              ))}
            </tr>
          </Fragment>
        ))}
      </tbody>
    );
  };

  return (
    <StyledTable>
      {renderTableHeader(headers, title)}
      {renderTableBody(data)}
    </StyledTable>
  );
};

const TableHeader = ({ content, type = 'col', span, isTitleCell }: HeaderProps) => {
  return (
    <HeaderCell isTitleCell={isTitleCell} scope={type === 'col' ? 'col' : 'row'} colSpan={span}>
      {content}
    </HeaderCell>
  );
};

interface TableProps {
  title?: ReactElement<any, any>;
  headers?: string[];
  data: RowProps[];
}

export interface RowProps {
  rowHeader?: string;
  cells: (string | undefined)[];
}

interface HeaderProps {
  content: string | ReactElement<any, any>;
  type?: 'row' | 'col';
  span?: number;
  isTitleCell: boolean;
}
