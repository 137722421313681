import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer } from '@reshub/model';

type SessionState = {
  isLoading: boolean;
  error: boolean;
  customer?: Customer;
};

export const customerInitialState: SessionState = {
  isLoading: false,
  error: false,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState: customerInitialState,
  reducers: {
    customerApiCallStart: (state) => {
      state.isLoading = true;
    },
    customerApiCallSuccess: (state) => {
      state.isLoading = false;
    },
    customerApiCallError: (state, action) => {
      state.isLoading = false;
      state.error = true;
    },
    setCustomer: (state, action: PayloadAction<Customer>) => {
      state.customer = action.payload;
    },
    clearCustomer: (state) => {
      delete state.customer;
    },
  },
});

export default customerSlice;
