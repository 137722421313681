import { ApiClient } from '@mezo/web/api-client';
import { AssetLookup } from '@reshub/model';
import { useQuery } from 'react-query';

export const useAssetsById = (assetId?: string) => {
  return useQuery(
    ['assets', { id: assetId }],
    async () => {
      const { data } = await ApiClient.NON_CHAT_API.utility.get<AssetLookup>(`/assets/${assetId}`);
      return data;
    },
    { enabled: !!assetId }
  );
};
